import React from 'react';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import Dialog, { DialogContent } from '../../common/MaterialDialog';
import { Track } from '../../../api';
import { Desktop, NoDesktop } from '../../../style/constants';
import { Button } from '../../../VuexyDesign/atoms/_index';
import { TextBolder18 } from '../../../VuexyDesign/atoms/Text';
import ArtistDetails from '../../../VuexyDesign/molecules/ArtistDetails';

const SubmissionAndStoreStatus = ({
  showStoreDeliveryStatus,
  handleOpenTrackDeliveryStatusModal,
  track,
  handleOpen,
  onClose,
  show,
}: {
  showStoreDeliveryStatus: boolean;
  handleOpenTrackDeliveryStatusModal: () => void;
  track: Track;
  handleOpen: () => void;
  onClose: () => void;
  show: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex flex-row justify-content-between mt-1"
      style={{ gap: '8px' }}
    >
      <Desktop>
        <div className="d-flex align-items-center" style={{ gap: '2rem' }}>
          {/* ------------------------------------ store delvery status ------------------------------------ */}
          {showStoreDeliveryStatus && (
            <>
              <SpanDesignForViewStatus
                onClick={handleOpenTrackDeliveryStatusModal}
              >
                {
                  t(
                    'myTracks:storeDeliveryStatus.ViewStoreDeliveryStatus',
                  ) as string
                }
              </SpanDesignForViewStatus>
            </>
          )}
          {/* -------------------------------------- submission detail ------------------------------------- */}
          {(track.release_status === 'submitted' ||
            track.release_status === 'approved' ||
            track.release_status === 'live' ||
            track.release_status === 'incorrect details') && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <SpanDesignForViewStatus onClick={handleOpen}>
                {t('myTracks:viewSubmissionDetails') as string}
              </SpanDesignForViewStatus>
            </div>
          )}
        </div>
      </Desktop>
      {/* ------------------------------------- for mobile version ------------------------------------- */}
      <NoDesktop>
        <div
          className="d-flex flex-column align-items-center"
          style={{ gap: '20px' }}
        >
          {/* -------------------------------------- submission detail ------------------------------------- */}
          {(track.release_status === 'submitted' ||
            track.release_status === 'approved' ||
            track.release_status === 'live' ||
            track.release_status === 'incorrect details') && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <SpanDesignForViewStatus onClick={handleOpen}>
                {t('myTracks:viewSubmissionDetails') as string}
              </SpanDesignForViewStatus>
            </div>
          )}
          {/* ------------------------------------ store delvery status ------------------------------------ */}
          {showStoreDeliveryStatus && (
            <div>
              <SpanDesignForViewStatus
                onClick={handleOpenTrackDeliveryStatusModal}
              >
                {
                  t(
                    'myTracks:storeDeliveryStatus.ViewStoreDeliveryStatus',
                  ) as string
                }
              </SpanDesignForViewStatus>
            </div>
          )}
        </div>
      </NoDesktop>

      <Dialog open={show} onClose={onClose}>
        <DialogContent className="mt-3" style={{ overflowX: 'hidden' }}>
          <TextBolder18>{t('myTracks:submissionDetails')}</TextBolder18>
          <ArtistDetails trackId={track.id} />
          <div className="d-flex justify-content-center">
            <Button
              color="secondary"
              text="Close"
              onClick={onClose}
              outline
              className="text-dark"
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const SpanDesignForViewStatus = styled.span`
  font-weight: 500;
  font-size: 13px;
  text-decoration-line: underline;
  cursor: pointer;
`;
export default SubmissionAndStoreStatus;
