import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FormGroup, Input, Label } from 'reactstrap';

type Props = {
  label: string;
  InputHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  inputName: string;
  type: string | number;
};

export default function PaymentInfoGeneric({
  label,
  InputHandler,
  value,
  inputName,
  type,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column mt-1">
      <FormGroup>
        <Label for={label}>{t(label)}</Label>
        <Input
          type={type}
          name={inputName}
          id={label}
          placeholder={label}
          className="text-dark cursor-pointer"
          onChange={InputHandler}
          value={value}
        />
      </FormGroup>
    </div>
  );
}
