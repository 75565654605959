/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  components,
  InputProps,
  MenuListProps,
  MultiValueProps,
  MultiValueRemoveProps,
  OptionProps,
} from 'react-select';
import Image from 'next/image';
import { GoPlus } from 'react-icons/go';
import useTranslation from 'next-translate/useTranslation';
import {
  API,
  TrackRelease,
  style as colors,
  TrackArtist,
  IncorrectDetails,
} from '../../api';
import Loader from '../../components/common/Loader';
import {
  TextBolder14,
  TextBolder16,
  TextBolder18,
  TextRegular12,
  TextRegular14,
} from '../atoms/Text';
import { Button, Col, Container, Dropdown, Row } from '../atoms/_index';
import {
  MainFlowAddContributorDialog,
  MainFlowAddRequiredContributorDialog,
  MainFlowContributorCard,
} from './_index';
import { MainFlowIncorrectDetails } from '../molecules/_index';

interface MainFlowContributorsProps {
  nextStepHandler: () => void;
  trackId: number;
  updatePreview: (val: boolean) => void;
  isIncorrect?: boolean;
}

function MainFlowContributors({
  nextStepHandler,
  trackId,
  updatePreview,
  isIncorrect,
}: MainFlowContributorsProps) {
  const { t } = useTranslation('organisms');
  const [trackRelease, setTrackRelease] = useState<TrackRelease>();
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);
  const [artistRole, setArtistRole] = useState('');
  const [songwriters, setSongwriters] = useState<Option[]>([]);
  const [composers, setComposers] = useState<Option[]>([]);
  const [isInstrumental, setIsInstrumental] = useState(false);
  const [incorrectDetails, setIncorrectDetails] = useState<IncorrectDetails>();

  const [editUser, setEditUser] = useState<TrackArtist>();

  const [isValid, setIsValid] = useState(false);

  const [isAddRequiredDialogOpen, setIsAddRequiredDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const [isSongWriterDropDownOpen, setIsSongWriterDropDownOpen] = useState(
    false,
  );
  const [isSongComposerDropDownOpen, setIsSongComposerDropDownOpen] = useState(
    false,
  );

  useEffect(() => {
    API.trackReleases.getTrackRelease(trackId).then((resp) => {
      setTrackRelease(resp.data);
      setIncorrectDetails(resp?.data.incorrect_details);

      setDropdownOptions(
        resp.data.artist_details.artists.map((artist: TrackArtist) => ({
          value: artist.id || -1,
          label: artist.artist_name || artist.real_name,
        })),
      );

      setSongwriters(
        resp.data.artist_details.artists
          .filter((artist: TrackArtist) =>
            artist.artist_role.includes('Lyricist'),
          )
          .map((artist: TrackArtist) => ({
            value: artist.id || -1,
            label: artist.artist_name || artist.real_name,
          })),
      );
      setComposers(
        resp.data.artist_details.artists
          .filter((artist: TrackArtist) =>
            artist.artist_role.includes('Composer'),
          )
          .map((artist: TrackArtist) => ({
            value: artist.id || -1,
            label: artist.artist_name || artist.real_name,
          })),
      );
      setIsInstrumental(
        resp.data.track_details.lyrics_language.toUpperCase() === 'ZXX',
      );
    });
  }, []);

  useEffect(() => {
    if (!trackRelease) {
      return;
    }

    setIsInstrumental(
      trackRelease.track_details.lyrics_language.toUpperCase() === 'ZXX',
    );

    setDropdownOptions(
      trackRelease.artist_details.artists.map((artist: TrackArtist) => ({
        value: artist.id || -1,
        label: artist.artist_name || artist.real_name,
      })),
    );

    setSongwriters(
      trackRelease.artist_details.artists
        .filter((artist) => artist.artist_role.includes('Lyricist'))
        .map((artist: TrackArtist) => ({
          value: artist.id || -1,
          label: artist.artist_name || artist.real_name,
        })),
    );

    setComposers(
      trackRelease.artist_details.artists
        .filter((artist) => artist.artist_role.includes('Composer'))
        .map((artist: TrackArtist) => ({
          value: artist.id || -1,
          label: artist.artist_name || artist.real_name,
        })),
    );
  }, [trackRelease]);

  useEffect(() => {
    if (isInstrumental) {
      setIsValid(composers.length > 0);
    } else {
      setIsValid(songwriters.length > 0 && composers.length > 0);
    }
  }, [songwriters, composers]);

  const fetchArtistDetails = () =>
    API.trackReleases.getTrackRelease(trackId).then((resp) => {
      setTrackRelease(resp.data);
      setIncorrectDetails(resp?.data.incorrect_details);
    });

  const MenuListSongwriter = ({
    innerRef,
    innerProps,
    children,
  }: MenuListProps) => (
    <div ref={innerRef} {...innerProps}>
      <div
        className="d-flex w-100 pl-2 py-1 cursor-pointer"
        onClick={() => {
          setIsAddRequiredDialogOpen(true);
          setArtistRole('Lyricist');
          setIsSongWriterDropDownOpen(false);
        }}
      >
        <div className="d-flex align-items-center">
          <GoPlus size={16} />
        </div>
        <div className="ml-1">
          <TextBolder14>{t('organisms:addNew')}</TextBolder14>
        </div>
      </div>
      {children}
    </div>
  );

  const MenuListComposer = ({
    innerRef,
    innerProps,
    children,
  }: MenuListProps) => (
    <div ref={innerRef} {...innerProps}>
      <div
        className="d-flex w-100 pl-2 py-1 cursor-pointer"
        onClick={() => {
          setIsAddRequiredDialogOpen(true);
          setArtistRole('Composer');
          setIsSongComposerDropDownOpen(false);
        }}
      >
        <div className="d-flex align-items-center">
          <GoPlus size={16} />
        </div>
        <div className="ml-1">
          <TextBolder14>{t('organisms:addNew')}</TextBolder14>
        </div>
      </div>
      {children}
    </div>
  );

  const Input = ({ className, ...props }: InputProps) => (
    <components.Input
      {...props}
      className={`${className} h-100 d-flex align-items-center my-auto`}
    />
  );

  const MultiValue = ({ children, className, ...props }: MultiValueProps) => (
    <components.MultiValue
      {...props}
      className={`${className} bg-primary text-white`}
    >
      {children}
    </components.MultiValue>
  );

  const OptionLayout = ({
    data,
    className,
    isSelected,
    ...props
  }: OptionProps) => {
    const option = data as Option;
    const currentArtist = trackRelease?.artist_details.artists.find(
      (item) => item.id === option.value,
    );

    return (
      <components.Option
        {...props}
        data={data}
        isSelected={isSelected}
        className={`${className} ${isSelected ? 'text-white' : ''}`}
      >
        {`${option.label} ${
          currentArtist?.artist_type === 'MAINARTIST'
            ? t('organisms:mainArtistBrackets')
            : ''
        }  ${
          currentArtist?.artist_type === 'FEATUREDARTIST'
            ? t('organisms:featuredArtistBrackets')
            : ''
        }`}
      </components.Option>
    );
  };

  const MultiValueRemoveSongwriters = ({
    data,
    children,
    ...props
  }: MultiValueRemoveProps) => (
    <div
      className="d-flex align-items-center justify-content-center"
      onClick={() =>
        handleRemoveValue(Number((data as Option).value), 'Lyricist')
      }
    >
      <components.MultiValueRemove {...props} data={data}>
        {children}
      </components.MultiValueRemove>
    </div>
  );

  const MultiValueRemoveComposers = ({
    data,
    children,
    ...props
  }: MultiValueRemoveProps) => (
    <div
      className="d-flex align-items-center justify-content-center"
      onClick={() =>
        handleRemoveValue(Number((data as Option).value), 'Composer')
      }
    >
      <components.MultiValueRemove {...props} data={data}>
        {children}
      </components.MultiValueRemove>
    </div>
  );

  if (!trackRelease) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Loader />
      </div>
    );
  }

  const handleOpenEditDialog = (artist: TrackArtist) => {
    setEditUser(artist);
    setIsAddDialogOpen(true);
  };

  const handleDelete = async (artist?: TrackArtist) => {
    if (!artist || artist.id === undefined) {
      return;
    }

    await API.trackReleases.deleteArtist(artist.id, trackId);
    fetchArtistDetails();
    updatePreview(true);
  };

  const handleRemoveValue = (artistId: number, role: string) => {
    const artist = trackRelease?.artist_details?.artists?.find(
      (item) => item.id === artistId,
    );
    if (artist) {
      try {
        API.trackReleases
          .updateArtist(
            {
              ...artist,
              artist_role: artist.artist_role.filter((item) => item !== role),
            },
            trackId,
          )
          .then((resp) => {
            if (resp.success) {
              fetchArtistDetails();
              setTrackRelease(resp.data);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleClose = (artist?: TrackArtist, role?: string) => {
    if (artist && role) {
      if (role === 'Lyricist') {
        setSongwriters([
          ...songwriters,
          { value: artist.id || -1, label: artist.real_name },
        ]);
      }
      if (role === 'Composer') {
        setComposers([
          ...songwriters,
          { value: artist.id || -1, label: artist.real_name },
        ]);
      }
    }

    setEditUser(undefined);
    setIsAddRequiredDialogOpen(false);
    setIsAddDialogOpen(false);
    setArtistRole('');

    fetchArtistDetails();
  };

  const updateArtistWithRole = (artistId: number, role: string) => {
    const artist = trackRelease.artist_details.artists.find(
      (item) => item.id === artistId,
    );

    if (artist) {
      API.trackReleases
        .updateArtist(
          {
            ...artist,
            artist_role: [...artist.artist_role, role],
          },
          trackId,
        )
        .then((resp) => {
          setTrackRelease(resp.data);
        });
    }
  };

  const handleSongwriterChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    if (!selected || !Array.isArray(selected)) {
      return;
    }

    if (selected.length === 0) {
      return;
    }

    const latestArtistId = Number(selected[selected.length - 1].value);
    updateArtistWithRole(latestArtistId, 'Lyricist');

    setSongwriters(selected);
  };

  const handleComposersChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    if (!selected || !Array.isArray(selected)) {
      return;
    }

    if (selected.length === 0) {
      return;
    }

    const latestArtistId = Number(selected[selected.length - 1].value);
    updateArtistWithRole(latestArtistId, 'Composer');

    setComposers(selected);
  };

  const handleSave = () => {
    if (isValid) {
      updatePreview(true);
      nextStepHandler();
    }
  };

  return (
    <>
      <Container className="mb-4 px-0">
        <Row>
          <Col className="d-flex justify-content-between mt-2">
            <TextBolder18>{t('organisms:contributors')}</TextBolder18>
          </Col>
        </Row>
        {isIncorrect && incorrectDetails ? (
          <MainFlowIncorrectDetails
            incorrectDetails={incorrectDetails}
            step="contributors"
            allArtists={trackRelease.artist_details.artists}
          />
        ) : (
          <></>
        )}
        <Row>
          {/* 
            If lyrics language is instrumental only composers are required
            If lyrics language is not instrumental songwriters and composers are required
        */}
          {!isInstrumental ? (
            <Col xs={12} className="mt-2">
              <>
                <TextRegular14>
                  {t('organisms:selectSongwriters')}*
                </TextRegular14>
                <Dropdown
                  onChange={handleSongwriterChange}
                  options={dropdownOptions}
                  value={songwriters}
                  placeholder={t('organisms:selectSongwriters') as string}
                  components={{
                    MenuList: MenuListSongwriter,
                    Input,
                    MultiValue,
                    Option: OptionLayout,
                    MultiValueRemove: MultiValueRemoveSongwriters,
                  }}
                  isMulti
                  closeMenuOnSelect
                  hideSelectedOptions
                  onFocus={() => {
                    // we are using delay with setTimeout for solving an event propagation problem
                    setTimeout(() => {
                      setIsSongWriterDropDownOpen(true);
                      setIsSongComposerDropDownOpen(false);
                    }, 100);
                  }}
                  onBlur={() => {
                    setIsSongWriterDropDownOpen(false);
                  }}
                  menuIsOpen={isSongWriterDropDownOpen}
                />
              </>
            </Col>
          ) : (
            <></>
          )}

          <Col xs={12} className="mt-2">
            <>
              <TextRegular14>{t('organisms:selectComposers')} *</TextRegular14>
              <Dropdown
                onChange={handleComposersChange}
                options={dropdownOptions}
                value={composers}
                placeholder={t('organisms:selectComposers') as string}
                components={{
                  MenuList: MenuListComposer,
                  Input,
                  MultiValue,
                  Option: OptionLayout,
                  MultiValueRemove: MultiValueRemoveComposers,
                }}
                isMulti
                closeMenuOnSelect
                hideSelectedOptions
                onBlur={() => setIsSongComposerDropDownOpen(false)}
                onFocus={() => {
                  // we are using delay with setTimeout for solving an event propagation problem
                  setTimeout(() => {
                    setIsSongComposerDropDownOpen(true);
                    setIsSongWriterDropDownOpen(false);
                  }, 100);
                }}
                menuIsOpen={isSongComposerDropDownOpen}
              />
            </>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12} className="mb-2">
            <TextBolder16>{t('organisms:addOtherContributors')}</TextBolder16>
          </Col>
          <>
            {trackRelease.artist_details.artists
              .filter(
                (artist: TrackArtist) => artist.artist_type === 'CONTRIBUTOR',
              )
              .map((artist: TrackArtist) => (
                <Col xs={6} key={`artist-card-${artist.id}`}>
                  <div className="mr-1">
                    <MainFlowContributorCard
                      key={artist.email}
                      artist={artist}
                    />
                    <div className="d-flex w-100 justify-content-center align-items-center mt-1 mb-2">
                      <TextBolder14
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleOpenEditDialog(artist)}
                      >
                        {t('organisms:edit')}
                      </TextBolder14>
                      <TextBolder14
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        className="text-danger ml-2"
                        onClick={() => handleDelete(artist)}
                      >
                        {t('organisms:delete')}
                      </TextBolder14>
                    </div>
                  </div>
                </Col>
              ))}
          </>
          <Col xs={6}>
            <div
              className="d-flex flex-column align-items-center justify-content-center px-2"
              style={{
                background: colors.gray,
                cursor: 'pointer',
                height: '150px',
                width: '150px',
                borderRadius: '4px',
              }}
              onClick={() => setIsAddDialogOpen(true)}
            >
              <div>
                <Image
                  src="/static/images/cross.svg"
                  width={20}
                  height={20}
                  alt="test"
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </div>
              <div className="d-flex">
                <TextBolder14 className="text-muted text-center user-select-none">
                  {t('organisms:addAContributor')}
                </TextBolder14>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12}>
            <div className="d-flex flex-column mt-1">
              <TextRegular12>{t('organisms:selectOwnProfile')}</TextRegular12>
              <TextRegular12>
                {t('organisms:addCreditsToArtists')}
              </TextRegular12>
            </div>
          </Col>
          <Col xs={12}>
            <Button
              className="mt-2"
              color="success"
              style={{
                cursor: `${!isValid ? 'default' : 'pointer'}`,
                borderRadius: '2px',
              }}
              onClick={handleSave}
              disabled={!isValid}
            >
              {t('organisms:saveAndProceed') as string}
            </Button>
          </Col>
        </Row>
      </Container>
      <MainFlowAddRequiredContributorDialog
        trackId={trackId}
        isOpen={isAddRequiredDialogOpen}
        onClose={handleClose}
        fetchArtistDetails={fetchArtistDetails}
        artistRole={artistRole}
      />

      {editUser ? (
        <MainFlowAddContributorDialog
          trackId={trackId}
          artist={editUser}
          isOpen={isAddDialogOpen}
          onClose={handleClose}
          fetchArtistDetails={fetchArtistDetails}
          allArtists={trackRelease.artist_details.artists}
        />
      ) : (
        <MainFlowAddContributorDialog
          trackId={trackId}
          isOpen={isAddDialogOpen}
          onClose={handleClose}
          fetchArtistDetails={fetchArtistDetails}
          allArtists={trackRelease.artist_details.artists}
        />
      )}
    </>
  );
}

export default MainFlowContributors;

interface Option {
  value: string | number;
  label: string;
}
