import { useMemo } from 'react';
import { useRouter } from 'next/router';
import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import { useQuery } from 'react-query';
import { ParsedUrlQuery } from 'querystring';
import { axios, Track, Pagination } from '../../../api';

export interface TracksQuery {
  search?: string;
  contest?: boolean;
  visibility?: boolean;
  liked?: boolean;
  listened?: boolean;
  pulled?: boolean;
  archived?: boolean;
  release_status?:
    | 'inactive'
    | 'pending'
    | 'submitted'
    | 'released'
    | 'accepted'
    | 'declined';
  pull_status?: 'open' | 'accepted' | 'declined' | 'cancelled';
  sort?: 'tracks' | 'likes' | 'plays' | 'date';
  sort_dir?: 'asc' | 'desc';
  status?: 'active';
  tab?: 'releases';
  overview?: 'releases' | 'demos';
}

export interface TracksResponse {
  data: Track[];
  pagination: Pagination;
}

export const getTracks = (
  _key: string,
  params: TracksQuery = {},
  page: number,
): Promise<TracksResponse> =>
  axios({
    method: 'GET',
    url: `/user/my-tracks`,
    /**
     * `keywords` query param is used by header search, so we are using `search` query instead
     */
    params: { ...params, keyword: params.search, page },
  }).then(({ data: { data, pagination } }) => ({
    data,
    pagination,
  }));

const queryFilterKeys = ['contest', 'visibility', 'liked', 'listened'];

const queryStringFilterKeys = ['overview', 'tab'];

/**
 * Here to assist us with having some default query values pre-selected even if they are
 * not in the URL
 */
export const defaultQuery = (query: ParsedUrlQuery): TracksQuery => {
  const { tab, ...restQuery } = query;

  const nextQuery = {
    overview: tab === 'releases' ? 'releases' : 'demos',
    tab: tab === 'releases' ? 'releases' : undefined,
    ...{
      sort: 'date',
      sort_dir: 'desc',
    },
    ...restQuery,
    ...mapValues(pick(restQuery, queryFilterKeys), Boolean),
    ...mapValues(pick(restQuery, queryStringFilterKeys), String),
  };

  return pick(nextQuery, [
    ...queryFilterKeys,
    ...queryStringFilterKeys,
    'search',
    'sort',
    'sort_dir',
  ]) as TracksQuery;
};

export const useCurrentTracksQuery = (): TracksQuery => {
  const { query: routerQuery } = useRouter();

  return useMemo(() => defaultQuery(routerQuery), [routerQuery]);
};

export const useIsReleaseTab = (): boolean => {
  const { query: routerQuery } = useRouter();

  return routerQuery.tab === 'releases';
};

export const useCurrentTracksQueryWithReleaseStatus = (): TracksQuery => {
  const { query: routerQuery } = useRouter();

  if (routerQuery.tab === 'releases') {
    routerQuery.release_status =
      'pending,submitted,released,accepted,signatures,incorrect details,requested';
  }
  return useMemo(() => defaultQuery(routerQuery), [routerQuery]);
};

export const useActiveTrack = (
  { enabled }: { enabled: boolean } = { enabled: true },
): Track | undefined => {
  const { data } = useQuery<TracksResponse>(
    ['active-track', { status: 'active' }],
    () => getTracks('active-track', { status: 'active' }, 1),
    {
      enabled,
    },
  );

  return data?.data?.[0];
};
