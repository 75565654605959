import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import {
  API,
  FugaLanguage,
  TrackArtist,
  TrackRelease,
  useCurrentUser,
} from '../../api';
import { TextBolder14, TextRegular14 } from '../atoms/Text';
import { Col, Row } from '../atoms/_index';

type ArtistDetailsProps = {
  trackId: number;
};

function ArtistDetails({ trackId }: ArtistDetailsProps) {
  const { t } = useTranslation('artist');
  const [mainGenre, setMainGenre] = useState('');
  const [subGenre, setSubGenre] = useState('');
  const [trackGenres, setTrackGenres] = useState<TrackGenre[]>();
  const [trackRelease, setTrackRelease] = useState<TrackRelease>();
  const [fugaLanguages, setFugaLanguages] = useState<Option[]>([]);

  const currentUser = useCurrentUser();

  useEffect(() => {
    if (
      currentUser !== undefined &&
      !currentUser.requesting &&
      trackRelease === undefined
    ) {
      API.trackReleases.getTrackRelease(trackId).then((resp) => {
        setTrackRelease(resp.data);
      });
    }
  }, [currentUser]);

  const mapArtistType = (artist: TrackArtist): string => {
    switch (artist.artist_type) {
      case 'MAINARTIST':
        return t('artist:mainArtist');
      case 'FEATUREDARTIST':
        return t('artist:featuredArtist');
      case 'CONTRIBUTOR':
        return t('artist:contributor');
      default:
        return t('artist:artist');
    }
  };

  useEffect(() => {
    API.trackReleases.getTrackGenres().then((resp) => {
      setTrackGenres(resp.data);
    });

    API.trackReleases.getLanguages().then((resp) =>
      setFugaLanguages(
        resp.data.map((lang: FugaLanguage) => ({
          value: lang.id,
          label: lang.name,
        })),
      ),
    );
  }, []);

  useEffect(() => {
    if (trackGenres !== undefined) {
      setMainGenre(
        trackGenres.find(
          (genre) => genre.id === trackRelease?.track_details.main_genre_id,
        )?.name || '',
      );
      setSubGenre(
        trackGenres.find(
          (genre) => genre.id === trackRelease?.track_details.main_sub_genre_id,
        )?.name || '',
      );
    }
  }, [trackGenres, trackRelease]);

  return (
    <Row className="d-flex mt-2 mb-3">
      <Col xs={6} className="d-flex flex-column">
        {trackRelease?.artist_details?.artists?.map((artist: TrackArtist) => (
          <div className="d-flex flex-column mb-1" key={artist?.id}>
            <TextBolder14>{mapArtistType(artist)}</TextBolder14>
            {artist?.artist_name ? (
              <TextRegular14>
                {t('artist:artistName')} {artist?.artist_name}
              </TextRegular14>
            ) : (
              <TextRegular14>
                {t('artist:fullName')} {artist?.real_name}
              </TextRegular14>
            )}
            {artist?.artist_type !== 'CONTRIBUTOR' && (
              <TextRegular14>
                {t('artist:royaltySplit')}{' '}
                {artist?.percentage ? Number(artist?.percentage) : ''}%
              </TextRegular14>
            )}

            <TextRegular14>
              {t('artist:roles')} {artist?.artist_role?.join(', ')}
            </TextRegular14>
          </div>
        ))}
      </Col>
      <Col xs={6} className="d-flex flex-column">
        <TextBolder14>{t('artist:trackDetails')}</TextBolder14>
        <TextRegular14>
          {t('artist:trackName')} {trackRelease?.track_details?.release_title}
        </TextRegular14>
        <TextRegular14>
          {t('artist:mainGenre')} {mainGenre}
        </TextRegular14>
        <TextRegular14>
          {t('artist:subGenre')} {subGenre}
        </TextRegular14>
        <TextRegular14>
          {t('artist:productionYear')}{' '}
          {trackRelease?.track_details?.production_year}
        </TextRegular14>
        <TextRegular14>
          {t('artist:explicitLyrics')}{' '}
          {trackRelease?.track_details?.explicit === t('artist:yes') &&
            t('artist:explicit')}
          {trackRelease?.track_details?.explicit === t('artist:cleanCaps') &&
            t('artist:clean')}
          {trackRelease?.track_details?.explicit === t('artist:no') &&
            t('artist:notExplicit')}
        </TextRegular14>
        <TextRegular14>
          {t('artist:lyricsLanguage')}{' '}
          {
            fugaLanguages.find(
              (lang) =>
                lang.value ===
                trackRelease?.track_details?.lyrics_language.toUpperCase(),
            )?.label
          }
        </TextRegular14>
        <TextRegular14>
          {t('artist:trackMetadataLanguage')}{' '}
          {
            fugaLanguages.find(
              (lang) =>
                lang.value ===
                trackRelease?.track_details?.meta_data_language.toUpperCase(),
            )?.label
          }
        </TextRegular14>
        <TextRegular14>
          {t('artist:price')}{' '}
          {
            allPrices.find(
              (pr) => pr.value === trackRelease?.track_details?.price,
            )?.label
          }
        </TextRegular14>
      </Col>
    </Row>
  );
}

export default ArtistDetails;

interface TrackGenre {
  id: number;
  name: string;
  parent_id?: number;
}

interface Option {
  value: string | number;
  label: string;
}

const allPrices: Option[] = [
  { value: 'BACK', label: '0.79' },
  { value: 'MID', label: '0.99' },
  { value: 'FRONT', label: '1.29' },
];
