import React from 'react';
import styled from 'styled-components';
import { FacebookCircle as Facebook } from '@styled-icons/boxicons-logos/FacebookCircle';
import { TwitterWithCircle as Twitter } from '@styled-icons/entypo-social/TwitterWithCircle';
import { InstagramWithCircle as Instagram } from '@styled-icons/entypo-social/InstagramWithCircle';
import { Soundcloud } from '@styled-icons/icomoon/Soundcloud';
import { Spotify } from '@styled-icons/fa-brands/Spotify';
import { Web } from '@styled-icons/foundation/Web';
import { YoutubeWithCircle as YouTube } from '@styled-icons/entypo-social/YoutubeWithCircle';
import { Snapchat } from '@styled-icons/fa-brands/Snapchat';
import { Bandcamp } from '@styled-icons/fa-brands/Bandcamp';
import { Applemusic } from '@styled-icons/simple-icons/Applemusic';
import { SocialItem } from '../../api/lib/api/users';
import { style } from '../../api';

import { useIsMobile } from '../../style/constants';

const SocialIcon = ({
  item,
  size,
  greyoutOnMobile = true,
}: {
  item: Omit<SocialItem, 'id'>;
  size?: number;
  greyoutOnMobile?: boolean;
}) => {
  const { type } = item;
  const isMobile = useIsMobile();
  const iconSize = size || (isMobile ? 16 : 14);

  const getColor = (desktopColor: string) =>
    isMobile && greyoutOnMobile ? style.grey_main_new : desktopColor;

  switch (type) {
    case 'facebook': {
      return (
        <Round>
          <Facebook size={iconSize} color={getColor(style.facebook_new)} />
        </Round>
      );
    }
    case 'twitter': {
      return (
        <Round>
          <Twitter
            size={iconSize}
            color={getColor(style.twitter_new)}
            fill={getColor(style.twitter_new)}
          />
        </Round>
      );
    }
    case 'instagram': {
      return (
        <Round>
          <Instagram size={iconSize} color={getColor(style.instagram_new)} />
        </Round>
      );
    }
    case 'soundcloud': {
      return (
        <Round
          style={{
            backgroundColor: getColor(style.soundcloud_new),
            height: iconSize,
            width: iconSize,
            paddingBottom: 3,
          }}
        >
          <Soundcloud
            size={iconSize - 4}
            color={style.white}
            style={{ marginLeft: 2 }}
          />
        </Round>
      );
    }
    case 'spotify': {
      return (
        <Round>
          <Spotify size={iconSize} color={getColor(style.spotify_new)} />
        </Round>
      );
    }
    case 'youtube': {
      return (
        <Round>
          <YouTube size={iconSize} color={style.youtube_new} />
        </Round>
      );
    }
    case 'custom': {
      return (
        <Website>
          <Web
            size={iconSize + 6}
            color={getColor(style.primary_pink_new)}
            style={{ marginLeft: -3 }}
          />
        </Website>
      );
    }
    case 'snapchat': {
      return (
        <Round>
          <Snapchat size={iconSize} color={style.snapchat_new} />
        </Round>
      );
    }
    case 'bandcamp': {
      return (
        <Round>
          <Bandcamp size={iconSize} color={style.bandcamp_new} />
        </Round>
      );
    }
    case 'applemusic': {
      return (
        <Round>
          <Applemusic size={iconSize} color="white" />
        </Round>
      );
    }
    default: {
      const result: never = type;
      throw new Error(`unknown result type ${result}`);
    }
  }
};

export default SocialIcon;

const Round = styled.div`
  border-radius: 50%;
  text-decoration: none;
  display: block;
  background: ${style.mainBg};
  color: ${style.pink};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Website = styled.a`
  text-decoration: none;
  color: ${style.black};
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 0px;
  padding-left: 0px;
`;
