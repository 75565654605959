import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { ThumbsUp, ThumbsDown } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import {
  getPullRequestsForLogginInUser,
  Track,
  TrackPulledRecieved,
} from '../../api';
import Button from '../atoms/Button';
import { TextBolder14 } from '../atoms/Text';

function PullRequestSection({ track, onPullAction }: PullRequestSectionProps) {
  const { t } = useTranslation('myTracks');
  const [pullRequests, setPullRequests] = useState<TrackPulledRecieved[]>([]);

  useEffect(() => {
    const setPulls = async () => {
      const pulls: TrackPulledRecieved[] = await getPullRequestsForLogginInUser().then(
        (res) => res,
      );

      const filtered: TrackPulledRecieved[] = pulls.filter(
        (pull) => track.id === pull.track_id && pull.status === 'open',
      );

      setPullRequests(filtered);
    };
    setPulls();
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: '1.7rem',
          marginBottom: '1rem',
          width: '200px',
          gap: '8px',
        }}
        className="d-flex justifty-content-center flex-column mx-auto"
      >
        {pullRequests.map((pullRequest) => (
          <>
            <p className="d-flex justify-content-center">
              <Image
                src={pullRequest.label?.profile_photo || ''}
                alt="Label image"
                width="45"
                height="45"
                unoptimized
                className="rounded-circle"
                style={{
                  maxWidth: '100%',
                }}
              />
            </p>
            <span className="text-center">
              {t('myTracks:trackHasBeenPulled')}{' '}
              <LabelName>
                <Link href={`/profile/${pullRequest?.label?.id}`}>
                  <>{pullRequest?.label?.display_name}</>
                </Link>
              </LabelName>
              {t('myTracks:respondNow')}
            </span>
            <div
              className="d-flex justify-content-around w-100 p-0"
              style={{ gap: '8px' }}
            >
              <Button
                color="success"
                className="d-flex align-items-center justify-content-around p-0 br-5"
                style={{
                  width: '97px',
                  maxHeight: '42px',
                }}
                onClick={() => onPullAction(pullRequest.id, 'accept')}
              >
                <span className="d-flex flex-row">
                  <ThumbsUp size={14} />
                  <span style={{ marginLeft: '5px' }}>
                    {t('myTracks:accept')}
                  </span>
                </span>
              </Button>

              <Button
                color="flat-danger"
                className="d-flex align-items-center justify-content-around p-0 br-5"
                style={{
                  width: '97px',
                  maxHeight: '42px',
                }}
                onClick={() => onPullAction(pullRequest.id, 'decline')}
              >
                <span className="d-flex">
                  <ThumbsDown size={14} />
                  <span style={{ marginLeft: '5px' }}>
                    {t('myTracks:decline')}
                  </span>
                </span>
              </Button>
            </div>
          </>
        ))}
      </div>
    </>
  );
}

interface PullRequestSectionProps {
  track: Track;
  onPullAction: (pullId?: number, action?: string) => void;
}

const LabelName = styled(TextBolder14)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default PullRequestSection;
