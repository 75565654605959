import React, { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  Edit2,
  Trash2,
  ArrowRight,
  Edit,
  AlertCircle,
  Play,
  Share2,
  BarChart,
  DollarSign,
} from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import {
  Track,
  API,
  trackActions,
  useDispatch,
  usePlayer,
  useCurrentUser,
} from '../../api';
import PlayerCover from '../../components/PlayerCover';
import { TextBold18, TextRegular14 } from '../atoms/Text';
import { Button, ProOutlineButton } from '../atoms/_index';
import { DefaultSection } from '../molecules/_index';
import SharingModal from '../../components/Modals/SharingModal';
import DeleteModal from '../../components/MyTracks/Modal/DeleteVirpp';
import StoreDeliveryStatusViewModal from '../../components/MyTracks/Modal/StoreDeliveryStatusViewModal';
import { useIsDesktop } from '../../style/constants';
import { useIsReleaseTab } from '../../components/MyTracks/context/Tracks';
import TrackStatusBadgeReleases from '../../components/MyTracks/TrackList/TrackStatusBadgeReleases';
import TrackStatusBadgeDemos from '../../components/MyTracks/TrackList/TrackStatusBadgeDemos';
import SubmissionAndStoreStatus from '../../components/MyTracks/TrackList/SubmissionAndStoreStatus';
import VuexyProGradientButton from '../atoms/ProGradientButton';

function MyTracksDistributionCardBodyNew({
  track,
  onAction,
}: MyTracksDistributionCardBodyNewProps) {
  const { t } = useTranslation('organisms');
  const router = useRouter();
  const dispatch = useDispatch();
  const { playTrackByIdOrEnqueuePlaylist } = usePlayer();
  const currentUser = useCurrentUser();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [show, setShow] = useState(false);
  const [
    showTrackStoreDeliveryStatus,
    setShowTrackStoreDeliveryStatus,
  ] = useState<{
    isShow: boolean;
    status: string;
    trackId: number;
  }>({
    isShow: false,
    status: '',
    trackId: 0,
  });
  const isDesktop = useIsDesktop();
  const isReleaseTab = useIsReleaseTab();
  const [trackStoreDeliveryStatusData, setTrackStoreDeliveryStatus] = useState<
    { name: string; status: string }[]
  >([]);
  const [showTrackDeliveryStatus, setShowTrackDeliveryStatus] = useState(false);

  /* -------------------------- api call for track store delivery status -------------------------- */
  useEffect(() => {
    if (
      track &&
      (track?.release_status === 'submitted' ||
        track?.release_status === 'approved' ||
        track?.release_status === 'live' ||
        track?.release_status === 'incorrect details')
    ) {
      API.trackReleases
        .getTrackReleaseLiveStatus(track.id)
        .then((resp) => {
          setTrackStoreDeliveryStatus(resp);
          if (resp.length !== 0) {
            setShowTrackStoreDeliveryStatus((prev) => ({
              ...prev,
              isShow: true,
              trackId: track?.id,
            }));
            const checkIsDelivered = resp.filter(
              (f: { name: string; status: string }) => f.status === 'DELIVERED',
            );
            /* ----------------------- code for check is delivered button show or not ----------------------- */
            if (checkIsDelivered.length === 0) {
              setShowTrackStoreDeliveryStatus((prev) => ({
                ...prev,
                status: '',
                trackId: track?.id,
              }));
            } else {
              setShowTrackStoreDeliveryStatus((prev) => ({
                ...prev,
                status: 'Delivered',
                trackId: track?.id,
              }));
            }
          } else {
            setShowTrackStoreDeliveryStatus((prev) => ({
              ...prev,
              isShow: false,
              trackId: track?.id,
            }));
          }
        })
        .catch(() => {
          setShowTrackStoreDeliveryStatus((prev) => ({
            ...prev,
            isShow: false,
            status: '',
            trackId: track?.id,
          }));
          setTrackStoreDeliveryStatus([]);
        });
    } else {
      setShowTrackStoreDeliveryStatus((prev) => ({
        ...prev,
        isShow: false,
        status: '',
      }));
    }
  }, [track]);

  /* --------------------- disable buttons if status is submitted or approved --------------------- */
  useEffect(() => {
    if (
      track?.release_status === 'submitted' ||
      track?.release_status === 'approved'
    ) {
      setIsButtonDisabled(true);
    }
  }, []);

  const handleCloseSumissionDetailModal = () => {
    setShow(false);
  };
  const handleOpenSumissionDetailModal = () => {
    setShow(true);
  };

  const handleOpenTrackDeliveryStatusModal = () => {
    setShowTrackDeliveryStatus(true);
  };

  function toEdit() {
    router.push(`/edit-track/${track?.id}`);
  }
  function toStats() {
    router.push(
      track?.release_status !== 'live'
        ? `/statistics?tab=demos&track_id=${track?.id}`
        : `/statistics?tab=releases&track_id=${track.id}`,
    );
  }
  const handleDelete = async () => {
    if (!track) {
      return;
    }
    setShowDeleteModal(false);

    await dispatch(trackActions.deleteTrack(track.id));
    onAction('delete');
  };

  const handleActivate = useCallback(async () => {
    if (!track) {
      return;
    }

    await dispatch(trackActions.activateTrack(track.id));
    onAction();
  }, [dispatch, track]);

  const getButtonSection = () => {
    if (isReleaseTab) {
      return null;
    }
    if (!track) {
      return null;
    }
    return <DefaultSection track={track} />;
  };

  if (!track || !track) {
    return null;
  }

  const playTrack = playTrackByIdOrEnqueuePlaylist([track.id], 'unknown');

  const handleReleaseIndependently = () => {
    const { abilities } = currentUser;
    if (abilities) {
      if (abilities?.plan === 'amateur') {
        router.push('/become-pro');
      } else {
        router.push(`/distribution/welcome?track_id=${track.id}`);
      }
    }
  };
  const getDemosTabButtonBasedOnStatus = (
    status: string | undefined,
    width: string | undefined = undefined,
  ) => {
    if (status === 'active') {
      return (
        <StyledButtonPro
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          className={`${width}`}
          onClick={handleReleaseIndependently}
        >
          <>
            {t('myTracks:releaseIndependently') as string}
            <ArrowRight
              style={{ marginLeft: '10px' }}
              size={14}
              stroke={hovering ? '#fff' : '#284ed8'}
            />
          </>
        </StyledButtonPro>
      );
    }
    if (status === 'inactive') {
      return (
        <StyledButtonMakeActive
          color="success"
          className={`px-2 d-flex align-items-center justify-content-around br-5 ${width}`}
          onClick={async (e) => {
            e.preventDefault();
            handleActivate();
          }}
        >
          {t('common:makeActive') as string}
        </StyledButtonMakeActive>
      );
    }

    return <></>;
  };
  const getReleaseTabButtonBasedOnStatus = (status: string) => {
    if (status === 'incorrect details') {
      return (
        <Button
          onClick={() => {
            router.push(`/distribution?track_id=${track.id}`);
          }}
          color="danger"
          className={`d-flex align-items-center  ${
            !isDesktop ? 'w-100' : 'justify-content-around br-5'
          }`}
        >
          <AlertCircle size={14} />
          <span style={{ marginLeft: '5px' }}>
            {t('myTracks:fixIncorrectDetails') as string}
          </span>
        </Button>
      );
    }
    if (status === 'draft') {
      return (
        <>
          <VuexyProGradientButton
            onClick={() => {
              router.push(`/distribution?track_id=${track.id}`);
            }}
            className={`d-flex align-items-center   ${
              !isDesktop
                ? 'w-100 justify-content-center'
                : 'justify-content-around mb-1 br-5'
            }`}
          >
            <>
              <Edit size={14} />
              <span style={{ marginLeft: '5px' }}>
                {t('artist:updateAndRelease') as string}
              </span>
            </>
          </VuexyProGradientButton>
        </>
      );
    }

    return (
      <>
        <MobileFullWidthButton>
          <StyledButtonPro
            disabled={isButtonDisabled}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            className="w-100"
            onClick={() => {
              router.push(`/track-releases/${track.id}`);
            }}
          >
            <>
              {t('organisms:streamNow') as string}
              <Play
                style={{ marginLeft: '10px' }}
                size={14}
                stroke={hovering ? '#fff' : '#284ed8'}
              />
            </>
          </StyledButtonPro>
        </MobileFullWidthButton>
      </>
    );
  };

  const getDataForReleases = () => (
    <>
      <div
        className="d-flex flex-column w-75"
        style={{ gap: '8px', marginTop: '10px' }}
      >
        <MobileFullWidthButton>
          {isReleaseTab ? (
            <>{getReleaseTabButtonBasedOnStatus(track.release_status)}</>
          ) : (
            getDemosTabButtonBasedOnStatus(track.status, 'w-100')
          )}
        </MobileFullWidthButton>
        {track.release_status === 'live' ||
        track.release_status === 'submitted' ||
        track.release_status === 'approved' ? (
          <>
            <MobileTwoButtonGrid>
              <StyledButtonMobile
                outline
                color="secondary"
                onClick={toStats}
                disabled={isButtonDisabled}
              >
                <>
                  <BarChart size={14} style={{ marginRight: '10px' }} />
                  {t('organisms:stats') as string}
                </>
              </StyledButtonMobile>
              <StyledButtonMobile
                outline
                onClick={() => {
                  router.push(`/my-wallet`);
                }}
                disabled={isButtonDisabled}
              >
                <>
                  <DollarSign size={14} style={{ marginRight: '10px' }} />
                  {t('organisms:earnings') as string}
                </>
              </StyledButtonMobile>
            </MobileTwoButtonGrid>
            <MobileFullWidthButton>
              <StyledButtonMobile
                disabled={isButtonDisabled}
                outline
                className="w-100"
                onClick={() => setShowShareModal(true)}
              >
                <>
                  <Share2 size={14} style={{ marginRight: '10px' }} />
                  {t('organisms:share') as string}
                </>
              </StyledButtonMobile>
            </MobileFullWidthButton>
          </>
        ) : (
          <></>
        )}
      </div>
      {/* ------------------------------------ store delivery status and submssion detail modal----------------------------------- */}
      <div className="mt-2">
        <SubmissionAndStoreStatus
          showStoreDeliveryStatus={showTrackStoreDeliveryStatus.isShow}
          handleOpenTrackDeliveryStatusModal={
            handleOpenTrackDeliveryStatusModal
          }
          track={track as Track}
          handleOpen={handleOpenSumissionDetailModal}
          onClose={handleCloseSumissionDetailModal}
          show={show}
        />
      </div>
    </>
  );

  const getDataForDemos = () => (
    <>
      <div className="d-flex flex-column w-75">{getButtonSection()}</div>
      <div
        className="d-flex flex-column w-75"
        style={{ gap: '8px', marginTop: '10px' }}
      >
        <MobileFullWidthButton>
          {isReleaseTab ? (
            <>{getReleaseTabButtonBasedOnStatus(track.release_status)}</>
          ) : (
            getDemosTabButtonBasedOnStatus(track.status, 'w-100')
          )}
        </MobileFullWidthButton>

        <MobileTwoButtonGrid>
          <StyledButtonMobile outline color="secondary" onClick={toEdit}>
            <>
              <Edit2 size={14} style={{ marginRight: '10px' }} />
              {t('organisms:edit') as string}
            </>
          </StyledButtonMobile>
          <StyledButtonMobile
            outline
            color="danger"
            onClick={() => setShowDeleteModal(true)}
          >
            <>
              <Trash2 size={14} style={{ marginRight: '10px' }} />
              {t('organisms:delete') as string}
            </>
          </StyledButtonMobile>
        </MobileTwoButtonGrid>
      </div>
    </>
  );
  return (
    <>
      <div className="text-centered d-flex jusitfy-content-center align-items-center flex-column w-100">
        <TextBold18>{track.title}</TextBold18>
        <TextRegular14 className="text-muted">
          {track.user.display_name}
        </TextRegular14>
        <div className="my-1">
          {isReleaseTab ? (
            <>
              <TrackStatusBadgeReleases track={track} />
            </>
          ) : (
            <>
              <TrackStatusBadgeDemos track={track} />
            </>
          )}
        </div>
        {track !== undefined && (
          <div style={{ minHeight: '170px' }}>
            <PlayerCover
              track={track}
              size={200}
              playTrack={playTrack}
              alwaysShowPlayButton
            />
          </div>
        )}

        {isReleaseTab ? <>{getDataForReleases()}</> : getDataForDemos()}

        <SharingModal
          show={showShareModal}
          close={() => {
            setShowShareModal(false);
          }}
          type="tracks"
          id={track?.id}
        />
        <DeleteModal
          visible={showDeleteModal}
          track={track}
          onDismiss={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
        />
      </div>
      <StoreDeliveryStatusViewModal
        trackStoreDeliveryStatusData={trackStoreDeliveryStatusData}
        visible={showTrackDeliveryStatus}
        onDismiss={() => setShowTrackDeliveryStatus(false)}
      />
    </>
  );
}

interface MyTracksDistributionCardBodyNewProps {
  track?: Track;
  onAction: (action?: string) => void;
}

const MobileTwoButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
`;

const StyledButtonMakeActive = styled(Button)`
  padding: 5px 10px !important;
  font-size: 1rem !important;
  height: 43px !important;
  min-height: 10px !important;
  margin-bottom: 0.28rem;
`;

const StyledButtonMobile = styled(Button)`
  padding: 5px 10px !important;
  font-size: 1rem !important;
  height: 42px !important;
  min-height: 10px !important;
  display: flex;
`;

const StyledButtonPro = styled(ProOutlineButton)`
  padding: 5px 16px !important;
  font-size: 1rem !important;
  height: 42px !important;
  min-height: 10px !important;
  border-radius: 5px !important;
`;

const MobileFullWidthButton = styled.div`
  width: 100%;
`;
export default MyTracksDistributionCardBodyNew;
