/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FormGroup, Label } from 'reactstrap';
import { CountryOption } from '../../api';
import Dropdown from '../atoms/Dropdown';

type Props = {
  label: string;
  InputHandler: (e: any) => void;
  options: CountryOption[];
  selectedOption?: CountryOption;
  inputName: string;
};

export default function PaymentSelectGeneric({
  label,
  InputHandler,
  options,
  selectedOption,
  inputName,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column mt-1">
      <FormGroup>
        <Label for={label}>{t(label)}</Label>
        <Dropdown
          value={selectedOption}
          onChange={(e) => InputHandler(e)}
          placeholder={inputName}
          options={options}
        />
      </FormGroup>
    </div>
  );
}
