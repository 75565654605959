import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import { API, IncorrectDetails, TrackRelease } from '../../api';
import BrowserPageTitle from '../../components/common/BrowserPageTitle';
import Loader from '../../components/common/Loader';
import renderMetaTags from '../../utils/renderMetaTags';
import { TextBold18, TextRegular14 } from '../atoms/Text';
import { Col, Row } from '../atoms/_index';
import { StepperHeader } from '../molecules/_index';
import MainFlowAlbumDetails from './MainFlowAlbumDetails';
import MainFlowArtistDetails from './MainFlowArtistDetails';
import MainFlowMusicStoresDetails from './MainFlowMusicStoresDetails';
import MainFlowPaymentDetails from './MainFlowPaymentDetails';
import MainFlowTrackDetails from './MainFlowTrackDetails';
import { MainFlowContributors, MainFlowFinishPage } from './_index';
import { noDesktop } from '../../style/constants';

function WelcomePageStepper({
  trackId,
  updatePreview,
}: WelcomePageStepperProps) {
  const { t } = useTranslation('organisms');
  const [steps, setSteps] = useState<TrackRelease>();
  const [currentStep, setCurrentStep] = useState('');
  const [
    incorrectDetails,
    setIncorrectDetails,
  ] = useState<IncorrectDetails | null>();

  const updateSteps = async () => {
    const { data, success } = await API.trackReleases.getTrackRelease(trackId);
    if (success) {
      setSteps(data);
      setCurrentStep(data.active_step);
      setIncorrectDetails(data.incorrect_details);
    }
  };

  const getStepsArray = () => (steps ? Object.entries(steps).slice(3) : []);

  const getStep = (stepId: string) => {
    const step = getStepsArray().find((s) => s[0] === stepId);
    return step ? step[1] : {};
  };

  const getCurrentStepComponent = () => {
    switch (currentStep) {
      case 'artist_details':
        return (
          <MainFlowArtistDetails
            nextStepHandler={nextStepHandler}
            trackId={trackId}
            updatePreview={updatePreview}
            isIncorrect={
              !!incorrectDetails &&
              Object.keys(incorrectDetails).includes('artist_details') &&
              !Object.values(
                incorrectDetails.artist_details || {},
              ).every((item) =>
                Array.isArray(item)
                  ? item
                      .map((artist) =>
                        Object.values(artist).every((val) => val),
                      )
                      .reduce((prev, curr) => prev && curr, true)
                  : item,
              )
            }
          />
        );
      case 'track_details':
        return (
          <MainFlowTrackDetails
            nextStepHandler={nextStepHandler}
            trackId={trackId}
            updatePreview={updatePreview}
            isIncorrect={
              !!incorrectDetails &&
              Object.keys(incorrectDetails).includes('track_details') &&
              !Object.values(
                incorrectDetails.track_details || {},
              ).every((item) =>
                Array.isArray(item)
                  ? item
                      .map((artist) =>
                        Object.values(artist).every((val) => val),
                      )
                      .reduce((prev, curr) => prev && curr, true)
                  : item,
              )
            }
          />
        );
      case 'contributors':
        return (
          <MainFlowContributors
            nextStepHandler={nextStepHandler}
            trackId={trackId}
            updatePreview={updatePreview}
            isIncorrect={
              !!incorrectDetails &&
              Object.keys(incorrectDetails).includes('contributors') &&
              !Object.values(
                incorrectDetails.contributors || {},
              ).every((item) =>
                Array.isArray(item)
                  ? item
                      .map((artist) =>
                        Object.values(artist).every((val) => val),
                      )
                      .reduce((prev, curr) => prev && curr, true)
                  : item,
              )
            }
          />
        );
      case 'album_art':
        return (
          <MainFlowAlbumDetails
            nextStepHandler={nextStepHandler}
            trackId={trackId}
            updatePreview={updatePreview}
            isIncorrect={
              !!incorrectDetails &&
              Object.keys(incorrectDetails).includes('album_art') &&
              !Object.values(incorrectDetails.album_art || {}).every((item) =>
                Array.isArray(item)
                  ? item
                      .map((artist) =>
                        Object.values(artist).every((val) => val),
                      )
                      .reduce((prev, curr) => prev && curr, true)
                  : item,
              )
            }
          />
        );
      case 'music_stores':
        return (
          <MainFlowMusicStoresDetails
            nextStepHandler={nextStepHandler}
            trackId={trackId}
          />
        );
      case 'payment_setup':
        return (
          <MainFlowPaymentDetails
            nextStepHandler={nextStepHandler}
            trackId={trackId}
          />
        );
      case 'finish':
        return (
          <MainFlowFinishPage
            trackId={trackId}
            isIncorrect={
              !!incorrectDetails &&
              Object.keys(incorrectDetails).includes('finish') &&
              !Object.values(incorrectDetails.finish || {}).every((item) =>
                Array.isArray(item)
                  ? item
                      .map((artist) =>
                        Object.values(artist).every((val) => val),
                      )
                      .reduce((prev, curr) => prev && curr, true)
                  : item,
              )
            }
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    updateSteps();
  }, []);

  useEffect(() => {}, [steps]);

  const nextStepHandler = () => {
    updateSteps();
    updatePreview(true);
  };

  const navigateToStepHandler = async (newStep: string) => {
    if (!steps) {
      return;
    }

    if (getStep(newStep).completed || newStep === steps.active_step) {
      setCurrentStep(newStep);
      const { data } = await API.trackReleases.getTrackRelease(trackId);
      setSteps(data);
    }
  };

  if (incorrectDetails === undefined) {
    return <Loader />;
  }

  return (
    <Row>
      <BrowserPageTitle>
        {t('organisms:submitForDistribution')}
      </BrowserPageTitle>
      {renderMetaTags({
        'og:title': `${t('organisms:submitForDistribution')} | Listen on VIRPP`,
      })}
      <Col md={4}>
        <div className="w-100 my-2">
          <p>
            <TextBold18>{t('organisms:createARelease')}</TextBold18>
          </p>
          <p>
            <TextRegular14>{t('organisms:scheduleThroughVirpp')}</TextRegular14>
          </p>
        </div>

        <StepperContainer>
          {getStepsArray()
            .filter((f) => f[0] !== 'terms_and_agreement')
            .map(([key, value]) => (
              <StepperHeader
                key={`stepper-header-${key}`}
                activeStep={steps?.active_step}
                step={value}
                isIncorrect={
                  !!incorrectDetails &&
                  Object.keys(incorrectDetails).includes(key) &&
                  !Object.values(
                    incorrectDetails[key as keyof IncorrectDetails] || {},
                  ).every((item) =>
                    Array.isArray(item)
                      ? item
                          .map((artist) =>
                            Object.values(artist).every((val) => val),
                          )
                          .reduce((prev, curr) => prev && curr, true)
                      : item,
                  )
                }
                stepId={key}
                handleClick={() => navigateToStepHandler(key)}
                currentStepId={currentStep}
              />
            ))}
        </StepperContainer>
      </Col>
      <Col md={8}>
        <>{getCurrentStepComponent()}</>
      </Col>
    </Row>
  );
}

export default WelcomePageStepper;

interface WelcomePageStepperProps {
  updatePreview: (val: boolean) => void;
  trackId: number;
}

const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${noDesktop} {
    flex-direction: row;
    overflow: scroll;
  }
`;
