import React, { useState } from 'react';
import Link from 'next/link';
import { SpotifyArtist, TrackArtist, style as colors } from '../../api';
import { TextRegular14 } from '../../components/common/Text';
import { Button } from '../atoms/_index';
import { MainFlowArtistDialog } from '../organisms/_index';

interface MainFlowArtistFoundCardProps {
  artist: SpotifyArtist;
  trackId: number;
  handleCloseSearchDialog: (closeSearchDialog?: boolean) => void;
  fetchArtistDetails: () => void;
  allArtists?: TrackArtist[];
}

function MainFlowArtistFoundCard({
  artist,
  trackId,
  handleCloseSearchDialog,
  fetchArtistDetails,
  allArtists,
}: MainFlowArtistFoundCardProps) {
  const [showArtistDialog, setShowArtistDialog] = useState(false);

  const handleCloseArtistDialog = (closeSearchDialog?: boolean) => {
    setShowArtistDialog(false);
    if (closeSearchDialog) {
      handleCloseSearchDialog(true);
    }
  };

  const handleSelectArtist = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowArtistDialog(true);
  };

  return (
    <div
      className="d-flex w-100 justify-content-between mb-2 mt-1 p-1"
      style={{ backgroundColor: colors.lightSilver }}
    >
      <Link
        href={`${artist.external_urls.spotify}`}
        target="_blank"
        rel="noreferrer"
      >
        <div className="d-flex cursor-pointer text-dark">
          {artist.images[0] ? (
            <>
              <img
                src={artist.images[0].url}
                width="40px"
                height="40px"
                className="rounded-circle"
                alt="avatar-icon"
              />
            </>
          ) : (
            <div
              style={{
                width: '40px',
                height: '40px',
                backgroundColor: 'white',
              }}
              className="rounded-circle"
            />
          )}
          <TextRegular14 className="align-self-center ml-1">
            <div>{artist.name}</div>
          </TextRegular14>
        </div>
      </Link>
      <div className="align-self-center">
        <Button
          color={colors.lightGreen}
          style={{ background: colors.lightGreen }}
          text="Connect"
          size="sm"
          onClick={(e) => handleSelectArtist(e)}
        />
      </div>
      {/* add artist */}
      <MainFlowArtistDialog
        trackId={trackId}
        handleCloseArtistPage={handleCloseArtistDialog}
        isMainFlowArtistDialogOpen={showArtistDialog}
        fetchArtistDetails={fetchArtistDetails}
        spotifyArtist={artist}
        allArtists={allArtists}
        isNewArtist
        isFirstAdded={allArtists === undefined || allArtists?.length === 0}
      />
    </div>
  );
}

export default MainFlowArtistFoundCard;
