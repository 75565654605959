/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { API } from '../../api';
import { StoreSelection } from '../../api/lib/api/track-release';
import Container from '../atoms/Container';
import { Row, Col } from '../atoms/_index';
import { TextBold18, TextBolder14, TextRegular14 } from '../atoms/Text';
import VuexyButton from '../atoms/Button';
import VuexyCustomInput from '../atoms/CustomInput';

interface MainFlowMusicStoresDetailsProps {
  nextStepHandler: () => void;
  trackId: number;
}

function MainFlowMusicStoresDetails({
  trackId,
  nextStepHandler,
}: MainFlowMusicStoresDetailsProps) {
  const { t } = useTranslation('contests');

  const [allCheckBoxes, setAllCheckBoxes] = useState<StoreSelection[]>([]);
  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState<string[]>([]);
  const [serverErrors, setServerErrors] = useState('');

  const fetchAllMusicStores = async () => {
    try {
      const response = await API.trackReleases.getMusicStores();
      setAllCheckBoxes(response.data);
    } catch (error: any) {
      console.log('Errors fetching all music stores ', error);
    }
  };

  const fetchSelectedMusicStores = async () => {
    try {
      const response = await API.trackReleases.getTrackRelease(trackId);

      const stores = response?.data?.music_stores?.stores;

      if (stores.length > 0) {
        setSelectedCheckBoxes(stores);
      } else {
        toggleSelectAll();
      }
    } catch (e) {
      console.log('Errors fetching selected music stores ', e);
    }
  };

  const toggleCheckbox = (label: string) => {
    if (selectedCheckBoxes.includes(label)) {
      setSelectedCheckBoxes(
        selectedCheckBoxes.filter(
          (selectedCheckbox) => selectedCheckbox !== label,
        ),
      );
    } else {
      setSelectedCheckBoxes([...selectedCheckBoxes, label]);
    }
  };

  useEffect(() => {
    fetchAllMusicStores();
  }, []);

  useEffect(() => {
    fetchSelectedMusicStores();
  }, [allCheckBoxes]);

  const handleSave = () => {
    try {
      API.trackReleases.postMusicStores(
        { stores: selectedCheckBoxes },
        trackId,
      );
      nextStepHandler();
    } catch (e: any) {
      console.log('Errors saving selected music stores ', e);
      setServerErrors(e);
    }
  };

  const toggleSelectAll = () => {
    if (selectedCheckBoxes.length === allCheckBoxes.length) {
      setSelectedCheckBoxes([]);
    } else {
      const allCheckBoxesIDs: string[] = [];
      allCheckBoxes.forEach((checkbox) => allCheckBoxesIDs.push(checkbox.id));
      setSelectedCheckBoxes(allCheckBoxesIDs);
    }
  };

  return (
    <Container className="mb-4 px-0">
      <Row className="d-flex justify-content-between pl-0">
        <Col className="d-flex justify-content-between mt-2">
          <TextBold18>{t('organisms:stepperHeader.musicStores')}</TextBold18>
        </Col>
      </Row>
      <Row className="d-flex-block justify-content-between mt-3 mb-2">
        <Col
          xs={12}
          className="d-flex justify-content-between user-select-none"
        >
          <TextBolder14 style={{ color: '#666666' }}>
            {selectedCheckBoxes.length}/{allCheckBoxes.length}{' '}
            {t('organisms:storesSelected')}
          </TextBolder14>

          <TextBolder14
            className="text-dark cursor-pointer"
            style={{ textDecoration: 'underline', color: '#333333' }}
            onClick={toggleSelectAll}
          >
            {selectedCheckBoxes.length === allCheckBoxes.length
              ? t('organisms:deselectAll')
              : t('organisms:selectAll')}
          </TextBolder14>
        </Col>
      </Row>
      <Row className="d-flex mb-3">
        {allCheckBoxes.map((store: StoreSelection) => (
          <Col xs={6} md={4}>
            <div className="mt-2" key={`store-${store.name}`}>
              <VuexyCustomInput
                type="checkbox"
                className="custom-control-Primary font-weight-bold"
                id={store.name}
                label={store.name}
                checked={selectedCheckBoxes.includes(store.id)}
                inline
                onChange={() => toggleCheckbox(store.id)}
              />
            </div>
          </Col>
        ))}
      </Row>
      <div className="d-flex flex-column">
        <TextRegular14>• {t('organisms:selectOneStore')}</TextRegular14>
        <TextRegular14>• {t('organisms:songAvailibilty')}</TextRegular14>
      </div>
      <VuexyButton
        className="mt-3 mb-4 cursor-pointer"
        color="success"
        onClick={handleSave}
        disabled={selectedCheckBoxes.length === 0}
      >
        {t('organisms:saveAndProceed') as string}
      </VuexyButton>
      <p style={{ color: '#E43E1B' }}>{serverErrors}</p>
    </Container>
  );
}

export default MainFlowMusicStoresDetails;
