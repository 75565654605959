import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import useTranslation from 'next-translate/useTranslation';
import { Col, Row } from '../atoms/_index';
import { CountryOption, PaymentInfo, style } from '../../api';
import { TextBold14, TextRegular12, TextRegular14 } from '../atoms/Text';
import { PaymentInfoGeneric, PaymentSelectGeneric } from './_index';
import VuexyCustomInput from '../atoms/CustomInput';

function PaymentDetailsContentDistribution({
  paymentInfoData,
  errorBankMessage,
  errorPaypalMessage,
  countryOptions,
  selectedCountryOptionValue,
  handlePayPalEmailIdChange,
  handleIBANCodeChange,
  handleAccountNumberChange,
  handleBankNameChange,
  handlePaymentCheck,
  setSelectedCountryOptionValue,
  handleCountyCodeChange,
}: PaymentDetailsContentProps) {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <FormGroup className="d-flex flex-row mt-1">
          <FormGroup check>
            <Label check className="d-flex align-items-center h-100">
              <VuexyCustomInput
                name="payment"
                type="radio"
                id="Bank"
                inline
                checked={paymentInfoData?.payment_type === 'bank'}
                onChange={() => handlePaymentCheck('bank')}
              />
              <div className="d-inline-flex flex-column">
                <TextRegular14>{t('pricing:bankDesposit')}</TextRegular14>
                <TextRegular12>{t('pricing:onlyFor')}</TextRegular12>
              </div>
            </Label>
          </FormGroup>
          <FormGroup check className="ml-1">
            <Label check className="d-flex align-items-center h-100">
              <VuexyCustomInput
                name="payment"
                type="radio"
                id="Paypal"
                label="Paypal"
                checked={paymentInfoData?.payment_type === 'paypal'}
                onChange={() => handlePaymentCheck('paypal')}
              />
            </Label>
          </FormGroup>
        </FormGroup>
      </div>
      {paymentInfoData?.payment_type === 'bank' ? (
        <div>
          <Row>
            <Col lg={6}>
              <PaymentInfoGeneric
                label={t('pricing:bankName')}
                InputHandler={handleBankNameChange}
                inputName="BankName"
                value={paymentInfoData.bank_name || ''}
                type="string"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <PaymentInfoGeneric
                label={t('pricing:accountNumber')}
                InputHandler={handleAccountNumberChange}
                inputName="AccountNumber"
                value={paymentInfoData.account_number || ''}
                type="number"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <PaymentInfoGeneric
                label={t('pricing:bicSwift')}
                InputHandler={handleIBANCodeChange}
                inputName="IBANCode"
                value={paymentInfoData.iban_code || ''}
                type="string"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <PaymentSelectGeneric
                label={t('pricing:countryCode')}
                InputHandler={(e) => {
                  setSelectedCountryOptionValue(e);
                  handleCountyCodeChange(e);
                }}
                options={countryOptions}
                selectedOption={selectedCountryOptionValue}
                inputName={t('pricing:countryCode')}
              />
            </Col>
          </Row>
          <div>
            {errorBankMessage && (
              <TextBold14 style={{ color: style.red }}>
                {errorBankMessage}
              </TextBold14>
            )}
          </div>
        </div>
      ) : (
        <div>
          <Row>
            <Col lg={6}>
              <div className="d-flex flex-column mt-1">
                <FormGroup>
                  <Label for={t('pricing:paypalEmailId')}>
                    {t('pricing:paypalEmailId')}
                  </Label>
                  <Input
                    type="text"
                    name="PaypalEmailId"
                    id="PaypalEmailId"
                    placeholder={t('pricing:paypalEmailId')}
                    className="text-dark cursor-pointer"
                    onChange={handlePayPalEmailIdChange}
                    value={paymentInfoData?.paypal_email || ''}
                  />
                  <TextRegular12 className="text-muted">
                    Please fill in your PayPal account email address
                  </TextRegular12>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <div>
            {errorPaypalMessage && (
              <TextBold14 style={{ color: style.red }}>
                {errorPaypalMessage}
              </TextBold14>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentDetailsContentDistribution;

interface PaymentDetailsContentProps {
  paymentInfoData: PaymentInfo;
  errorPaypalMessage: string;
  errorBankMessage: string;
  countryOptions: CountryOption[];
  selectedCountryOptionValue?: CountryOption;
  handlePayPalEmailIdChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleIBANCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAccountNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBankNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePaymentCheck: (paymentType: 'bank' | 'paypal') => void;
  setSelectedCountryOptionValue: React.Dispatch<
    React.SetStateAction<CountryOption | undefined>
  >;
  handleCountyCodeChange: (e: CountryOption) => void;
}
