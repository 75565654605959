import React from 'react';
import styled from 'styled-components';
import { style } from '../../api';

const VuexyProGradientButton = ({
  text,
  children,
  ...props
}: VuexyButtonProps) => (
  <ProGradientButton style={{ borderRadius: '5px' }} {...props}>
    {text} {children}
  </ProGradientButton>
);

interface VuexyButtonProps {
  href?: string;
  text?: string;
  size?: string;
  active?: boolean;
  'aria-label'?: string;
  block?: boolean;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  target?: string;
}

export default VuexyProGradientButton;

const ProGradientButton = styled.button`
  background: ${style.distributionGradient};
  -webkit-background-clip: text;

  white-space: nowrap;
  box-shadow: none;

  color: #fff;
  -webkit-text-fill-color: #fff;
  -webkit-background-clip: border-box;
  background-clip: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  text-decoration: none;
  border-radius: 5px;

  :hover {
    color: #fff;
    -webkit-text-fill-color: #fff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    box-shadow: 0 8px 25px -8px #8338a4;
    transition: box-shadow 0.2s;
  }

  :focus {
    outline: none;
  }

  :hover::before {
    -webkit-mask: none;
  }
`;
