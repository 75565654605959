import React, { useEffect, useState } from 'react';
import Image from 'next/image';

import useTranslation from 'next-translate/useTranslation';
import { API, TrackRelease } from '../../api';
import { TextBold22, TextRegular14 } from '../atoms/Text';
import { TextBold36 } from '../../components/common/Text';

function MainFlowCongratulationBox({
  trackId,
}: MainFlowCongratulationBoxProps) {
  const { t } = useTranslation('artist');
  const [trackRelease, setTrackRelease] = useState<TrackRelease>();

  useEffect(() => {
    API.trackReleases
      .getTrackRelease(trackId)
      .then((response) => setTrackRelease(response.data));
  }, []);

  if (trackRelease === undefined) {
    return <></>;
  }

  return (
    <div>
      <div className="d-flex align-items-center flex-column mt-5">
        <TextBold36 className="mb-2">
          {t('artist:congratulations') as string}
        </TextBold36>
        <TextBold22>{t('artist:signedTrackContract') as string}</TextBold22>
        <TextRegular14>
          {t('artist:notifyAboutRelease') as string}
        </TextRegular14>
        <div className="mt-3 mb-2">
          <Image
            src={trackRelease.album_art.artwork}
            width={200}
            height={200}
            unoptimized
            alt="test"
            style={{
              maxWidth: '100%',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default MainFlowCongratulationBox;

interface MainFlowCongratulationBoxProps {
  trackId: number;
}
