import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { TrackArtist } from '../../api';
import Dialog from '../../components/common/Dialog';
import { TextBold22, TextRegular14 } from '../atoms/Text';
import { Button } from '../atoms/_index';

function MainFlowArtistDeleteDialog({
  isOpen,
  onClose,
  artist,
  firstArtist,
  onDelete,
}: MainFlowArtistDeleteDialogProps) {
  const { t } = useTranslation('organisms');
  if (!artist) {
    return <></>;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="text-center">
      <div className="d-flex justify-content-center flex-column pb-3 w-75 m-auto">
        <div className="text-center mb-1">
          <TextBold22>
            {t('organisms:Are you sure you want to delete')} “
            {artist.artist_name}” {t('organisms:fromArtistsOfThisSong')}
          </TextBold22>
        </div>
        <div className="text-center mb-5">
          <TextRegular14>
            {t('organisms:when')} “{artist.artist_name}”{' '}
            {t('organisms:isDeleted')} {firstArtist.artist_name}
          </TextRegular14>
        </div>
        <div className="d-flex justify-content-center">
          <Button color="danger" onClick={() => onDelete(artist)}>
            <>
              {t('organisms:okayDelete')}
              {artist.artist_name}
            </>
          </Button>
          <Button color="light" onClick={onClose} className="ml-1">
            {t('organisms:cancel') as string}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default MainFlowArtistDeleteDialog;

interface MainFlowArtistDeleteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  artist?: TrackArtist;
  firstArtist: TrackArtist;
  onDelete: (artist?: TrackArtist) => void;
}
