import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Track } from '../../api';
import Button from '../atoms/Button';
import CardText from '../atoms/CardText';

function UpdateDetailsDistributionSection({ track }: { track: Track }) {
  const { t } = useTranslation('artist');
  return (
    <>
      <CardText className="mt-1 text-center">
        {t('artist:trackWasSelected') as string}
      </CardText>
      <Button
        style={{
          width: '200px',
        }}
        color="gradient-pro"
        className="d-flex align-items-baseline justify-content-around mb-1 br-5"
        href={`/distribution?track_id=${track.id}`}
        text={t('artist:updateAndRelease') as string}
      />
    </>
  );
}

export default UpdateDetailsDistributionSection;
