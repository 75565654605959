import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Label, Input } from 'reactstrap';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import { API, SpotifyArtist, TrackArtist, style as colors } from '../../api';
import Dialog from '../../components/common/Dialog';
import { Button, CardBody } from '../atoms/_index';
import {
  TextRegular12,
  TextRegular16,
  TextBolder14,
  TextRegular14,
  TextBolder16,
  TextBold22,
} from '../atoms/Text';
import MainFlowArtistFoundCard from '../molecules/MainFlowArtistFoundCard';
import { MainFlowArtistDialog } from './_index';
import { mobile, noDesktop, useIsMobile } from '../../style/constants';

interface MainFlowArtistBySearchDialogProps {
  handleCloseArtistPage: () => void;
  isMainFlowArtistBySearchDialogOpen: boolean;
  trackId: number;
  fetchArtistDetails: () => void;
  isFirstAdded: boolean;
  allArtists?: TrackArtist[];
}

function MainFlowArtistBySearchDialog({
  trackId,
  handleCloseArtistPage,
  isMainFlowArtistBySearchDialogOpen,
  fetchArtistDetails,
  isFirstAdded,
  allArtists,
}: MainFlowArtistBySearchDialogProps) {
  const { t } = useTranslation('organisms');
  const [showArtist, setShowAritst] = useState(false);
  const [filterByArtistName, setFilterByArtistName] = useState<string>('');
  const [spotifyArtists, setSpotifyArtists] = useState<SpotifyArtist[]>([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const isMobile = useIsMobile();

  const [spotifyId, setSpotifyId] = useState<string>();
  const [spotifyArtist, setSpotifyArtist] = useState<SpotifyArtist>();

  const [error, setError] = useState('');

  const onClose = () => {
    setFilterByArtistName('');
    setSpotifyArtists([]);
    setSpotifyArtist(undefined);
    setShowAritst(false);
    handleCloseArtistPage();
  };

  const handleArtistNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterByArtistName(e.target.value);
  };

  const handleFindSpotyfyArtistClick = async () => {
    try {
      const response = await API.trackReleases.getSpotifyArtists({
        keyword: filterByArtistName,
      });
      setSpotifyArtists(response?.data || []);
      setShowAritst(true);
    } catch (e: any) {
      console.log('Errors fetching selected music stores ', e);
    }
  };

  const handleCloseAddDialog = (closeSearchDialog?: boolean) => {
    setSpotifyArtist(undefined);
    setShowAddDialog(false);
    handleCloseArtistPage();
    if (closeSearchDialog) {
      onClose();
    }
  };

  const handleOpenAddDialog = () => {
    setShowAddDialog(true);
  };

  const handleSpofityLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const parts = value.split('/');

    const newSpotifyId = parts.length > 0 ? parts[parts.length - 1] : undefined;

    setSpotifyId(newSpotifyId);
  };

  const handleSpotifyLinkSearch = async () => {
    await API.trackReleases
      .getSpotifyArtists({ artistId: spotifyId })
      .then((response) => {
        if (response.success) {
          setSpotifyArtist(response.data[0]);
          setError('');
        } else {
          setError(response.message);
        }
      });
  };

  useEffect(() => {
    if (spotifyArtist !== undefined) {
      handleOpenAddDialog();
    }
  }, [spotifyArtist]);

  return (
    <Dialog open={isMainFlowArtistBySearchDialogOpen} onClose={onClose}>
      <DialogContainer className={` ${isMobile ? '' : 'px-3'}`}>
        <CardBody className="text-center d-flex flex-column mx-auto mb-2">
          <TextRegular12
            className="font-weight-bold text-uppercase"
            style={{ color: '#0A5AF5' }}
          >
            {t('organisms:mainArtist')}
          </TextRegular12>
          <TextBold22 className="w-100">
            {t('organisms:artistDetails')}
          </TextBold22>
          <div>
            <Label className="text-left w-100 d-flex">
              <TextRegular14>{t('organisms:artistName')}</TextRegular14>
            </Label>
            <div className="d-flex justify-content-center ">
              <Input
                type="name"
                name="name"
                id="exampleName"
                placeholder={t('organisms:searchArtistName')}
                className="text-dark mr-1"
                onChange={handleArtistNameChange}
                value={filterByArtistName}
              />
              <Button
                color="primary"
                text="Find"
                disabled={!filterByArtistName}
                onClick={handleFindSpotyfyArtistClick}
              />
            </div>
            <TextRegular12 className="text-muted text-left d-block">
              {t('organisms:enterFullArtistName')}
            </TextRegular12>
          </div>
          <>
            {showArtist ? (
              <div className="mt-1">
                <div className="d-flex flex-column text-left mt-1 justify-content-center">
                  {spotifyArtists.length ? (
                    <TextBolder14>
                      {spotifyArtists.length} {t('organisms:artistsFound')}
                    </TextBolder14>
                  ) : (
                    <>
                      <TextBolder14>
                        {t('organisms:noArtistsFound')}
                      </TextBolder14>
                      <TextRegular14>
                        {t('organisms:checkCorrectSpelling')}
                      </TextRegular14>
                    </>
                  )}

                  <TextRegular14 className="w-100 text-left d-flex" />
                </div>

                {spotifyArtists.length ? (
                  spotifyArtists?.map((artist: SpotifyArtist) => (
                    <div key={`spotify-artist-card-${artist.id}`}>
                      <MainFlowArtistFoundCard
                        trackId={trackId}
                        artist={artist}
                        handleCloseSearchDialog={handleCloseAddDialog}
                        fetchArtistDetails={fetchArtistDetails}
                        allArtists={allArtists}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    <div
                      className="d-block-flex pt-2 pb-1 px-3 justify-content-center"
                      style={{ backgroundColor: colors.lightSilver }}
                    >
                      <div className="d-flex justify-content-center align-items-center mb-1">
                        <Image
                          src="/static/images/Spotify.svg"
                          width={24}
                          height={24}
                          alt="test"
                          style={{
                            maxWidth: '100%',
                          }}
                        />
                        Spotify
                      </div>
                      <div className="d-flex justify-content-center">
                        <Input
                          type="text"
                          name="name"
                          id="spotifyLink"
                          placeholder="Add Link to Spotify profile"
                          className="text-dark mr-1"
                          style={{ backgroundColor: colors.lightSilver }}
                          onChange={handleSpofityLinkChange}
                        />
                        <Button
                          color="success"
                          text={t('organisms:add') as string}
                          onClick={handleSpotifyLinkSearch}
                        />
                      </div>
                      <TextRegular14 className="text-danger">
                        {error}
                      </TextRegular14>
                    </div>
                    <div className="text-left d-flex flex-column align-items-center">
                      <TextRegular16
                        className="d-flex text-dark"
                        style={{ paddingBottom: '10px' }}
                      >
                        {t('organisms:ifYourAreNotOn')}
                      </TextRegular16>
                      <Button
                        outline
                        color="dark"
                        text={t('organisms:createNewId') as string}
                        className="w-75 align-self-center"
                        onClick={() => handleOpenAddDialog()}
                      />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="mt-1 d-flex justify-content-center flex-column text-center">
                <TextBolder16 className="mb-1">
                  {t('organisms:or')}
                </TextBolder16>
                <TextRegular14
                  className="text-dark "
                  style={{ paddingBottom: '10px' }}
                >
                  {t('organisms:ifYourAreNotOn')}
                </TextRegular14>
                <Button
                  outline
                  color="dark"
                  text={t('organisms:createNewId') as string}
                  className="w-75 align-self-center"
                  onClick={() => handleOpenAddDialog()}
                />
              </div>
            )}
          </>
        </CardBody>
      </DialogContainer>
      <MainFlowArtistDialog
        trackId={trackId}
        handleCloseArtistPage={handleCloseAddDialog}
        isMainFlowArtistDialogOpen={showAddDialog}
        isNewArtist
        fetchArtistDetails={fetchArtistDetails}
        isFirstAdded={isFirstAdded}
        allArtists={allArtists}
        spotifyArtist={spotifyArtist}
      />
    </Dialog>
  );
}

export default MainFlowArtistBySearchDialog;

const DialogContainer = styled.div`
  width: 50rem;
  @media ${noDesktop} {
    width: 35rem;
  }
  @media ${mobile} {
    width: 100%;
  }
`;
