import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { DocusignSignature } from '../../api';
import { TextBolder12, TextBolder14, TextRegular12 } from '../atoms/Text';
import { CardText, Button } from '../atoms/_index';

function AwaitingSignautres({ trackId }: AwaitingSignautresProps) {
  const { t } = useTranslation('artist');
  const [artistSignatures, setArtistSignatures] = useState<
    DocusignSignature[]
  >();
  const [showArtists, setShowArtists] = useState(false);

  const handleClick = () => {
    setShowArtists((prev) => !prev);
  };

  const getArtistType = (artistSignature: DocusignSignature) => {
    switch (artistSignature.artist_type) {
      case 'MAINARTIST':
        return t('artist:mainArtist');
      case 'FEATUREDARTIST':
        return t('artist:featuredArtist');
      case 'CONTRIBUTOR':
        return t('artist:contributor');
      default:
        return t('artist:artist');
    }
  };

  useEffect(() => {
    // API.trackReleases.getDocusignSignatures(trackId).then((resp) => {
    //   setArtistSignatures(resp.data?.artists);
    // });
    setArtistSignatures([]);
  }, []);

  return (
    <>
      <CardText className="mt-1 text-center">
        {t('artist:trackWasSelected') as string}
      </CardText>
      <Button
        color="secondary"
        className="px-2 d-flex mb-1"
        onClick={handleClick}
      >
        {t('artist:pendingSignatures') as string}
      </Button>
      <div
        style={{
          display: `${showArtists ? 'block' : 'none'}`,
          zIndex: 20,
          boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.1)',
        }}
        className="w-100 mb-2 p-1"
      >
        {artistSignatures &&
          artistSignatures.map((artistSignature) => (
            <div
              className="w-100 d-flex flex-column"
              key={`artist-signature-${artistSignature.user_id}-${trackId}`}
              style={{ marginTop: '5px' }}
            >
              <TextBolder14>{artistSignature.artist_name}</TextBolder14>
              <div>
                <TextRegular12 className="text-muted">
                  {getArtistType(artistSignature)}
                </TextRegular12>{' '}
                <TextBolder12
                  className={`${
                    artistSignature.signed ? 'text-success' : 'text-danger'
                  }`}
                >
                  {artistSignature.signed
                    ? t('artist:signed')
                    : t('artist:notSigned')}
                </TextBolder12>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default AwaitingSignautres;

interface AwaitingSignautresProps {
  trackId: number;
}
