import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import moment from 'moment';
import { Edit, Clock, Radio, ThumbsDown } from 'react-feather';
import { Check } from '@styled-icons/material';
import { Track } from '../../../api';
import { Badge } from '../../../VuexyDesign/atoms/_index';

const TrackStatusBadgeReleases = ({ track }: { track: Track }) => {
  const { t } = useTranslation();
  const [trackStatus, setTrackStatus] = useState({
    title: '',
    color: '',
    icon: <Check size={14} />,
  });

  const tl = (translation: string) => t(translation) as string;
  useEffect(() => {
    if (track.release_status === 'approved') {
      setTrackStatus({
        title: `${tl('myTracks:statuses.ScheduledOn')} ${moment(
          track?.release_date,
        ).format('DD/MM/YYYY')}`,
        color: 'light-info',
        icon: <Clock size={11} style={{ marginRight: '3px' }} />,
      });
    } else if (track.release_status === 'draft') {
      setTrackStatus({
        title: tl('myTracks:statuses.draft'),
        color: 'light-secondary',
        icon: <Edit size={11} style={{ marginRight: '3px' }} />,
      });
    } else if (track.release_status === 'incorrect details') {
      setTrackStatus({
        title: tl('myTracks:statuses.declined'),
        color: 'light-danger',
        icon: <ThumbsDown size={11} style={{ marginRight: '3px' }} />,
      });
    } else if (track.release_status === 'submitted') {
      setTrackStatus({
        title: tl('myTracks:statuses.submitted'),
        color: 'light-warning',
        icon: <Clock size={11} style={{ marginRight: '3px' }} />,
      });
    } else if (track.release_status === 'live') {
      setTrackStatus({
        title: tl('myTracks:statuses.live'),
        color: 'light-success',
        icon: <Radio size={11} style={{ marginRight: '3px' }} />,
      });
    }
  }, [track.release_status]);
  return (
    <Badge
      color={trackStatus.color}
      pill
      text={trackStatus.title}
      icon={<span style={{ marginRight: '5px' }}>{trackStatus.icon}</span>}
    />
  );
};

export default TrackStatusBadgeReleases;
