import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Label, Input } from 'reactstrap';
import Image from 'next/image';
import { IoMdCheckmark as Checkmark } from 'react-icons/io';
import useTranslation from 'next-translate/useTranslation';
import { useCurrentUser, API } from '../../api';
import Loader from '../../components/common/Loader';
import {
  TextBold14,
  TextBold16,
  TextBolder14,
  TextBolder16,
  TextRegular14,
  TextRegular16,
} from '../atoms/Text';
import { Button } from '../atoms/_index';

function MainFlowMobile() {
  const { t } = useTranslation('organisms');
  const router = useRouter();
  const currentUser = useCurrentUser();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [showEmailChange, setShowEmailChange] = useState(false);
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState('');

  const trackId = Number(router.query.track_id?.toString());

  if (!trackId || Number.isNaN(trackId)) {
    router.push('/my-tracks');
  }

  useEffect(() => {
    if (currentUser.email && userEmail === '') {
      setUserEmail(currentUser.email);
    }
  }, [currentUser]);

  const handleSendMail = () => {
    API.trackReleases
      .emailInstructions({ email: userEmail }, trackId)
      .then((resp) => {
        if (resp.success) {
          setError('');
          setIsSuccessful(true);
        } else {
          setError(resp.message);
        }
      });
  };

  const handleShowChange = () => {
    setShowEmailChange(true);
  };

  const handleNewEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.target.value);
  };

  const handleNewEmailSave = () => {
    if (!userEmail) {
      return;
    }
    if (!userEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setValidationError(t('organisms:invalidEmail') as string);
      return;
    }
    setValidationError('');
    setShowEmailChange(false);
  };

  useEffect(() => {}, [userEmail]);

  if (currentUser.requesting) {
    return (
      <div className="d-flex flex-column text-center w-100 align-items-center">
        <Loader />
      </div>
    );
  }

  if (showEmailChange) {
    return (
      <div className="d-flex flex-column w-100 align-items-center text-left mt-5">
        <div className="mb-2 w-75">
          <Label for="newEmail" className="text-left w-100">
            <TextRegular14>{t('organisms:enterEmailId')}</TextRegular14>
          </Label>
          <Input
            type="email"
            name="email"
            id="newEmail"
            className="w-100 text-dark"
            onChange={handleNewEmailChange}
          />
          {validationError && (
            <span className="text-danger">{validationError}</span>
          )}
        </div>
        <div className="w-75 d-flex justify-content-center">
          <Button
            color="primary"
            className="m-auto"
            onClick={handleNewEmailSave}
          >
            {t('organisms:changeEmailId') as string}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column text-center w-100 align-items-center mt-2 px-2">
      <TextBold16 className="mb-1">
        {t('organisms:pleaseSwitchTo')}{' '}
        <span className="font-weight-bolder">{t('organisms:desktop')}</span>{' '}
        {t('organisms:forReleaseSubmission')}
      </TextBold16>
      <Image
        alt="laptop-icon"
        src="/static/images/virppLaptop.svg"
        width={200}
        height={170}
        style={{
          maxWidth: '100%',
        }}
      />
      <div className="text-muted mb-3">
        <TextRegular14>{t('organisms:openLinkThrough')}</TextRegular14>
        <TextBold14> {t('organisms:desktopBrowserOnly')}</TextBold14>
      </div>
      <div className="mb-2">
        <TextRegular14 className="d-block">
          {t('organisms:emailId')}
        </TextRegular14>
        <TextBolder16>{userEmail}</TextBolder16>
      </div>
      <div className="mb-3 w-100">
        <Button
          outline
          size="sm"
          className="w-100 text-dark"
          onClick={handleShowChange}
        >
          <TextBolder14>{t('organisms:changeEmailId')}</TextBolder14>
        </Button>
      </div>
      <div className="mb-3 w-100">
        <Button
          color="primary"
          size="sm"
          className="w-75"
          onClick={handleSendMail}
        >
          <TextBolder14>
            {t('organisms:emailMeFurtherInstructions')}
          </TextBolder14>
        </Button>
      </div>
      {error !== '' && (
        <TextRegular16 className="text-danger">{error}</TextRegular16>
      )}
      {isSuccessful && (
        <TextRegular16 className="text-success">
          {t('organisms:emailSent')} <Checkmark color="success" />
        </TextRegular16>
      )}
    </div>
  );
}

export default MainFlowMobile;
