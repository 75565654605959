import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ArrowRight } from 'react-feather';
import { Player } from '@lottiefiles/react-lottie-player';
import Link from 'next/link';
import { Button, Col, Row } from '../atoms/_index';

function HowItWorksRowMobile({
  headerTitle,
  title,
  subtitle,
  media,
  button,
  picture,
  buttonLink,
}: HowItWorksRowMobileProps) {
  const { t } = useTranslation('howItWorks');
  return (
    <Row className="d-flex mt-3 mx-1">
      <Col
        xs="12"
        lg="5"
        className="w-100 mb-1 px-0"
        style={{ height: '25rem' }}
      >
        {picture ? (
          <div className="rounded-lg w-100">
            <Player
              src={media}
              style={{ height: '27rem' }}
              loop
              autoplay
              background="primary"
            />
          </div>
        ) : (
          <div className="w-100 h-100 rounded-lg overflow-hidden">
            <iframe
              title="virpp how it works"
              className="w-100 h-100"
              frameBorder="0"
              src={media}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        )}
      </Col>
      <Col
        xs="12"
        lg="7"
        className="d-flex flex-column justify-content-center px-0"
      >
        <span className="custom-font-text-tiny-bold text-primary font-weight-bolder">
          {t(headerTitle)}
        </span>
        <span className="custom-font-text-larger-bold">{t(title)}</span>
        <span className="custom-font-text-regular-normal w-100">
          {t(subtitle)}
        </span>
      </Col>
      <Col className="pl-0" xs="9">
        <Link href={`${buttonLink}`}>
          <Button
            className={`mt-1 ${
              button ? '' : 'd-none'
            } text-dark w-50 border-black`}
            color="white"
            text={t('howItWorks:readMore') as string}
            size="sm"
          >
            <ArrowRight size={18} />
          </Button>
        </Link>
      </Col>
    </Row>
  );
}

export default HowItWorksRowMobile;

interface HowItWorksRowMobileProps {
  headerTitle: string;
  title: string;
  subtitle: string;
  media: string;
  button?: boolean;
  picture?: boolean;
  buttonLink?: string;
}
