import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FormGroup, Label } from 'reactstrap';
import { format, addDays } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import { API, useCurrentUser, IncorrectDetails } from '../../api';
import Button from '../atoms/Button';
import { FinishPageContent } from '../molecules/_index';
import { DatePickerComponent } from '../atoms/_index';
import { TextBold14 } from '../atoms/Text';
import { ToastError, ToastSuccess } from '../../components/Toast';
import { addToDataLayerGA } from '../../utils/googleAnalytics';
import { getPlanNamesFromPlanTypes } from '../../utils/getPlanNamesFromPlanTypes';

interface MainFlowFinishPageProps {
  trackId: number;
  isIncorrect?: boolean;
}

export function MainFlowFinishPage({
  trackId,
  isIncorrect,
}: MainFlowFinishPageProps) {
  const { t } = useTranslation('organisms');
  const router = useRouter();

  // TODO change logic for useCurrentUser param
  // TODO dont hardcode it
  const currentUser = useCurrentUser(true);

  const [releaseDate, setReleaseDate] = useState<Date>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [incorrectDetails, setIncorrectDetails] = useState<IncorrectDetails>();

  const [isDatepickerDisabled, setIsDatepickerDisabled] = useState(false);

  const defaultDate = new Date(format(addDays(new Date(), 30), 'd MMM yyyy'));

  const handleSave = () => {
    if (isIncorrect && releaseDate) {
      // setIsLoadingDocusign(true);
      API.trackReleases
        .postFinishPage(
          {
            original_release_date: format(new Date(releaseDate), 'yyyy-MM-dd'),
          },
          trackId,
        )
        .then((response) => {
          // setIsLoadingDocusign(false);
          if (response.data.success) {
            router.push(`/tracks/${trackId}`);
          }
        });
      return;
    }
    if (releaseDate) {
      // && !isLoadingDocusign) {
      try {
        // setIsLoadingDocusign(true);
        API.trackReleases
          .postFinishPage(
            {
              original_release_date: format(
                new Date(releaseDate),
                'yyyy-MM-dd',
              ),
            },
            trackId,
          )
          .then((response) => {
            if (response.status === 200) {
              router.push(`/tracks/${trackId}`);
            } else if (response.status === 422) {
              setErrorMessage(response.data.message);
            } else {
              ToastError('An error has occured!');
            }
          });
      } catch (error: any) {
        console.log('Errors saving selected music stores ', error);
      }
    }
  };

  const handlePickerChange = (date: Date) => {
    setReleaseDate(date);
    setErrorMessage(undefined);
  };

  useEffect(() => {
    API.trackReleases.getTrackRelease(trackId).then((response) => {
      const originalReleaseDate = response.data.finish.original_release_date;
      if (originalReleaseDate) {
        const incorrect_details = response?.data.incorrect_details;

        setReleaseDate(new Date(originalReleaseDate));
        setErrorMessage(undefined); // If the date has been already saved to db it must be valid
        setIncorrectDetails(incorrect_details);

        setIsDatepickerDisabled(
          incorrect_details?.finish?.original_release_date === undefined ||
            incorrect_details?.finish?.original_release_date === true,
        );
      }
    });
    if (
      router.query.session_id ||
      router.query.redirect_status ||
      router.query.subscription_id
    ) {
      // This is used to remove the query params from Stripe/PayPal
      // They are only needed here once to show the success toast
      router.push(`/distribution?track_id=${trackId}`, undefined, {
        shallow: true,
      });

      // Recetching the user in case the user has not been updated yet.
      currentUser.refetch().then((resp) => {
        addToDataLayerGA({
          event: 'artist_sub_pro',
          userId: resp.data?.id,
          userType: getPlanNamesFromPlanTypes(resp.data?.abilities?.plan),
        });
      });

      ToastSuccess(t('organisms:paymentProcessedSuccesfully'));
    }
  }, []);

  return (
    <>
      <div>
        <FinishPageContent
          header="Finish"
          trackId={trackId}
          isIncorrect={isIncorrect}
          incorrectDetails={incorrectDetails}
        />
        <div>
          <FormGroup>
            <Label>{t('organisms:originalReleaseDate')}</Label>
            <div>
              <DatePickerComponent
                selectedDate={releaseDate}
                handleDatePickerChange={(e: Date) => handlePickerChange(e)}
                format="d MMM yyyy"
                openToDate={releaseDate || defaultDate}
                minDate={
                  releaseDate !== undefined &&
                  releaseDate < addDays(new Date(), 30)
                    ? releaseDate
                    : addDays(new Date(), 30)
                }
                disabled={isDatepickerDisabled}
              />
            </div>
          </FormGroup>
          {errorMessage && (
            <TextBold14 className="text-danger">{errorMessage}</TextBold14>
          )}
          {isDatepickerDisabled ? (
            <></>
          ) : (
            <ul className="pl-2 mt-1">
              <li>
                {t('organisms:cannotBeEarlier')}{' '}
                {format(addDays(new Date(), 30), 'd MMM yyyy')}
              </li>
            </ul>
          )}
        </div>
        <div className="d-flex">
          <Button
            className="mt-3 mb-4"
            color="success"
            text={t('organisms:saveAndFinish') as string}
            onClick={() => handleSave()}
            disabled={errorMessage !== undefined}
          />
          {/* {isLoadingDocusign && <Loader className="ml-1" />} */}
        </div>
      </div>
    </>
  );
}
export default MainFlowFinishPage;
