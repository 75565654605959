import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ArrowRight } from 'react-feather';
import { Player } from '@lottiefiles/react-lottie-player';
import Link from 'next/link';
import { Button, Col, Row } from '../atoms/_index';

function HowItWorksRowDesktop({
  key,
  headerTitle,
  title,
  subtitle,
  media,
  leftColumn,
  rightColumn,
  videoLeft,
  button,
  picture,
  buttonLink,
}: HowItWorksRowDesktopProps) {
  const { t } = useTranslation('howItWorks');
  return (
    <Row
      key={key}
      className={`d-flex mt-3 w-75 ${videoLeft ? 'flex-row-reverse' : ''}`}
    >
      <Col
        xs="12"
        lg={leftColumn}
        className={`d-flex flex-column justify-content-center pl-0 ${
          videoLeft ? 'offset-md-1' : 'offset-md-0'
        }`}
      >
        <span className="custom-font-text-tiny-medium text-primary font-weight-bolder">
          {t(headerTitle)}
        </span>
        <span className="custom-heading-font-4 w-75">{t(title)}</span>
        <span className="custom-font-text-regular-normal w-75">
          {t(subtitle)}
        </span>
        <Link href={`${buttonLink}`}>
          <Button
            className={`mt-1 ${
              button ? '' : 'd-none'
            } text-primary rounded mb-sm-0 mr-1`}
            color="primary"
            outline
            text={t('howItWorks:readMore') as string}
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              width: '140px',
            }}
          >
            <ArrowRight size={18} />
          </Button>
        </Link>
      </Col>
      <Col
        style={{ height: '30rem' }}
        xs="12"
        lg={rightColumn}
        className="pl-0"
      >
        {picture ? (
          <Player src={media} style={{ height: '33rem' }} loop autoplay />
        ) : (
          <div className="w-100 h-100 rounded-lg  overflow-hidden">
            <iframe
              title="virpp how it works"
              className="w-100 h-100"
              frameBorder="0"
              src={media}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        )}
      </Col>
    </Row>
  );
}

export default HowItWorksRowDesktop;

interface HowItWorksRowDesktopProps {
  key: number;
  headerTitle: string;
  title: string;
  subtitle: string;
  media: string;
  leftColumn?: string;
  rightColumn?: string;
  videoLeft: boolean;
  button?: boolean;
  picture?: boolean;
  buttonLink?: string;
}
