import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { ArrowRight } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { TextBold28, TextBolder12 } from '../atoms/Text';
import { Col, Row, Badge, Button } from '../atoms/_index';
import { useIsMobile } from '../../style/constants';

function DistributionLandingStep({
  title,
  secondTitle,
  desc,
  imgUrl,
  isSecondary,
  isComingSoon,
  isLoggedIn,
  hasTracks,
  onRequestRelease,
  buttonText,
}: StepProps) {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <StepMobile
          title={title}
          secondTitle={secondTitle}
          desc={desc}
          imgUrl={imgUrl}
          isSecondary={isSecondary}
          isComingSoon={isComingSoon}
          isLoggedIn={isLoggedIn}
          hasTracks={hasTracks}
          onRequestRelease={onRequestRelease}
          buttonText={buttonText}
        />
      ) : (
        <StepDesktop
          title={title}
          secondTitle={secondTitle}
          desc={desc}
          imgUrl={imgUrl}
          isSecondary={isSecondary}
          isComingSoon={isComingSoon}
          isLoggedIn={isLoggedIn}
          hasTracks={hasTracks}
          onRequestRelease={onRequestRelease}
          buttonText={buttonText}
        />
      )}
    </>
  );
}

const StepDesktop = ({
  title,
  secondTitle,
  desc,
  imgUrl,
  isSecondary,
  isComingSoon,
  isLoggedIn,
  hasTracks,
  onRequestRelease,
  buttonText,
}: StepProps) => {
  const { t } = useTranslation('organisms');

  const getButton = () => {
    if (!isLoggedIn) {
      return (
        <Link href="/login?redirect=/upload">
          <Button color="primary" outline text={buttonText[0]}>
            <ArrowRight />
          </Button>
        </Link>
      );
    }

    if (!hasTracks) {
      return (
        <Link href="/upload?distribution=true">
          <Button color="primary" outline text={buttonText[1]}>
            <ArrowRight />
          </Button>
        </Link>
      );
    }

    return (
      <Button
        color="primary"
        outline
        text={buttonText[1]}
        onClick={onRequestRelease}
      >
        <ArrowRight />
      </Button>
    );
  };

  return (
    <Row
      className={`mx-auto ${
        isSecondary ? 'flex-row-reverse' : 'flex-row'
      } align-items-center mb-5 w-75`}
    >
      <Col
        sm={6}
        className={`${
          isSecondary
            ? 'pr-md-0 text-center text-md-right'
            : 'pl-md-0 text-center text-md-left'
        } pl-0 pr-0`}
      >
        <Image
          src={imgUrl}
          priority
          unoptimized
          height="500"
          width="1000"
          className="pointer-events-none object-fit-contain"
          alt="img-steps"
          style={{
            maxWidth: '100%',
          }}
        />
      </Col>
      <Col
        sm={6}
        className={`${
          isSecondary ? 'pr-md-4' : 'pl-md-4'
        } pl-0 pr-0 mt-1 mb-mt-0`}
      >
        <div>
          <TextBolder12 className="text-uppercase text-primary">
            {title}
          </TextBolder12>
          {isComingSoon && (
            <Badge
              text={t('organisms:comingSoon')}
              color="warning"
              className="text-white ml-1"
            />
          )}
        </div>
        <TextBold28>{secondTitle}</TextBold28>
        <p>{desc}</p>
        {getButton()}
      </Col>
    </Row>
  );
};

const StepMobile = ({
  title,
  secondTitle,
  desc,
  imgUrl,
  isSecondary,
  isComingSoon,
  isLoggedIn,
  hasTracks,
  onRequestRelease,
  buttonText,
}: StepProps) => {
  const { t } = useTranslation('organisms');

  const getButton = () => {
    if (!isLoggedIn) {
      return (
        <Link href="/login?redirect=/upload">
          <Button
            color="primary"
            outline
            text={buttonText[0]}
            className="w-100"
          >
            <ArrowRight />
          </Button>
        </Link>
      );
    }

    if (!hasTracks) {
      return (
        <Link href="/upload?distribution=true">
          <Button
            color="primary"
            outline
            text={buttonText[1]}
            className="w-100"
          >
            <ArrowRight />
          </Button>
        </Link>
      );
    }

    return (
      <Button
        color="primary"
        outline
        text={buttonText[1]}
        onClick={onRequestRelease}
        className="w-100"
      >
        <ArrowRight />
      </Button>
    );
  };

  return (
    <>
      <Row
        className={`mx-auto ${
          isSecondary ? 'flex-row-reverse' : 'flex-row'
        } align-items-center mb-5 w-100`}
      >
        <Col
          xs={12}
          className={`${
            isSecondary
              ? 'pr-md-0 text-left text-md-right'
              : 'pl-md-0 text-left text-md-left'
          } pl-0 pr-0`}
        >
          <div>
            <TextBolder12 className="text-uppercase text-primary">
              {title}
            </TextBolder12>
            {isComingSoon && (
              <Badge
                text={t('organisms:comingSoon')}
                color="warning"
                className="text-white ml-1"
              />
            )}
          </div>
          <TextBold28>{secondTitle}</TextBold28>
        </Col>
        <Col
          xs={12}
          className={`${
            isSecondary ? 'pr-md-4' : 'pl-md-4'
          } pl-0 pr-0 mt-1 mb-mt-0`}
        >
          <Image
            src={imgUrl}
            priority
            unoptimized
            height="500"
            width="1000"
            className="pointer-events-none br-5"
            alt="img-steps"
            style={{
              maxWidth: '100%',

              objectFit: 'cover',
            }}
          />
        </Col>
        <Col
          xs={12}
          className={`${
            isSecondary ? 'pr-md-4' : 'pl-md-4'
          } pl-0 pr-0 mt-1 mb-mt-0`}
        >
          <p>{desc}</p>
          {getButton()}
        </Col>
      </Row>
      <div className="w-100 mt-2 mb-3">
        <hr />
      </div>
    </>
  );
};

interface StepProps {
  title: string;
  secondTitle: string;
  desc: string;
  imgUrl: string;
  isSecondary?: boolean;
  isComingSoon?: boolean;
  isLoggedIn: boolean;
  hasTracks: boolean;
  onRequestRelease?: () => void;
  buttonText: string[];
}

export default DistributionLandingStep;
