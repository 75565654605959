import React from 'react';
import Head from 'next/head';

const defaultVals: { [key: string]: string | undefined | number } = {
  'fb:app_id': process.env.FACEBOOK_APP_ID,
  'og:site_name': 'VIRPP',
  'og:image:width': 1200,
  'og:image:height': 630,
  'twitter:card': 'summary',
};

export const createTrackShareImageUrl = (trackId: number) =>
  `https://${process.env.S3_SHARING_URL}/${trackId}/tracks/share_1200x630.jpg`;

const RenderMetaTags = (vals: { [key: string]: string }) => {
  const valsWithDefaults = { ...defaultVals, ...vals };
  return (
    <Head>
      {Object.keys(valsWithDefaults).map((key) => (
        <meta
          key={key}
          property={key}
          content={valsWithDefaults[key] as string}
        />
      ))}
    </Head>
  );
};

export default RenderMetaTags;
