import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Edit2 } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import {
  API,
  authenticationActions,
  TrackRelease,
  useDispatch,
  useCurrentUser,
  useAuthentication,
} from '../../api';
import Button from '../atoms/Button';
import { FinishPageContent } from '../molecules/_index';
import { Col, Row } from '../atoms/_index';
import MainFlowPreview from './MainFlowPreview';
import { login } from '../../utils/auth';
import Loader from '../../components/common/Loader';
import useLoginWithRedirect from '../../hooks/useLoginWithRedirect';

function MainFlowSecondArtistFinish({
  trackId,
  code,
}: MainFlowSecondArtistFinishProps) {
  const { t } = useTranslation('organisms');
  const router = useRouter();
  const currentUser = useCurrentUser();
  const { isLoggedIn } = useAuthentication();

  const dispatch = useDispatch();
  const [trackRelease, setTrackRelease] = useState<TrackRelease>();
  // const [showDocusignDialog, setShowDocusignDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [docusignUrl, setDocusignUrl] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true);

  // @ts-ignore
  const loginWithRedirect = useLoginWithRedirect(false, router.query, false);

  const loginCallback = (token: string) =>
    dispatch(authenticationActions.verifyLoginOnStartup(token));

  const loginUserWithCode = async () => {
    if (code) {
      const {
        headers: { authorization: token },
      } = await API.user.loginUserWithCode({ code });

      await login({ token, cb: loginCallback });
    }
  };

  useEffect(() => {
    if (code) {
      loginUserWithCode();
    }
  }, []);

  useEffect(() => {
    if (!shouldFetch) {
      return;
    }

    // API.trackReleases
    //   .getDocusignContract(trackId)
    //   .then((resp) => {
    //     if (resp.success) {
    //       setDocusignUrl(resp.data.redirect_url);
    //     }
    //   })
    //   .catch((err) => console.log(err));

    if (!currentUser.requesting && currentUser.id) {
      if (trackRelease === undefined) setShouldFetch(false);
      API.trackReleases.getTrackRelease(trackId).then((resp) => {
        setTrackRelease(resp.data);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (trackRelease !== undefined) {
      setIsLoading(false);
    }
  }, [trackRelease]);

  // const handleDocusignDialogClose = () => {
  //   API.trackReleases.getDocusignSignatures(trackId).then((resp) => {
  //     const signingUser = resp.data.artists.find(
  //       (artist: DocusignSignature) => artist.user_id === currentUser?.id,
  //     );

  //     if (signingUser?.signed) {
  //       router.push('/profile/edit');
  //     }
  //   });
  // };

  if (!isLoggedIn && !code) {
    loginWithRedirect();
  }
  if (isLoading && trackRelease === undefined) {
    return (
      <div className="w-100 m-auto px-2 pt-5 mt-5 d-flex justify-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Row className="w-50 m-auto px-2">
        <Col xs={8}>
          <FinishPageContent
            header={t('organisms:releaseContract') as string}
            trackId={trackId}
          />
          <Link href={`/tracks/${trackId}`}>
            <Button
              color="success"
              text={t('organisms:viewTrack') as string}
              // onClick={() => setShowDocusignDialog(true)}
            >
              <Edit2 style={{ marginLeft: '5px' }} size={12} />
            </Button>
          </Link>
        </Col>
        <Col xs={4}>
          <MainFlowPreview
            trackId={trackId}
            shouldUpdate={false}
            setShouldUpdate={() => {}}
          />
        </Col>
      </Row>

      {/* <DocusignDialog
        docusignUrl={docusignUrl}
        trackId={trackId}
        show={showDocusignDialog}
        onClose={handleDocusignDialogClose}
      /> */}
    </>
  );
}

export default MainFlowSecondArtistFinish;

interface MainFlowSecondArtistFinishProps {
  trackId: number;
  code?: string;
}
