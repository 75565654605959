import React from 'react';
import VuexyIcon from '../atoms/Icon';

const data = [
  {
    src: 'https://tracks.virpp.com/images/icons/spotifyIcon.png',
  },
  {
    src: 'https://tracks.virpp.com/images/icons/iTunesIcon.png',
  },
  {
    src: 'https://tracks.virpp.com/images/icons/youtubeMusicIcon.png',
  },
  {
    src: 'https://tracks.virpp.com/images/icons/amazonMusicIcon.png',
  },
  {
    src: 'https://tracks.virpp.com/images/icons/deezerIcon.png',
  },
  {
    src: 'https://tracks.virpp.com/images/icons/tidalIcon.png',
  },
  {
    src: 'https://tracks.virpp.com/images/icons/instagramIcon.png',
  },
  {
    src: 'https://tracks.virpp.com/images/icons/tiktokIcon.png',
  },
];

// TODO - add alt in here since it is required in next/image for NextJS 13
const VuexyIconGroup = ({ iconSize, ...props }: VuexyIconGroupProps) => (
  <div {...props} className="d-flex">
    {data.map((item) => (
      <div
        className="p-0 d-inline-flex align-items-center"
        style={{ marginRight: '8px' }}
        key={`icon_${item.src}`}
      >
        <VuexyIcon
          src={item.src}
          width={iconSize}
          height={iconSize}
          alt="test"
        />
      </div>
    ))}
  </div>
);

interface VuexyIconGroupProps {
  iconSize: number;
  className?: string;
}

export default VuexyIconGroup;
