import React from 'react';
import { Track } from '../../api';
import Card from '../atoms/Card';
import MyTracksDistributionCardBodyNew from './MyTracksDistributionCardBodyNew';

function MyTracksDistributionCard({
  track,
  onAction,
}: MyTracksDistributionCardProps) {
  if (!track) {
    return null;
  }

  return (
    <Card
      className="d-flex flex-column align-items-center p-2"
      style={{
        minWidth: '270px',
        maxWidth: '420px',
        maxHeight: '78vh',
        overflowY: 'auto',
      }}
    >
      <MyTracksDistributionCardBodyNew track={track} onAction={onAction} />
    </Card>
  );
}

interface MyTracksDistributionCardProps {
  track?: Track;
  onAction: (action?: string) => void;
}

export default MyTracksDistributionCard;
