import React from 'react';
import Image from 'next/image';
import { IoMdCheckmark as Checkmark } from 'react-icons/io';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import { TrackArtist, style } from '../../api';
import { TextBold16, TextRegular12, TextRegular14 } from '../atoms/Text';

interface MainFlowArtistCardProps {
  artist: TrackArtist;
}

function MainFlowArtistCard({ artist }: MainFlowArtistCardProps) {
  const { t } = useTranslation('organisms');
  const newArtistRoleArray = artist?.artist_role
    ?.map((role) =>
      role === t('organisms:lyricist') ? t('organisms:songwriter') : role,
    )
    .join(', ');
  const isCorrect = false;
  return (
    <div
      style={{
        border: '3px solid #CCCCCC',
        borderRadius: '4px',
      }}
      className="p-1"
    >
      <div className="d-flex flex-column">
        <TextRegular12
          className="text-uppercase mb-1"
          style={{ color: '#0A5AF5', fontWeight: 700 }}
        >
          {artist.artist_type === 'MAINARTIST' && artist.first_artist && (
            <>{t('organisms:firstArtist')}</>
          )}
          {artist.artist_type === 'MAINARTIST' && !artist.first_artist && (
            <>{t('organisms:mainArtistCaps')}</>
          )}
          {artist.artist_type === 'FEATUREDARTIST' && (
            <>{t('organisms:featuredArtist')}</>
          )}
          {artist.artist_type === 'CONTRIBUTOR' && (
            <>{t('organisms:contributor')}</>
          )}
        </TextRegular12>
        <TextBold16 className="text-bold" style={{ marginBottom: '5px' }}>
          {`${artist.artist_name} (${Number(artist.percentage)}% royalties)`}
        </TextBold16>
        <TextRegular14 style={{ marginBottom: '5px' }}>
          {artist.real_name}
        </TextRegular14>
        {artist.artist_role.length > 0 ? (
          <TextRegular14 className={isCorrect ? 'text-danger' : ''}>
            {newArtistRoleArray}
          </TextRegular14>
        ) : (
          t('organisms:artistRoleNotSet')
        )}
      </div>
      <CardRow className="d-flex mt-1">
        <div className="d-flex">
          <div className="d-flex justify-content-center align-items-center">
            <Image
              src="/static/images/Spotify.svg"
              width={24}
              height={24}
              alt="test"
              style={{
                maxWidth: '100%',
              }}
            />
          </div>
          <TextRegular14
            className={`d-flex justify-content-center align-items-center ${
              isCorrect ? 'text-danger' : ''
            }`}
          >
            {artist.spotify_artist_id ? (
              <TextRegular14>
                {t('organisms:connected')}{' '}
                <Checkmark size="1.5rem" color={`${style.lightGreen}`} />
              </TextRegular14>
            ) : (
              t('organisms:notConnected')
            )}
          </TextRegular14>
        </div>
        <div className="appleMusic d-flex">
          <div className="d-flex justify-content-center align-items-center">
            <Image
              src="/static/images/AppleSvg.svg"
              width={24}
              height={24}
              alt="test"
              className="object-fit-contain"
              style={{
                maxWidth: '100%',
              }}
            />
          </div>
          <TextRegular14
            className={`d-flex justify-content-center align-items-center ${
              isCorrect ? 'text-danger' : ''
            }`}
          >
            {artist.apple_artist_id ? (
              <TextRegular14>
                {t('organisms:connected')}{' '}
                <Checkmark size="1.5rem" color={`${style.lightGreen}`} />
              </TextRegular14>
            ) : (
              t('organisms:notConnected')
            )}
          </TextRegular14>
        </div>
      </CardRow>
    </div>
  );
}

export default MainFlowArtistCard;

const CardRow = styled.div`
  @media (max-width: 1400px) {
    flex-direction: column;
  }

  .appleMusic {
    margin-left: 1.5rem;
    @media (max-width: 1400px) {
      margin: 0;
    }
  }
`;
