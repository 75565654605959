import React, { useEffect, useState } from 'react';
import { Label, Input } from 'reactstrap';
import useTranslation from 'next-translate/useTranslation';
import { API, TrackArtist } from '../../api';
import CardBody from '../atoms/CardBody';
import Dialog from '../../components/common/Dialog';
import { Button } from '../atoms/_index';
import {
  TextBold24,
  TextBolder14,
  TextRegular12,
  TextRegular14,
} from '../atoms/Text';

interface MainFlowAddRequiredArtistDialogProps {
  onClose: (artist?: TrackArtist, artistType?: string) => void;
  isOpen: boolean;
  trackId: number;
  fetchArtistDetails: () => void;
  artistRole: string;
}

function MainFlowAddRequiredArtistDialog({
  trackId,
  onClose,
  isOpen,
  fetchArtistDetails,
  artistRole,
}: MainFlowAddRequiredArtistDialogProps) {
  const { t } = useTranslation('organisms');
  const initial: TrackArtist = {
    apple_artist_id: '',
    artist_name: '',
    artist_role: [],
    artist_type: 'CONTRIBUTOR',
    email: '',
    percentage: 0,
    real_name: '',
    user_id: 0,
    youtube_artist_id: '',
    first_artist: false,
  };

  const [artistInfoData, setArtistInfoData] = useState<TrackArtist>(initial);

  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');

  const handleRealNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArtistInfoData({ ...artistInfoData, real_name: e.target.value });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArtistInfoData({ ...artistInfoData, email: e.target.value });
  };

  useEffect(() => {
    setIsValid(artistInfoData.real_name !== '' && artistInfoData.email !== '');
  }, [artistInfoData]);

  const resetFields = () => {
    setArtistInfoData(initial);
  };

  const handleSave = async () => {
    if (!isValid) {
      return;
    }

    const response = await API.trackReleases.addArtist(
      {
        artist_name: '',
        real_name: artistInfoData.real_name,
        email: artistInfoData.email,
        artist_role: [artistRole],
        artist_type: artistInfoData.artist_type,
      },
      trackId,
    );

    if (response.success) {
      const { artists } = response.data.artist_details;

      fetchArtistDetails();
      resetFields();
      onClose(artists[artists.length - 1], artistRole);
    } else {
      setError(response.message);
    }
  };

  const handleClose = () => {
    resetFields();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className="px-2 pb-2" style={{ maxWidth: '35rem' }}>
        <CardBody className="text-center d-flex flex-column">
          <div className="mb-2">
            <TextBold24>
              {t('organisms:addNew')} {artistRole.toLocaleLowerCase()}
            </TextBold24>
            <div>
              {t('organisms:streamingServicesRequireToAdd')}{' '}
              {artistRole.toLocaleLowerCase()} {t('organisms:toYourRelease')}
            </div>
          </div>
          <div>
            <div className="mb-1">
              <Label className="text-left d-flex">
                <TextRegular14>{t('organisms:realName')}</TextRegular14>
              </Label>
              <div className="d-flex flex-column justify-content-center ">
                <Input
                  value={artistInfoData?.real_name}
                  type="text"
                  name="real_name_input"
                  id="real_name_input"
                  placeholder="Search Your artist Name here"
                  className="text-dark mr-1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleRealNameChange(e)
                  }
                />
                <TextRegular12
                  className="text-left text-muted"
                  style={{ paddingTop: '8px' }}
                >
                  {t('organisms:useYourLegalName')}
                </TextRegular12>
              </div>
            </div>
            <div className="mb-1">
              <Label className="text-left d-flex">
                <TextRegular14>{t('organisms:emailId')}</TextRegular14>
              </Label>
              <div className="d-flex flex-column justify-content-center">
                <Input
                  value={artistInfoData?.email}
                  type="text"
                  name="email"
                  id="email_input"
                  placeholder={t('organisms:email')}
                  className="text-dark mr-1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEmailChange(e)
                  }
                />
                <TextRegular12
                  className="text-left text-muted"
                  style={{ paddingTop: '8px' }}
                >
                  {t('organisms:useActiveEmailId')}
                </TextRegular12>
              </div>
            </div>
          </div>

          <TextBolder14 className="text-danger mt-1">
            {error !== '' && error}
          </TextBolder14>
        </CardBody>
        <Button
          color="primary"
          text={`${t(
            'organisms:saveAndAdd',
          )} ${artistRole.toLocaleLowerCase()}`}
          className="d-flex mx-auto pt-1"
          onClick={handleSave}
          disabled={!isValid}
        />
      </div>
    </Dialog>
  );
}

export default MainFlowAddRequiredArtistDialog;
