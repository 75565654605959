import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import {
  API,
  useCurrentUser,
  useSendGoogleAnalysticDataLayer,
} from '../../api';
import VuexyButton from '../atoms/Button';
import Container from '../atoms/Container';
import VuexyCustomInput from '../atoms/CustomInput';
import {
  TextBold18,
  TextBold36,
  TextRegular14,
  TextRegular18,
  TextBolder14,
} from '../atoms/Text';
import { Col, Row } from '../atoms/_index';
import { CenteredLoader } from '../../components/common/Loader';
import { useIsMobile } from '../../style/constants';
import * as userAPI from '../../api/lib/api/user';
import { getPlanNamesFromPlanTypes } from '../../utils/getPlanNamesFromPlanTypes';
import DistibutionCofirmationDialog from '../../components/Modals/DistibutionCofirmationDialog';
import GrowthPlanValidationForReleaseDialog from '../../components/Modals/GrowthPlanValidationForReleaseDialog';
import PaypalPaymentConfirmationWaitingDialog from '../../components/Modals/PaypalPaymentConfirmationWaitingDialog';

function WelcomePageForm({ onAccept }: WelcomePageFormProps) {
  const { t } = useTranslation('organisms');
  const user = useCurrentUser();
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [
    loaderOnPaypalPaymentConfirmation,
    setLoaderOnPaypalPaymentConfirmation,
  ] = useState(false);
  const [
    showGrowthPlanValidationModal,
    setShowGrowthPlanValidationModal,
  ] = useState(false);
  const [
    showPaypalPaymentWaitingConfirmationModal,
    setShowPaypalPaymentWaitingConfirmationModal,
  ] = useState(false);
  const [
    isDistributionConfirmationDialogOpen,
    setIsDistributionConfirmationDialogOpen,
  ] = useState(false);
  const [
    timerForSendingDataLayerPaypal,
    setTimerForSendingDataLayerPaypal,
  ] = useState(1);
  const router = useRouter();
  const isMobile = useIsMobile();
  const sendDataLayer = useSendGoogleAnalysticDataLayer();
  const isPaymentConfirmation = !!router.query['payment-confirmation'];

  const getPaymentConfirmationAndSendDataLayer = async () => {
    if (router.query.paypalPayment?.toString() === 'true') {
      userAPI.getCurrent(undefined, true).then((currentUser) => {
        if (currentUser?.subscription?.processing) {
          setTimerForSendingDataLayerPaypal(timerForSendingDataLayerPaypal + 1);
          return;
        }
        if (
          currentUser.abilities?.plan === 'amateur' &&
          !currentUser?.subscription?.processing
        ) {
          router.push('/become-pro');
        }

        if (
          currentUser.abilities?.plan !== 'amateur' &&
          !currentUser?.subscription?.processing
        ) {
          sendDataLayer.sendGoogleDatalayer(
            {
              event: 'artist_sub_pro',
              userId: currentUser.id,
              userType: getPlanNamesFromPlanTypes(currentUser.abilities?.plan),
              artistType: currentUser.producer_roles,
              value: currentUser.subscription?.amount,
              currency: currentUser.subscription?.currency,
            },
            {
              validateForFreeUser: false,
              paramsList: ['fire_ga', 'paypalPayment'],
            },
          );
        }
      });
    }
    user.refetch();
    setLoaderOnPaypalPaymentConfirmation(false);
  };

  const handleDistributionConfirmationDialogClose = () => {
    setIsDistributionConfirmationDialogOpen(false);
  };

  const handleDistributionConfirmationDialogOpen = () => {
    setIsDistributionConfirmationDialogOpen(true);
  };

  /* -------------------- send a google data layer when payment type is stripe -------------------- */
  useEffect(() => {
    if (
      user?.id &&
      isPaymentConfirmation &&
      user?.subscription?.payment_system !== 'paypal'
    ) {
      sendDataLayer.sendGoogleDatalayer(
        {
          event: 'artist_sub_pro',
          userId: user.id,
          userType: getPlanNamesFromPlanTypes(user.abilities?.plan),
          artistType: user.producer_roles,
          value: user.subscription?.amount,
          currency: user.subscription?.currency,
        },
        { validateForFreeUser: false, paramsList: ['fire_ga'] },
      );
    }
  }, [user?.id]);

  /* ----------- paypal payment taking some time for confirmation so we are using loader ---------- */
  useEffect(() => {
    if (router.query.paypalPayment?.toString() === 'true') {
      setLoaderOnPaypalPaymentConfirmation(true);
    }
  }, []);
  /* -------------- functionality that send a data layer when payment type is paypal -------------- */
  useEffect(() => {
    if (router.query.paypalPayment?.toString() === 'true') {
      // paypal confirmation takes time , so we are giving a time to paypal confirmation with setTime out
      setTimeout(() => {
        getPaymentConfirmationAndSendDataLayer();
      }, 3000);
    }
  }, [timerForSendingDataLayerPaypal]);

  /* ------------- validation for checking user is login or user is pro member or not ------------- */
  useEffect(() => {
    if (
      !user.requesting &&
      !isPaymentConfirmation &&
      (!user.id || user.abilities?.plan === 'amateur')
    ) {
      router.push('/');
    }
  }, [user]);

  const onSave = async () => {
    const responseuser = await user.refetch();
    if (responseuser.isSuccess) {
      const { data } = responseuser;
      if (
        !data?.subscription?.processing &&
        data?.abilities.plan !== 'amateur'
      ) {
        const payloadForTrackReleaseStart: {
          agree: boolean;
          track_id?: number;
        } = {
          agree: isChecked,
        };
        if (router.query.track_id) {
          payloadForTrackReleaseStart.track_id = parseInt(
            router.query.track_id.toString(),
            10,
          );
        }
        const response = await API.trackReleases.trackReleaseStartDistribution({
          ...payloadForTrackReleaseStart,
        });

        if (response.success) {
          setErrorMessage('');
          onAccept();
          router.push(`/distribution?track_id=${response.data.track_id}`);
        } else {
          if (
            response.message ===
            'Growing subscription is limited to release 1 song'
          ) {
            setShowGrowthPlanValidationModal(true);
          } else {
            setErrorMessage(response.message);
          }
          handleDistributionConfirmationDialogClose();
        }
      } else {
        setShowPaypalPaymentWaitingConfirmationModal(true);
        handleDistributionConfirmationDialogClose();
      }
    } else {
      handleDistributionConfirmationDialogClose();
    }
  };

  if (user.requesting || loaderOnPaypalPaymentConfirmation) {
    return <CenteredLoader />;
  }

  return (
    <>
      <Container>
        <Container
          className={`${
            isMobile ? 'w-100' : 'w-75'
          } d-flex flex-column align-items-center justify-content-center text-center mt-3`}
        >
          <TextBold36>{t('organisms:letsScheduleYourRelease')}</TextBold36>
          <TextRegular18 className="my-2">
            {t('organisms:submitYourTrack')}
          </TextRegular18>
          <TextBold18>{t('organisms:threeStepsAway')}</TextBold18>
        </Container>
        <Row className="w-75 d-flex flex-column flex-md-row justify-content-between mt-2 mb-4 m-auto">
          <Col xs={12} md={4} className="d-flex flex-column text-center">
            <Image
              alt="WLCM"
              src="/static/images/welcome/WelcomePage1.png"
              priority
              unoptimized
              height="233"
              width="233"
              className="object-fit-contain object-position-center"
              style={{
                maxWidth: '100%',
              }}
            />
            <TextBold18 className="mt-2 mb-1  ">
              {t('organisms:submitTheForm')}
            </TextBold18>
            <div className="w-75 m-auto">
              <TextRegular14>
                {t('organisms:enterAllTrackDetails')}
              </TextRegular14>
            </div>
          </Col>
          <Col xs={12} md={4} className="d-flex flex-column text-center">
            <Image
              alt="WLCM"
              src="/static/images/welcome/WelcomePage2.png"
              priority
              unoptimized
              height="233"
              width="233"
              className="object-fit-contain object-position-center"
              style={{
                maxWidth: '100%',
              }}
            />
            <TextBold18 className="mt-2 mb-1  ">
              {t('organisms:scheduleYourRelease')}
            </TextBold18>
            <div className="w-75 m-auto">
              <TextRegular14>{t('organisms:pickADate')}</TextRegular14>
            </div>
          </Col>
          <Col xs={12} md={4} className="d-flex flex-column text-center">
            <Image
              alt="WLCM"
              src="/static/images/welcome/WelcomePage3.png"
              priority
              unoptimized
              height="233"
              width="233"
              className="object-fit-contain object-position-center"
              style={{
                maxWidth: '100%',
              }}
            />
            <TextBold18 className="mt-2 mb-1  ">
              {t('organisms:launchYourRelease')}
            </TextBold18>
            <div className="w-75 m-auto">
              <TextRegular14>
                {t('organisms:weWillPromoteYourRelease')}
              </TextRegular14>
            </div>
          </Col>
        </Row>
        <Container
          className={`${
            isMobile ? 'w-100' : 'w-75'
          } d-flex flex-column align-items-center justify-content-center text-center mt-3`}
        >
          <div className="d-flex align-items-center justify-content-center">
            <VuexyCustomInput
              type="checkbox"
              className="custom-control-Primary font-weight-bold"
              id="Primary"
              inline
              onChange={() => setIsChecked(!isChecked)}
            />
            <TextRegular14>
              {t('organisms:iAgreeTo')}{' '}
              <a
                href="https://tracks.virpp.com/Terms-conditions-and-license-agreement-Virpp.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <TextBolder14
                  style={{ textDecoration: 'underline' }}
                  className="text-dark"
                  // onClick={() => setShowTAC(true)}
                >
                  {t('organisms:termsAndConditions')}
                </TextBolder14>
              </a>
            </TextRegular14>
          </div>
          <div className="w-75 text-danger font-weight-bold">
            {errorMessage}
          </div>
          {/* <div
        className="w-75 mt-1"
        style={{ color: '#666666', fontSize: '12px' }}
      >
        Facilisis dui consectetur viverra cursus duis viverra. Eget elementum,
        velit pellentesque tellus, eu in nibh. Pellentesque aliquam in platea
        vitae. Gravida pellentesque sed dis vitae. Quam pellentesque ut lorem
        turpis elit nullam amet, morbi.
      </div> */}
          <VuexyButton
            className="my-4 rounded"
            color="primary"
            onClick={handleDistributionConfirmationDialogOpen}
            disabled={!isChecked}
            style={{
              cursor: isChecked ? 'pointer' : 'default',
              borderRadius: '2px',
            }}
          >
            {t('organisms:proceed') as string}
          </VuexyButton>
        </Container>
      </Container>

      <DistibutionCofirmationDialog
        isOpen={isDistributionConfirmationDialogOpen}
        onClose={handleDistributionConfirmationDialogClose}
        onConfirm={onSave}
      />
      <GrowthPlanValidationForReleaseDialog
        isOpen={showGrowthPlanValidationModal}
        onClose={() => setShowGrowthPlanValidationModal(false)}
      />
      <PaypalPaymentConfirmationWaitingDialog
        isOpen={showPaypalPaymentWaitingConfirmationModal}
        onClose={() => setShowPaypalPaymentWaitingConfirmationModal(false)}
      />
    </>
  );
}

export default WelcomePageForm;

interface WelcomePageFormProps {
  onAccept: () => void;
}
