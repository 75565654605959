import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Track } from '../../../api';
import { Badge } from '../../../VuexyDesign/atoms/_index';

const TrackStatusBadgeDemos = ({ track }: { track: Track }) => {
  const { t } = useTranslation();
  const [trackStatus, setTrackStatus] = useState({
    title: '',
    color: '',
  });

  const tl = (translation: string) => t(translation) as string;
  useEffect(() => {
    if (track.status === 'inactive') {
      setTrackStatus({
        title: tl('myTracks:inactive'),
        color: 'light-danger',
      });
    } else if (track.status === 'active') {
      setTrackStatus({
        title: tl('myTracks:active'),
        color: 'light-success',
      });
    } else if (track.status === 'uploaded') {
      setTrackStatus({
        title: tl('myTracks:incomplete'),
        color: 'light-secondary',
      });
    }
  }, [track.status]);
  return (
    <Badge
      color={trackStatus.color}
      pill
      style={{
        marginLeft: '10px',
      }}
      text={trackStatus.title}
    />
  );
};

export default TrackStatusBadgeDemos;
