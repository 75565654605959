import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { Row as ReactRow } from 'reactstrap';
import styled from 'styled-components';
import {
  TextBold18NoSpacing,
  TextRegular14,
  TextRegular16,
} from '../atoms/Text';
import { Button, Col, Row } from '../atoms/_index';
import { noBigTablet, useIsDesktop, useIsMobile } from '../../style/constants';

interface DistributionLandingChooseUsProps {
  isLoggedIn: boolean;
  hasTracks: boolean;
  onRequestRelease?: () => void;
}

function DistributionLandingChooseUs({
  isLoggedIn,
  hasTracks,
  onRequestRelease,
}: DistributionLandingChooseUsProps) {
  const compareData = [
    {
      id: 1,
      featureName: 'organisms:noFeePerRelease',
      VIRPP: true,
      Tunecore: true,
      Distrokid: true,
    },
    {
      id: 2,
      featureName: 'organisms:keepYourRoyalties',
      VIRPP: true,
      Tunecore: true,
      Distrokid: true,
    },
    {
      id: 3,
      featureName: 'organisms:salesAndPerformanceData',
      VIRPP: true,
      Tunecore: true,
      Distrokid: false,
    },
    {
      id: 4,
      featureName: 'organisms:realTimeStatistics',
      VIRPP: true,
      Tunecore: false,
      Distrokid: false,
    },
    {
      id: 5,
      featureName: 'organisms:getFeedbackOnDemos',
      VIRPP: true,
      Tunecore: false,
      Distrokid: false,
    },
    {
      id: 6,
      featureName: 'organisms:joinContestsFromProfessionals',
      VIRPP: true,
      Tunecore: false,
      Distrokid: false,
    },
    {
      id: 7,
      featureName: 'organisms:recordLabelsLookingForMusic',
      VIRPP: true,
      Tunecore: false,
      Distrokid: false,
    },
    {
      id: 8,
      featureName: 'organisms:oneMarketingLink',
      VIRPP: true,
      Tunecore: false,
      Distrokid: false,
    },
    {
      id: 9,
      featureName: 'organisms:communitySupport',
      VIRPP: true,
      Tunecore: false,
      Distrokid: false,
    },
    {
      id: 10,
      featureName: 'organisms:customRecordLabelName',
      VIRPP: true,
      Tunecore: false,
      Distrokid: false,
    },
    {
      id: 11,
      featureName: 'organisms:customReleaseDate',
      VIRPP: true,
      Tunecore: false,
      Distrokid: false,
    },
    {
      id: 12,
      featureName: 'organisms:costPerAdditionalArtistProfile',
      VIRPP: '€0,-',
      Tunecore: '€14,99',
      Distrokid: '€9,99',
    },
  ];

  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const triggerDivRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('organisms');

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!triggerDivRef.current) {
        return;
      }

      const triggerPoint = triggerDivRef.current.offsetTop;
      const triggerPoint2 =
        triggerDivRef.current.offsetTop + triggerDivRef.current.offsetHeight;

      if (
        window.scrollY > triggerPoint &&
        window.scrollY < triggerPoint2 + 60
      ) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sticky]);

  return (
    <div
      className={`mb-5 d-flex flex-column justify-content-center mx-auto ${
        isMobile ? 'w-100' : 'w-75'
      }`}
    >
      <PlanTable ref={triggerDivRef}>
        <TopHeaderRow sticky={sticky}>
          <TopBarRow1>
            {isDesktop ? (
              <Col xs={0} xl={5} className="d-flex justify-center">
                <div className="d-flex flex-column">
                  <TextBold18NoSpacing className="mr-1 text-capitalize">
                    Features
                  </TextBold18NoSpacing>
                  <Subtitle>Get access to all our features</Subtitle>
                </div>
              </Col>
            ) : (
              <Col xs={0} xl={5} className="d-flex justify-center p-0" />
            )}
            <Col
              xs={4}
              xl={3}
              className="d-flex justify-content-center align-items-center position-sticky"
            >
              <img
                height={30}
                width="auto"
                src="/static/images/logo.png"
                alt="logo"
                style={{ maxWidth: '100%' }}
              />
            </Col>
            <Col
              xs={4}
              xl={2}
              className="d-flex justify-content-center align-items-center position-sticky"
            >
              <img
                height={30}
                width="auto"
                src="/static/images/distribution-landing/tunecore.svg"
                alt="logo"
                style={{ maxWidth: '100%' }}
              />
            </Col>
            <Col
              xs={4}
              xl={2}
              className="d-flex justify-content-center align-items-center position-sticky"
            >
              <img
                height={30}
                width="auto"
                src="/static/images/distribution-landing/distrokid.svg"
                alt="logo"
                style={{ maxWidth: '100%' }}
              />
            </Col>
          </TopBarRow1>
        </TopHeaderRow>
        {compareData.map((item, index) => (
          <Row
            className={`w-100 mx-auto p-1 ${index % 2 === 0 ? 'bg-light' : ''}`}
            key={`compare-data-${item.id}`}
          >
            <Col xs={12} xl={5} className={isMobile ? 'text-center mb-1' : ''}>
              <TextRegular16>{t(item.featureName)}</TextRegular16>
            </Col>
            <Col xs={4} xl={3} className="d-flex justify-content-center">
              {typeof item.VIRPP === 'string' ? (
                <TextRegular16>{item.VIRPP}</TextRegular16>
              ) : (
                <>
                  {item.VIRPP ? (
                    <StyledIcon>
                      <Image
                        alt="check-icon"
                        src="/static/images/VuexyCheckCircle.svg"
                        width={26}
                        height={26}
                        style={{
                          maxWidth: '100%',
                        }}
                      />
                    </StyledIcon>
                  ) : (
                    <StyledIcon>
                      <Image
                        alt="cross-icon"
                        src="/static/images/VuexyXCircle.svg"
                        width={26}
                        height={26}
                        style={{
                          maxWidth: '100%',
                        }}
                      />
                    </StyledIcon>
                  )}
                </>
              )}
            </Col>
            <Col xs={4} xl={2} className="d-flex justify-content-center">
              {typeof item.Tunecore === 'string' ? (
                <TextRegular16>{item.Tunecore}</TextRegular16>
              ) : (
                <>
                  {item.Tunecore ? (
                    <StyledIcon>
                      <Image
                        alt="check-icon"
                        src="/static/images/VuexyCheckCircle.svg"
                        width={26}
                        height={26}
                        style={{
                          maxWidth: '100%',
                        }}
                      />
                    </StyledIcon>
                  ) : (
                    <StyledIcon>
                      <Image
                        alt="cross-icon"
                        src="/static/images/VuexyXCircle.svg"
                        width={26}
                        height={26}
                        style={{
                          maxWidth: '100%',
                        }}
                      />
                    </StyledIcon>
                  )}
                </>
              )}
            </Col>
            <Col xs={4} xl={2} className="d-flex justify-content-center">
              {typeof item.Distrokid === 'string' ? (
                <TextRegular16>{item.Distrokid}</TextRegular16>
              ) : (
                <>
                  {item.Distrokid ? (
                    <StyledIcon>
                      <Image
                        alt="check-icon"
                        src="/static/images/VuexyCheckCircle.svg"
                        width={26}
                        height={26}
                        style={{
                          maxWidth: '100%',
                        }}
                      />
                    </StyledIcon>
                  ) : (
                    <StyledIcon>
                      <Image
                        alt="cross-icon"
                        src="/static/images/VuexyXCircle.svg"
                        width={26}
                        height={26}
                        style={{
                          maxWidth: '100%',
                        }}
                      />
                    </StyledIcon>
                  )}
                </>
              )}
            </Col>
          </Row>
        ))}
      </PlanTable>
      <div className="w-100 d-flex justify-content-center">
        {isLoggedIn && hasTracks ? (
          <Button
            color="primary"
            outline
            onClick={onRequestRelease}
            className="mt-1"
          >
            <TextRegular16>{t('organisms:requestARelease')}</TextRegular16>
          </Button>
        ) : (
          <div className="mt-2">
            <Link href="/upload?distribution=true">
              <Button color="primary" outline>
                <TextRegular16>{t('organisms:uploadNow')}</TextRegular16>
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default DistributionLandingChooseUs;

const Subtitle = styled(TextRegular14)`
  line-height: 25px;
  opacity: 0.5;
  text-transform: none !important;
  white-space: nowrap;
`;

const TopBarRow1 = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 1rem 1rem !important;
`;

const TopHeaderRow = styled(ReactRow)`
  display: flex;
  flex-direction: column;
  margin: 0 !important;

  position: ${(props) => (props.sticky ? 'fixed' : 'static')};
  width: ${(props) => (props.sticky ? '73.9%' : '100%')};

  /* left: 13.5%; */
  top: 5rem;

  background-color: white;
  z-index: 2;
  border: 1px solid #ebe9f1 !important;
  animation: moveToTop 0.5s ease-in-out;

  border-radius: 6px;

  @media ${noBigTablet} {
    width: ${(props) => (props.sticky ? '94%' : '100%')};
    left: ${(props) => (props.sticky ? '12px' : '0px')};
  }

  @keyframes moveToTop {
    from {
      top: 0rem;
    }
    to {
      top: 5rem;
    }
  }
`;

const PlanTable = styled.div`
  border-radius: 8px;
  border: 1px solid #ebe9f1 !important;
  margin: 0 !important;
  width: 100%;
  padding: 0;

  > div:last-child {
    border-radius: 0px 0px 6px 6px;
  }

  & > *:first-child {
    border-radius: 6px 6px 0px 0px;
  }

  @media ${noBigTablet} {
    border-radius: 0px;
    border-top: none !important;

    > div:last-child {
      border-radius: 0px;
    }

    > div:first-child {
      border-radius: 0px;
    }
  }
`;

const StyledIcon = styled.div`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
`;
