import React from 'react';
import { ArrowRight } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import Dialog from '../common/Dialog';
import { DialogContent } from '../common/MaterialDialog';
import {
  TextBold28,
  TextRegular14,
  TextRegular18,
} from '../../VuexyDesign/atoms/Text';
import { Button, Col, Row } from '../../VuexyDesign/atoms/_index';
import { useIsDesktop, useIsMobile } from '../../style/constants';

function PaypalPaymentConfirmationWaitingDialog({
  onClose,
  isOpen,
}: PaypalPaymentConfirmationWaitingDialogProps) {
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const { t } = useTranslation('pricing');

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        style={{
          width: isDesktop ? '49rem' : '',
          overflowY: 'auto',
        }}
        className="p-0 m-0"
      >
        <div className="w-100 mx-auto mb-3 d-flex flex-column justify-content-center align-items-center text-center">
          <div className="d-flex justify-content-center">
            <img
              src="/static/images/welcome/paypalPaymentConfitmationWaitingRelaxGirl.svg"
              height="290"
              width="260"
              alt="Payment confirmation waiting girl"
            />
          </div>
          <TextBold28 className={`${isMobile ? 'px-1' : 'px-3'}`}>
            {t('pricing:processingYourPayment') as string}{' '}
          </TextBold28>
          <TextRegular18 className={`mt-1 ${isMobile ? 'px-1' : 'px-3'}`}>
            {t('pricing:thankYouChoosingVirpp') as string}
          </TextRegular18>

          <Row
            className={`w-100 my-1 text-center ${isMobile ? 'px-1' : 'px-3'}`}
          >
            <Col xs={12} lg={12} className="d-flex ">
              <TextRegular14>
                {t('pricing:currentlyAwaitingPaymentConfirmation') as string}
              </TextRegular14>
            </Col>
            <Col xs={12} lg={12} className="d-flex mt-1">
              <TextRegular14>
                {t('pricing:appreciateYourPatienceUnderstanding') as string}
              </TextRegular14>
            </Col>
          </Row>
          <Button
            color="success"
            className="d-flex justify-content-between mt-1"
            onClick={onClose}
          >
            <>
              {t('pricing:okUnderstand') as string}
              <ArrowRight className="ml-1" />
            </>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default PaypalPaymentConfirmationWaitingDialog;

interface PaypalPaymentConfirmationWaitingDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
