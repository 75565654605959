import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

function Pills({
  tabs,
  active,
  style,
  className,
  tabStyle,
  tabClassName,
  setActive,
  shouldClearQueryParams,
}: TabsProps) {
  const router = useRouter();

  const [activeTab, setActiveTab] = useState(
    active?.toLocaleLowerCase() || tabs[0].label.toLowerCase(),
  );

  const handleClick = async (tab: TabProps) => {
    if (shouldClearQueryParams) {
      await router.replace(
        { pathname: router.pathname, query: undefined },
        undefined,
        {
          shallow: true,
        },
      );
    }

    setActiveTab(tab.label.toLowerCase());
    setActive(tab.label);
  };

  return (
    <div style={style} className={className}>
      <TabContainer
        className="d-flex flex-row text-nowrap overflow-scroll"
        style={{ gap: '8px' }}
      >
        {tabs.map((tab) => (
          <div
            key={`tab-${tab.label}`}
            className={`d-inline-flex justify-content-center align-items-center user-select-none font-weight-bold ${
              activeTab === tab.label.toLowerCase()
                ? 'bg-primary text-white'
                : ''
            } ${tabClassName || ''}`}
            style={{
              borderRadius: '4px',
              paddingTop: '0.5em',
              paddingBottom: '0.5em',
              paddingLeft: '15px',
              paddingRight: '15px',
              cursor: 'pointer',
              ...tabStyle,
            }}
            onClick={() => handleClick(tab)}
          >
            {tab.label}
          </div>
        ))}
      </TabContainer>

      {tabs.map((tab) =>
        tab.label.toLowerCase() === activeTab.toLowerCase() ? (
          <div key={`$tab-content-${tab.label}`} className="mt-2">
            {tab.content}
          </div>
        ) : undefined,
      )}
    </div>
  );
}

export default Pills;

interface TabsProps {
  tabs: TabProps[];
  active?: string;
  style?: React.CSSProperties;
  className?: string;
  tabStyle?: React.CSSProperties;
  tabClassName?: string;
  shouldClearQueryParams?: boolean;
  setActive: (active: string) => void;
}

interface TabProps {
  label: string;
  content: JSX.Element;
}

const TabContainer = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;
