import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, ErrorMessage } from 'formik';
import first from 'lodash/first';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import useTranslation from 'next-translate/useTranslation';
import isUrl from 'is-url';
import { SocialPlatform, SocialItem } from '../../api/lib/api/users';
import {
  API,
  logError,
  useSocialInfo,
  useCurrentUser,
  useQueryAddSocialInfo,
} from '../../api';
import { getErrorResponseMessage } from '../../api/redux/dispatch/alerts';
import { mobile } from '../../style/constants';
import SocialIcon from '../../components/common/SocialIcon';
import { TextRegular14 } from '../../components/common/Text';
import { ToastSuccess, ToastError } from '../../components/Toast';
import { TextBolder14, TextRegular12 } from '../atoms/Text';
import { Button, Dropdown, Input } from '../atoms/_index';
import { removeSocialInfo } from '../../api/lib/api/user';

interface SocialPlatformsProps {
  className?: string;
  border?: boolean;
  borderColor?: string;
  withLabel?: boolean;
}

const SocialPlatforms = ({
  className,
  border = true,
  borderColor = '#0000001a',
  withLabel = true,
}: SocialPlatformsProps) => {
  const { t } = useTranslation('settings');
  const user = useCurrentUser();
  const { data: socialInfoOriginal = [] } = useSocialInfo(user.id);

  const [showAddNewSocialForm, setShowAddNewSocialForm] = useState(false);
  const [newSocialPlatform, setNewSocialPlatform] = useState<
    SocialPlatform | undefined
  >(undefined);
  const [newSocialUsername, setNewSocialUsername] = useState('');
  const [newSocialUrl, setNewSocialUrl] = useState('');
  const [addNewSocialError, setAddNewSocialError] = useState<string>();
  const { mutateAsync: addSocialInfo } = useQueryAddSocialInfo();
  const [verifiedLink, setVerifiedLink] = useState(false);

  useEffect(() => {
    if (newSocialUrl) {
      setVerifiedLink(isUrl(newSocialUrl));
    }
  }, [newSocialUrl]);

  const platformOptions = [
    {
      label: 'Facebook',
      value: 'facebook',
    },
    {
      label: 'Instagram',
      value: 'instagram',
    },
    {
      label: 'Soundcloud',
      value: 'soundcloud',
    },
    {
      label: 'Spotify',
      value: 'spotify',
    },
    {
      label: 'Twitter',
      value: 'twitter',
    },
    {
      label: 'Youtube',
      value: 'youtube',
    },
    {
      label: t('settings:custom'),
      value: 'custom',
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...user,
        socialInfo: socialInfoOriginal,
      }}
      onSubmit={async ({ socialInfo }, { setSubmitting, setErrors }) => {
        // Handle the personal user info update
        let success = true;
        const formErrors: { [key: string]: unknown } = {};

        setSubmitting(true);

        // Handle the social media update
        const socialInfoToUpdate = socialInfo.filter(
          (item) => item.id !== undefined,
        );
        const socialInfoToAdd = socialInfo.filter(
          (item) => item.id === undefined,
        ) as Omit<SocialItem, 'id'>[];

        const socialInfoToRemove = socialInfoOriginal.filter(
          (socialInfoOriginalItem) =>
            !socialInfo
              .map((item) => item.id)
              .includes(socialInfoOriginalItem.id),
        );

        try {
          if (socialInfoToUpdate.length > 0) {
            await API.user.updateSocialInfo(socialInfoToUpdate);
          }
        } catch (err: any) {
          mapKeys(err.response.data.errors, (val: string, key: string) => {
            if (key) {
              const index = key.split('.')[1];
              const field = key.split('.')[2];
              formErrors[`${socialInfoToUpdate[Number(index)].type}`] = String(
                first(val),
              ).replace(key, field);
            }
            return val;
          });
          success = false;
          logError(err);
        }

        if (socialInfoToRemove.length) {
          await Promise.all(
            socialInfoToRemove.map((removeItem) =>
              API.user.removeSocialInfo(removeItem.id).catch((err) => {
                mapValues(err.response.data.errors, (val: string) => {
                  formErrors[`${removeItem.type}`] = val;
                  return val;
                });
                success = false;
                logError(err);
                Promise.resolve(true);
              }),
            ),
          );
        }

        if (socialInfoToAdd.length) {
          await Promise.all(
            socialInfoToAdd.map((addItem) =>
              API.user.addSocialInfo(addItem).catch((err) => {
                mapValues(err.response.data.errors, (val: string) => {
                  formErrors[`${addItem.type}`] = val;
                  return val;
                });
                success = false;
                logError(err);
                Promise.resolve(true);
              }),
            ),
          );
        }

        setErrors(formErrors);

        if (success) {
          ToastSuccess(t('profile:changesSaved') as string);
        } else {
          ToastError(t('profile:changesFailed') as string);
        }

        setSubmitting(false);
      }}
    >
      {({
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setSubmitting,
      }) => (
        <>
          <>
            <Section
              className={`w-100 d-flex flex-column ${className}`}
              style={
                border
                  ? { border: `1px solid ${borderColor}` }
                  : {
                      border: 'none',
                    }
              }
            >
              <form onSubmit={handleSubmit}>
                {withLabel && (
                  <TextRegular12>{t('socialNetworkConnections')}</TextRegular12>
                )}
                <div className="d-flex flex-column socials">
                  {values.socialInfo.map((item, index) => (
                    <div
                      className="w-100 justify-content-between d-flex align-items-center"
                      key={item?.id || `toAdd-${item.type}-${item.url}`}
                    >
                      <div>
                        <LinkToPlatform
                          href={item.url}
                          className="d-flex text-dark"
                          target="_blank"
                        >
                          <SocialIcon
                            item={item}
                            size={20}
                            greyoutOnMobile={false}
                          />
                          <TextBolder14 className="d-flex align-items-center ml-1">
                            {item.username}
                          </TextBolder14>
                        </LinkToPlatform>
                      </div>
                      <div>
                        <Button
                          color="flat-danger"
                          size="sm"
                          text={t('settings:remove') as string}
                          onClick={() => {
                            setFieldValue('socialInfo', [
                              ...values.socialInfo.slice(0, index),
                              ...values.socialInfo.slice(index + 1),
                            ]);
                            try {
                              removeSocialInfo(item.id);
                              ToastSuccess(t('profile:changesSaved') as string);
                            } catch (err) {
                              ToastError(t('profile:changesFailed') as string);
                            }
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name={`${item.type}`}
                        className="text-right"
                        component={ErrorLabel}
                      />
                    </div>
                  ))}
                </div>
                {showAddNewSocialForm ? (
                  <>
                    <SocialRow className="d-flex justify-content-between">
                      <FormInputWrapper className="d-flex">
                        <StyledDropdown
                          options={platformOptions}
                          onChange={(selected) => {
                            if (!selected || Array.isArray(selected)) return;
                            setNewSocialPlatform(
                              selected.value as SocialPlatform,
                            );
                            setNewSocialUsername(
                              selected.value as SocialPlatform,
                            );
                          }}
                          placeholder={t('profile:platform') as string}
                        />
                        <SocialUsernameInput
                          placeholder={t('profile:username') as string}
                          name="add-social-name"
                          type="text"
                          className="text-dark"
                          value={newSocialUsername}
                          onChange={(e) => setNewSocialUsername(e.target.value)}
                        />

                        <SocialUrlInput
                          name="add-social-url"
                          type="text"
                          value={newSocialUrl}
                          placeholder={t('profile:url') as string}
                          className="text-dark"
                          onChange={(e) => setNewSocialUrl(e.target.value)}
                        />
                      </FormInputWrapper>
                      <Button
                        type="submit"
                        disabled={
                          !!isSubmitting ||
                          !newSocialPlatform ||
                          !newSocialUsername ||
                          !verifiedLink
                        }
                        color="primary"
                        onClick={async () => {
                          if (
                            !newSocialPlatform ||
                            !newSocialUsername ||
                            !newSocialUrl
                          ) {
                            setAddNewSocialError(
                              t('profile:allFieldsAreRequired') as string,
                            );
                          } else if (newSocialPlatform) {
                            try {
                              setSubmitting(true);
                              addSocialInfo({
                                type: newSocialPlatform,
                                url: newSocialUrl,
                                username: newSocialUsername,
                              });
                              setShowAddNewSocialForm(false);
                              setAddNewSocialError(undefined);
                            } catch (err) {
                              setAddNewSocialError(
                                getErrorResponseMessage(err),
                              );
                            } finally {
                              setSubmitting(false);
                            }
                          }
                        }}
                        text={t('profile:add') as string}
                      />
                    </SocialRow>
                    {addNewSocialError && (
                      <ErrorLabel>{addNewSocialError}</ErrorLabel>
                    )}
                  </>
                ) : (
                  <div>
                    <div>
                      <Button color="flat-light">
                        <TextRegular14
                          className="align-bottom"
                          onClick={() => {
                            setNewSocialPlatform(undefined);
                            setNewSocialUsername('');
                            setNewSocialUrl('http://');
                            setShowAddNewSocialForm(true);
                          }}
                        >
                          + {t('profile:addSocialAccount') as string}
                        </TextRegular14>
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            </Section>
          </>
        </>
      )}
    </Formik>
  );
};

export default SocialPlatforms;

const LinkToPlatform = styled.a`
  ${TextBolder14}:hover {
    text-decoration: underline;
  }
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 145px;
  margin-top: 20px;

  @media ${mobile} {
    min-width: 100%;
  }
`;

const SocialUrlInput = styled(Input)`
  width: 45%;
  @media ${mobile} {
    width: 100%;
  }
`;

const SocialUsernameInput = styled(Input)`
  width: 25%;
  margin: 0 10px;
  @media ${mobile} {
    width: 100%;
    margin: 0 0 20px 0;
  }
`;

const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const ErrorLabel = styled.div`
  margin-top: 5px;
  color: #f00;
`;

const Section = styled.div`
  padding: 16px;
  border-radius: 4px;
  gap: 8px;
`;
