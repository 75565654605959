import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { IncorrectArtist, IncorrectDetails, TrackArtist } from '../../api';
import { TextBolder14, TextRegular14 } from '../atoms/Text';

function MainFlowIncorrectDetails({
  incorrectDetails,
  step,
  allArtists,
}: MainFlowIncorrectDetailsProps) {
  const { t } = useTranslation('artist');
  const [
    incorrectDetailsMessage,
    setIncorrectDetailsMessage,
  ] = useState<string>();

  useEffect(() => {
    setIncorrectDetailsMessage(getIncorrectProperties());
  }, [incorrectDetails]);

  const getIncorrectPropertiesForArtist = (artist: IncorrectArtist) => {
    if (!allArtists) {
      return '';
    }
    const trackArtist = allArtists.find((a) => a.id === artist.id);

    if (!trackArtist) {
      return '';
    }

    const fields = Object.entries(artist)
      .map(([key, value]) => {
        if (key === 'id') {
          return '';
        }
        return value === false
          ? key
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          : '';
      })
      .reduce((prev, curr) => {
        if (prev === '') {
          return curr;
        }
        return curr !== '' ? `${prev}, ${curr}` : prev;
      }, '');

    return fields.length > 0
      ? ` Artist: ${
          trackArtist.artist_name || trackArtist.real_name
        } (${fields})`
      : '';
  };

  const getIncorrectProperties = () => {
    if (incorrectDetails[step] === undefined) {
      return '';
    }
    const res = Object.entries(incorrectDetails[step] || {})
      .map(([key, value]) => {
        if (Array.isArray(value) && allArtists) {
          return value
            .map((a) => getIncorrectPropertiesForArtist(a))
            .filter((val) => val !== '');
        }
        return value === false
          ? key
              .split('_')
              .filter((word) => word !== 'id')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          : '';
      })
      .reduce((prev, curr) => {
        if (prev === '') {
          return curr;
        }
        return curr !== '' ? `${prev}, ${curr}` : prev;
      }, '')
      .toString();

    return res;
  };

  if (incorrectDetailsMessage === '') {
    return <></>;
  }

  return (
    <div
      style={{ backgroundColor: '#FCE8E4' }}
      className="d-flex flex-column justify-content-between p-1 mt-1"
    >
      <TextBolder14 className="text-danger">
        {t('artist:incorrectDetailsOn')} {incorrectDetailsMessage}
      </TextBolder14>

      <TextRegular14 className="text-danger mt-2">
        {incorrectDetails.admin_message}
      </TextRegular14>
    </div>
  );
}

interface MainFlowIncorrectDetailsProps {
  incorrectDetails: IncorrectDetails;
  step: keyof IncorrectDetails;
  allArtists?: TrackArtist[];
}

export default MainFlowIncorrectDetails;
