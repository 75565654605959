import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import Rating from '@mui/material/Rating';
import { useIsMobile } from '../../style/constants';

function DistributionLandingTrustedItem({
  imgUrl,
  name,
  description,
  job,
  className,
  onLoginPage,
}: TrustedProps) {
  const { t } = useTranslation('organisms');
  const isMobile = useIsMobile();

  return (
    <div
      className={className}
      style={{
        height: '261px',
        maxHeight: '261px',
        maxWidth: '429px',
        minWidth: '300px',
      }}
    >
      <div
        className={`d-flex flex-column border-light p-1 br-10 text-left ${
          isMobile ? 'mx-1' : ''
        } 
        ${onLoginPage ? 'h-100 justify-content-between' : ''}
        `}
        style={{
          background: '#FFFFFF',
        }}
      >
        <div className="d-flex flex-column">
          <Rating
            name="half-rating-read"
            defaultValue={5}
            precision={0.1}
            readOnly
          />
          <span className="custom-font-text-small-normal my-1 w-100">
            {t(description) as string}
          </span>
        </div>
        <div className="d-flex">
          <UserImage alt="trusted-user" src={imgUrl} width={40} height={40} />
          <div className="d-flex flex-column ml-1">
            <span className="custom-font-text-small-medium">{name}</span>
            <span className="custom-font-text-small-normal">
              {t(job) as string}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const UserImage = styled(Image)<{ size?: number; border?: boolean }>`
  border-radius: 50%;
  border: ${(p) => (p.border ? '3px solid white' : 'none')};
`;

interface TrustedProps {
  imgUrl: string;
  name: string;
  description: string;
  job: string;
  className?: string;
  onLoginPage?: boolean;
}

export default DistributionLandingTrustedItem;
