import React from 'react';
import Slider from 'react-slick';
import { ArrowRight } from 'react-feather';
import Link from 'next/link';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { mobile, noDesktop, useIsMobile } from '../../style/constants';
import { Button, Col, Row } from '../atoms/_index';
import { DistributionLandingTrustedItem } from '../molecules/_index';
import { TrustedArtist } from '../templates/DistributionLandingLayout';
import { useIsLoggedIn } from '../../api';

interface DistributionLandingTrustedProps {
  title: string;
  subtitle?: string;
  data: TrustedArtist[];
}

function DistributionLandingTrusted({
  title,
  subtitle,
  data,
}: DistributionLandingTrustedProps) {
  const isMobile = useIsMobile();
  const { t } = useTranslation('home');
  const userLoggedIn = useIsLoggedIn();
  return (
    <Container className="mx-auto py-5 text-center">
      <TestemonialsTextContainer>
        <h2 className="custom-heading-font-2 ">{title}</h2>
      </TestemonialsTextContainer>
      <br />
      {!isMobile && subtitle ? (
        <div className="d-flex justify-content-center">
          <div className="w-75">
            <span className="custom-font-text-medium-normal">{subtitle}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isMobile ? (
        <Slider {...settings} className="w-100 d-block mx-auto">
          {data.map((item) => (
            <DistributionLandingTrustedItem
              imgUrl={item.imgUrl}
              name={item.name}
              description={item.description}
              job={item.job}
              key={`trusted-item-${item.id}`}
            />
          ))}
        </Slider>
      ) : (
        <Row className="mt-2 text-left mx-auto">
          {data.map((item) => (
            <Col md={4} className="mb-2" key={`trusted-label-${item.name}`}>
              <DistributionLandingTrustedItem
                imgUrl={item.imgUrl}
                name={item.name}
                description={item.description}
                job={item.job}
                key={`trusted-item-${item.id}`}
              />
            </Col>
          ))}
        </Row>
      )}
      <div
        className="w-75 d-flex justify-content-center mt-1 mx-auto my-0"
        style={{ marginBottom: '100px' }}
      >
        <div className="mt-2">
          {userLoggedIn ? (
            <Link passHref href="/upload?distribution=true">
              <div>
                <Button color="primary" text={t('home:getStarted') as string}>
                  <ArrowRight />
                </Button>
              </div>
            </Link>
          ) : (
            <Link passHref href="/login?redirect=/music-distribution">
              <div>
                <Button color="primary" text={t('home:getStarted') as string}>
                  <ArrowRight />
                </Button>
              </div>
            </Link>
          )}
        </div>
      </div>
    </Container>
  );
}

export default DistributionLandingTrusted;

const settings = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  // speed: 8000,
  pauseOnHover: false,
  accessibility: false,
};

const Container = styled.div`
  @media ${noDesktop} {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const TestemonialsTextContainer = styled.div`
  @media ${mobile} {
    padding: 0 1rem;
  }
`;
