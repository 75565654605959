import React, { useEffect, useState } from 'react';
import { Label, Input } from 'reactstrap';
import useTranslation from 'next-translate/useTranslation';
import { API, ArtistRole, TrackArtist } from '../../api';
import CardBody from '../atoms/CardBody';
import Dialog from '../../components/common/Dialog';
import { Button, CustomInput, Dropdown } from '../atoms/_index';
import {
  TextBold24,
  TextBolder14,
  TextRegular12,
  TextRegular14,
} from '../atoms/Text';

interface MainFlowAddArtistDialogProps {
  artist?: TrackArtist;
  onClose: (artist?: TrackArtist, artistType?: string) => void;
  isOpen: boolean;
  trackId: number;
  fetchArtistDetails: () => void;
  allArtists: TrackArtist[];
}

function MainFlowAddArtistDialog({
  artist,
  trackId,
  onClose,
  isOpen,
  fetchArtistDetails,
  allArtists,
}: MainFlowAddArtistDialogProps) {
  const { t } = useTranslation('organisms');
  const initial: TrackArtist = {
    apple_artist_id: '',
    artist_name: '',
    artist_role: [],
    artist_type: 'CONTRIBUTOR',
    email: '',
    percentage: 0,
    real_name: '',
    user_id: 0,
    youtube_artist_id: '',
    first_artist: false,
  };

  const [artistInfoData, setArtistInfoData] = useState<TrackArtist>(
    artist || initial,
  );

  const [editArtist, setEditArtist] = useState(artist);

  const [isNewArtist, setIsNewArtist] = useState(true);
  const [selectedArtistsRole, setSelectedArtistRole] = useState<string[]>(
    artist?.artist_role || [],
  );

  const [selectedOption, setSelectedOption] = useState<Option>();

  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);

  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');

  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);

  const handleRealNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArtistInfoData({ ...artistInfoData, real_name: e.target.value });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArtistInfoData({ ...artistInfoData, email: e.target.value });
  };

  const allArtistsRoleArray: ArtistRole[] = [
    {
      id: 1,
      name: 'Arranger',
    },
    {
      id: 2,
      name: 'Choir',
    },
    {
      id: 3,
      name: 'Conductor',
    },
    {
      id: 4,
      name: 'Engineer',
    },
    {
      id: 5,
      name: 'Contributing Artist',
    },
    {
      id: 6,
      name: 'Ensemble',
    },
    {
      id: 7,
      name: 'Mixer',
    },
    {
      id: 8,
      name: 'Orchestra',
    },
    {
      id: 11,
      name: 'Writer',
    },
    {
      id: 12,
      name: 'Producer',
    },
  ];

  useEffect(() => {
    setError('');

    // if (artist) {
    //   setArtistInfoData(artist);
    //   setSelectedOption({ label: artist.real_name, value: artist.id || 0 });
    //   setSelectedArtistRole(artist.artist_role);
    //   return;
    // }

    const otherArtistOption: Option = { label: 'Other', value: -1 };
    const artistOptions: Option[] = allArtists
      .filter((item) => item.artist_type !== 'CONTRIBUTOR')
      .map((item) => ({
        label: item.artist_name || item.real_name,
        value: item.id || 0,
      }));

    setSelectedOption(otherArtistOption);
    setSelectedArtistRole([]);
    setDropdownOptions([otherArtistOption, ...artistOptions]);
  }, [allArtists]);

  useEffect(() => {
    setError('');

    setSelectedOption({
      label:
        artistInfoData.artist_name === '' || artistInfoData.real_name === ''
          ? 'Other'
          : artistInfoData.artist_name || artistInfoData.real_name,
      value: artistInfoData.id || -1,
    });
    validate();
  }, [artistInfoData]);

  const validate = () => {
    setIsValid(
      artistInfoData.real_name !== '' &&
        artistInfoData.email !== '' &&
        selectedArtistsRole.length > 0,
    );
  };

  useEffect(() => {
    validate();
  }, [selectedArtistsRole]);

  useEffect(() => {
    setError('');
    if (artist) {
      setSelectedOption({ label: artist.real_name, value: artist.id || 0 });
      setSelectedArtistRole(artist.artist_role);
      return;
    }

    const otherArtistOption: Option = { label: 'Other', value: -1 };
    const artistOptions: Option[] = allArtists
      .filter((item) => item.artist_type !== 'CONTRIBUTOR')
      .map((item) => ({
        label: item.artist_name,
        value: item.id || 0,
      }));

    setSelectedOption(otherArtistOption);
    setSelectedArtistRole([]);
    setDropdownOptions([otherArtistOption, ...artistOptions]);
  }, []);

  useEffect(() => {
    setEditArtist(artist);
  }, [artist]);

  useEffect(() => {
    if (editArtist) {
      setArtistInfoData(editArtist);
      setSelectedArtistRole(editArtist.artist_role);
      setSelectedOption({
        label: editArtist.real_name,
        value: editArtist.id || 0,
      });
    }
  }, [editArtist]);

  const resetFields = () => {
    const artistOptions: Option[] = allArtists.map((item) => ({
      label: item.artist_name,
      value: item.id || 0,
    }));

    const otherArtistOption: Option = { label: 'Other', value: -1 };
    setArtistInfoData(initial);
    setSelectedOption(otherArtistOption);
    setSelectedArtistRole([]);
    setDropdownOptions([otherArtistOption, ...artistOptions]);
  };

  const handleSave = async () => {
    if (!isValid || isAwaitingResponse) {
      return;
    }

    setIsAwaitingResponse(true);

    if (isNewArtist && !editArtist) {
      const response = await API.trackReleases.addArtist(
        {
          artist_name: '',
          real_name: artistInfoData.real_name,
          email: artistInfoData.email,
          artist_role: selectedArtistsRole,
          artist_type: artistInfoData.artist_type,
        },
        trackId,
      );

      setIsAwaitingResponse(false);

      if (response.success) {
        fetchArtistDetails();
        onClose();
        resetFields();
      } else {
        setError(response.message);
      }
    } else {
      const response = await API.trackReleases.updateArtist(
        {
          ...artistInfoData,
          artist_role: selectedArtistsRole,
        },
        trackId,
      );

      setIsAwaitingResponse(false);

      if (response.success) {
        fetchArtistDetails();
        onClose();
        resetFields();
      } else {
        setError(response.message);
      }
    }
  };

  const toggleCheckbox = (role: string) => {
    if (selectedArtistsRole?.includes(role)) {
      setSelectedArtistRole(
        selectedArtistsRole.filter((artistRole) => artistRole !== role),
      );
    } else {
      const newSelectedArtistRole = [...selectedArtistsRole];
      newSelectedArtistRole.push(role);
      setSelectedArtistRole(newSelectedArtistRole);
    }
  };

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const handleArtistChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    if (!selected || Array.isArray(selected)) {
      return;
    }

    if (selected.value === -1) {
      setIsNewArtist(true);
      setSelectedArtistRole([]);
      setArtistInfoData(initial);
      return;
    }

    const selectedArtist = allArtists.find(
      (item) => item.id === selected.value,
    );

    if (!selectedArtist) {
      return;
    }

    setIsNewArtist(false);

    setArtistInfoData(selectedArtist);
    setSelectedArtistRole(selectedArtist.artist_role || []);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className="px-2 pb-2" style={{ maxWidth: '35rem' }}>
        <CardBody className="text-center d-flex flex-column">
          <div className="mb-2">
            <TextBold24>{t('organisms:addOtherContributor')}</TextBold24>
            <div>{t('organisms:areThereOtherPeople')}</div>
          </div>
          <div style={{ zIndex: 1000 }}>
            <div className="mb-1">
              <Label className="text-left d-flex">
                <TextRegular14>
                  {t('organisms:selectContributor')}
                </TextRegular14>
              </Label>
              <div
                className="d-flex flex-column justify-content-center"
                style={{ zIndex: 1000 }}
              >
                <Dropdown
                  options={dropdownOptions}
                  onChange={handleArtistChange}
                  value={selectedOption}
                  isDisabled={editArtist !== undefined}
                />
              </div>
            </div>
            {(isNewArtist || editArtist) && (
              <>
                <div className="mb-1">
                  <Label className="text-left d-flex">
                    <TextRegular14>{t('organisms:realName')}</TextRegular14>
                  </Label>
                  <div className="d-flex flex-column justify-content-center ">
                    <Input
                      value={artistInfoData?.real_name}
                      type="text"
                      name="real_name_input"
                      id="real_name_input"
                      placeholder={t('organisms:searchArtistName')}
                      className="text-dark mr-1"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleRealNameChange(e)
                      }
                    />
                    <TextRegular12
                      className="text-left text-muted"
                      style={{ paddingTop: '8px' }}
                    >
                      {t('organisms:useYourLegalName')}
                    </TextRegular12>
                  </div>
                </div>
                <div className="mb-1">
                  <Label className="text-left d-flex">
                    <TextRegular14>{t('organisms:emailId')}</TextRegular14>
                  </Label>
                  <div className="d-flex flex-column justify-content-center">
                    <Input
                      value={artistInfoData?.email}
                      type="text"
                      name="email"
                      id="email_input"
                      placeholder={t('organisms:email')}
                      className="text-dark mr-1"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleEmailChange(e)
                      }
                    />
                    <TextRegular12
                      className="text-left text-muted"
                      style={{ paddingTop: '8px' }}
                    >
                      {t('organisms:useActiveEmailId')}
                    </TextRegular12>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="d-flex flex-column my-1" style={{ zIndex: 1 }}>
            <TextRegular14 className="text-left">
              {t('organisms:contributorRole')}
            </TextRegular14>
            <div className="d-flex w-100 flex-wrap">
              {allArtistsRoleArray.map((artistRole: ArtistRole) => (
                <div className="left-text mt-1 d-flex" key={artistRole.id}>
                  <CustomInput
                    type="checkbox"
                    className="custom-control-Primary font-weight-bold"
                    id={artistRole.id}
                    label={artistRole.name}
                    inline
                    checked={selectedArtistsRole?.includes(artistRole.name)}
                    onChange={() => toggleCheckbox(artistRole.name)}
                  />
                </div>
              ))}
            </div>
          </div>
          <TextBolder14 className="text-danger mt-1">
            {error !== '' && error}
          </TextBolder14>
        </CardBody>
        <Button
          color="primary"
          text={t('organisms:saveAndAddContributor') as string}
          className="d-flex mx-auto pt-1"
          onClick={handleSave}
          disabled={!isValid}
        />
      </div>
    </Dialog>
  );
}

export default MainFlowAddArtistDialog;

interface Option {
  value: string | number;
  label: string;
}
