import React from 'react';
import { ThumbsUp, ThumbsDown } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import Button from '../atoms/Button';
import CardText from '../atoms/CardText';

function DefaultDistributionSection({
  onReleaseAction,
}: {
  onReleaseAction: (action: string) => void;
}) {
  const { t } = useTranslation('artist');

  const handleAccept = () => {
    onReleaseAction('accept');
  };

  const handleDecline = () => {
    onReleaseAction('decline');
  };

  return (
    <>
      <CardText className="mt-1 text-center">
        {t('artist:trackWasSelected') as string}
      </CardText>
      <Button
        style={{
          width: '200px',
        }}
        color="gradient-pro"
        className="px-2 d-flex align-items-center justify-content-around mb-1 br-5"
        onClick={handleAccept}
      >
        <span>
          <ThumbsUp size={18} />
          <span style={{ marginLeft: '5px' }}>{t('artist:acceptRelease')}</span>
        </span>
      </Button>
      <Button
        color="flat-danger"
        style={{
          width: '200px',
        }}
        onClick={handleDecline}
        className="br-5"
      >
        <ThumbsDown size={18} />
        <>
          <span style={{ marginLeft: '5px' }}>{t('artist:notInterested')}</span>
        </>
      </Button>
    </>
  );
}

export default DefaultDistributionSection;
