import React, { useEffect, useState } from 'react';
import { Label, Input } from 'reactstrap';
import useTranslation from 'next-translate/useTranslation';
import {
  API,
  TrackArtist,
  ArtistRole,
  SpotifyArtist,
  ArtistPercentage,
  useDebouncedCallback,
  useTrack,
  style,
  useCurrentUser,
} from '../../api';
import CardBody from '../atoms/CardBody';
import Dialog from '../../components/common/Dialog';
import { Button, Col, CustomInput, Row } from '../atoms/_index';
import {
  TextBold12,
  TextBold14,
  TextBold24,
  TextBolder12,
  TextBolder14,
  TextRegular12,
  TextRegular14,
  TextRegular16,
} from '../atoms/Text';
import { useIsMobile } from '../../style/constants';

interface MainFlowArtistDialogProps {
  artist?: TrackArtist;
  spotifyArtist?: SpotifyArtist;
  handleCloseArtistPage: (closeSearchDialog?: boolean) => void;
  isMainFlowArtistDialogOpen: boolean;
  trackId: number;
  isNewArtist?: boolean;
  fetchArtistDetails: () => void;
  isFirstAdded?: boolean;
  allArtists?: TrackArtist[];
}

function MainFlowArtistDialog({
  trackId,
  artist,
  spotifyArtist,
  handleCloseArtistPage,
  isMainFlowArtistDialogOpen,
  isNewArtist,
  fetchArtistDetails,
  isFirstAdded,
  allArtists,
}: MainFlowArtistDialogProps) {
  const { t } = useTranslation('organisms');
  const initial: TrackArtist = {
    apple_artist_id: '',
    artist_name: spotifyArtist?.name || '',
    artist_role: [],
    artist_type: 'MAINARTIST',
    email: '',
    percentage: isFirstAdded ? 100 : 0,
    real_name: '',
    spotify_artist_id: spotifyArtist?.id || artist?.spotify_artist_id || '',
    user_id: 0,
    youtube_artist_id: '',
    first_artist: false,
  };

  const currentUser = useCurrentUser();

  const [artistInfoData, setArtistInfoData] = useState<TrackArtist>(initial);
  const [selectedArtistsRole, setSelectedArtistRole] = useState<string[]>(
    artist?.artist_role || [],
  );

  const [songName, setSongName] = useState('');
  const [track] = useTrack(trackId);
  const ismobile = useIsMobile();

  const [isFirstArtist, setIsFirstArtist] = useState(isFirstAdded || false);
  const [artistPercentages, setArtistPercentages] = useState<
    ArtistPercentage[]
  >([]);

  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);

  const debouncedPercentages = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>, artistId?: number) =>
      handlePercentageChange(e, artistId),
    1000,
  );

  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (
      isFirstAdded &&
      !currentUser.requesting &&
      artistInfoData.email !== currentUser.email
    ) {
      setArtistInfoData((prev) => ({
        ...prev,
        email: currentUser.email || '',
      }));
    }
  }, [currentUser]);

  useEffect(() => {
    if (artist !== undefined) {
      setArtistInfoData(artist);
      setIsFirstArtist(artist.first_artist || false);
    }
    if (allArtists) {
      setArtistPercentages(
        allArtists.map((a) => ({
          artist_id: a.id || -1,
          percentage: a.percentage || 0,
        })),
      );
    }

    API.trackReleases.getTrackRelease(trackId).then((resp) => {
      if (resp.success) {
        const releaseTitle: string = resp.data.track_details.release_title;
        const title: string =
          releaseTitle && releaseTitle !== ''
            ? resp.data.track_details.release_title
            : track?.title;
        setSongName(title);
      } else {
        setSongName('SONG_NAME');
      }
    });
  }, []);

  const allArtistsRoleArray: ArtistRole[] = [
    {
      id: 2,
      name: 'Arranger',
    },
    {
      id: 3,
      name: 'Choir',
    },
    {
      id: 4,
      name: 'Conductor',
    },
    {
      id: 5,
      name: 'Engineer',
    },
    {
      id: 7,
      name: 'Ensemble',
    },
    {
      id: 8,
      name: 'Mixer',
    },
    {
      id: 9,
      name: 'Orchestra',
    },
    {
      id: 11,
      name: 'Writer',
    },
    {
      id: 12,
      name: 'Producer',
    },
  ];

  useEffect(() => {
    if (spotifyArtist !== undefined) {
      setArtistInfoData({
        ...artistInfoData,
        artist_name: spotifyArtist.name,
        spotify_artist_id: spotifyArtist.id,
      });
    }
  }, [spotifyArtist]);

  const handleFirstArtistChange = () => {
    setIsFirstArtist((prev) => !prev);
  };

  const getSongNamePreview = () => {
    const mainArists =
      allArtists?.filter(
        (a) =>
          (a.id !== artistInfoData.id && a.artist_type === 'MAINARTIST') ||
          a.first_artist,
      ) || [];
    const featuredArtists =
      allArtists?.filter(
        (a) => a.id !== artistInfoData.id && a.artist_type === 'FEATUREDARTIST',
      ) || [];

    if (
      artistInfoData.artist_type === 'MAINARTIST' &&
      artistInfoData.artist_name !== '' &&
      mainArists.every((a) => a.id !== artistInfoData.id)
    ) {
      mainArists.push(artistInfoData);
    } else if (artistInfoData.artist_type === 'FEATUREDARTIST') {
      featuredArtists.push(artistInfoData);
    }

    const preview = `${mainArists
      ?.map((a) => a.artist_name)
      .join(', ')} - ${songName} ${
      featuredArtists?.length !== 0 ? 'feat. ' : ''
    } ${featuredArtists?.map((a) => a.artist_name).join(', ')}`;

    return preview;
  };

  const toggleCheckbox = (role: string) => {
    if (selectedArtistsRole?.includes(role)) {
      setSelectedArtistRole(
        selectedArtistsRole.filter((artistRole) => artistRole !== role),
      );
    } else {
      const newSelectedArtistRole = [...selectedArtistsRole];
      newSelectedArtistRole.push(role);
      setSelectedArtistRole(newSelectedArtistRole);
    }
  };

  const handleArtistNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArtistInfoData({ ...artistInfoData, artist_name: e.target.value });
  };

  const handleRealNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArtistInfoData({ ...artistInfoData, real_name: e.target.value });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArtistInfoData({ ...artistInfoData, email: e.target.value });
  };

  const handleArtistTypeChange = (newType: string) => {
    setArtistInfoData({ ...artistInfoData, artist_type: newType });
  };

  const handlePercentageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    artistId?: number,
  ) => {
    const newPercentage = roundToTwoDecimalPlaces(Number(e.target.value) || 0);
    const oldPercentage = roundToTwoDecimalPlaces(
      Number(artistInfoData.percentage) || 0,
    );

    if (!artistId) {
      calcuatePercentages(oldPercentage, newPercentage);
      setArtistInfoData({ ...artistInfoData, percentage: newPercentage });
    } else {
      const temp = [...artistPercentages];
      temp.find(
        (artistPercentage) => artistPercentage.artist_id === artistId,
      )!.percentage = newPercentage;

      setArtistPercentages([...temp]);
    }
  };

  const calcuatePercentages = (
    oldPercentage: number,
    newPercentage: number,
  ) => {
    const newArtistPercentages: ArtistPercentage[] = artistPercentages.map(
      (artistPercentage) => {
        const id = artistPercentage.artist_id;
        const changeValue =
          (oldPercentage - newPercentage) / artistPercentages.length;

        const value = Number(artistPercentage.percentage) + changeValue;

        let percentage = value > 0 ? roundToTwoDecimalPlaces(value) : 0;
        percentage = percentage > 100 ? 100 : percentage;

        return { artist_id: id, percentage };
      },
    );

    newArtistPercentages.forEach((artistPercentage) => {
      const input = document.getElementById(
        `input-${artistPercentage.artist_id}`,
      ) as HTMLInputElement | null;

      if (input != null) {
        input.value = artistPercentage.percentage.toString();
      }
    });

    setArtistPercentages(newArtistPercentages);
  };

  useEffect(() => {
    setIsValid(
      validatePercentages() &&
        artistInfoData.artist_name !== '' &&
        artistInfoData.real_name !== '' &&
        artistInfoData.email !== '' &&
        (selectedArtistsRole.length > 0 ||
          artistInfoData.artist_type === 'MAINARTIST' ||
          artistInfoData.artist_type === 'FEATUREDARTIST'),
    );
  }, [artistInfoData, selectedArtistsRole, artistPercentages]);

  const resetFields = () => {
    setArtistInfoData(initial);
    setSelectedArtistRole([]);
    setSongName('');
    setArtistPercentages([]);
  };

  const handleSave = async () => {
    if (!isValid || isAwaitingResponse) {
      return;
    }

    setIsAwaitingResponse(true);

    if (!isNewArtist) {
      const response = await API.trackReleases.updateArtist(
        {
          id: artistInfoData.id,
          artist_name: artistInfoData.artist_name,
          real_name: artistInfoData.real_name,
          email: artistInfoData.email,
          artist_role: selectedArtistsRole,
          artist_type: artistInfoData.artist_type,
          spotify_artist_id:
            artistInfoData.spotify_artist_id || spotifyArtist?.id || '',
          apple_artist_id: artistInfoData.apple_artist_id || '',
          youtube_artist_id: artistInfoData.youtube_artist_id || '',
          first_artist: isFirstArtist,
        },
        trackId,
      );

      setIsAwaitingResponse(false);

      if (response.success) {
        API.trackReleases
          .updateArtistsPercentages({ percentages: getPercentages() }, trackId)
          .then((resp) => {
            if (resp.success) {
              fetchArtistDetails();
              resetFields();
              handleCloseArtistPage(true);
            } else {
              setError(resp.message);
            }
          });
      } else {
        setError(response.message);
      }
    } else {
      const response = await API.trackReleases.addArtist(
        {
          artist_name: artistInfoData.artist_name,
          real_name: artistInfoData.real_name,
          email: artistInfoData.email,
          artist_role: selectedArtistsRole,
          artist_type: artistInfoData.artist_type,
          spotify_artist_id: spotifyArtist?.id || '',
          apple_artist_id: artistInfoData.apple_artist_id || '',
          youtube_artist_id: artistInfoData.youtube_artist_id || '',
          first_artist: isFirstArtist,
        },
        trackId,
      );

      setIsAwaitingResponse(false);

      if (response.success) {
        const { artists } = response.data.artist_details;
        const newArtistId = artists[artists.length - 1].id;

        API.trackReleases
          .updateArtistsPercentages(
            { percentages: getPercentages(newArtistId) },
            trackId,
          )
          .then((resp) => {
            if (resp.success) {
              fetchArtistDetails();
              resetFields();
              handleCloseArtistPage(true);
            } else {
              setError(resp.message);
            }
          });
      } else {
        setError(response.message);
      }
    }
  };

  const validatePercentages = () => {
    const percentagesSum = getPercentageSum();

    if (percentagesSum >= 99 && percentagesSum <= 100) {
      setError('');
      return true;
    }
    setError(t('organisms:enterRoyaltiesError') as string);
    return false;
  };

  const getPercentageSum = () => {
    let percentagesSum = artistPercentages.reduce(
      (accumulator: number, artistPercentage: ArtistPercentage) =>
        accumulator + Number(artistPercentage.percentage),
      0,
    );

    if (isNewArtist) {
      percentagesSum += artistInfoData.percentage || 0;
    }

    return percentagesSum;
  };

  const getPercentages = (newArtistId?: number) => {
    const percentages = [...artistPercentages];

    if (newArtistId) {
      percentages.push({
        artist_id: newArtistId,
        percentage: artistInfoData.percentage || 0,
      });
    }

    return percentages;
  };

  const onClose = () => {
    handleCloseArtistPage();
  };

  return (
    <Dialog open={isMainFlowArtistDialogOpen} onClose={onClose}>
      <div
        className={`pb-2 ${ismobile ? '' : 'px-2'}`}
        style={{ maxWidth: '35rem' }}
      >
        <CardBody className="text-center d-flex flex-column">
          <div className="mb-2">
            <TextBold24>
              {spotifyArtist?.name || isNewArtist
                ? t('organisms:addArtist')
                : t('organisms:editArtist')}
            </TextBold24>
            <div>
              {!isNewArtist ? (
                ''
              ) : (
                <div>
                  <div>{t('organisms:profileWillBeCreatedOn')}</div>
                  <div>{t('organisms:byUs')}</div>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="mb-1">
              <Label className="text-left d-flex">
                <TextRegular14>{t('organisms:artistName')}</TextRegular14>
              </Label>
              <div className="d-flex flex-column justify-content-center ">
                <Input
                  value={artistInfoData?.artist_name}
                  type="text"
                  name="artist_name"
                  id="artist_name_search"
                  placeholder={t('organisms:searchArtistName')}
                  className="text-dark mr-1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleArtistNameChange(e)
                  }
                  disabled={
                    artistInfoData.spotify_artist_id &&
                    artistInfoData.spotify_artist_id !== ''
                  }
                />
                {artistInfoData.spotify_artist_id === '' && (
                  <TextRegular12
                    className="text-left text-muted"
                    style={{ paddingTop: '8px' }}
                  >
                    {t('organisms:useCorrectName')}
                  </TextRegular12>
                )}
              </div>
            </div>
            <div className="mb-1">
              <Label className="text-left d-flex">
                <TextRegular14>{t('organisms:realName')}</TextRegular14>
              </Label>
              <div className="d-flex flex-column justify-content-center ">
                <Input
                  value={artistInfoData?.real_name}
                  type="text"
                  name="real_name_input"
                  id="real_name_input"
                  placeholder={t('organisms:searchArtistName')}
                  className="text-dark mr-1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleRealNameChange(e)
                  }
                />
                <TextRegular12
                  className="text-left text-muted"
                  style={{ paddingTop: '8px' }}
                >
                  {t('organisms:useYourLegalName')}
                </TextRegular12>
              </div>
            </div>
            <div className="mb-1">
              <Label className="text-left d-flex">
                <TextRegular14>{t('organisms:emailId')}</TextRegular14>
              </Label>
              <div className="d-flex flex-column justify-content-center">
                <Input
                  value={artistInfoData?.email}
                  type="text"
                  name="email"
                  id="email_input"
                  placeholder={t('organisms:email')}
                  className="text-dark mr-1"
                  disabled={
                    isFirstAdded || artistInfoData.email === currentUser.email
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEmailChange(e)
                  }
                />
                <TextRegular12
                  className="text-left text-muted"
                  style={{ paddingTop: '8px' }}
                >
                  {t('organisms:useActiveEmailId')}
                </TextRegular12>
              </div>
            </div>
            <div className="d-flex flex-column my-1 align-items-start justify-content-start">
              <TextRegular14 className="text-left mb-1">
                {t('organisms:artistType')}
              </TextRegular14>
              {/* Main Artist */}
              <div className="d-flex flex-column mb-1">
                <div className="d-flex align-items-center">
                  <CustomInput
                    type="radio"
                    id="main_artist"
                    checked={artistInfoData.artist_type === 'MAINARTIST'}
                    onChange={() => handleArtistTypeChange('MAINARTIST')}
                    inline
                    className="mr-0"
                  />
                  <TextRegular16>{t('organisms:mainArtist')}</TextRegular16>
                </div>
                <div
                  className="text-muted text-left ml-2 mb-1"
                  style={{ paddingLeft: '0.3rem' }}
                >
                  <TextRegular12>
                    {t('organisms:thisArtistNameWillBeVisible')}
                    <br />
                    {t('organisms:eg')}{' '}
                    <strong>{t('organisms:artistName')} </strong>{' '}
                    {t('organisms:songName')}
                  </TextRegular12>
                </div>
                {artistInfoData.artist_type === 'MAINARTIST' && (
                  <div
                    className={`d-flex text-left ${
                      isFirstAdded ? 'text-muted' : ''
                    }`}
                  >
                    <CustomInput
                      type="switch"
                      id="first_artist"
                      defaultChecked={isFirstAdded || isFirstArtist}
                      onChange={() => handleFirstArtistChange()}
                      inline
                      className="mr-0"
                      disabled={
                        isFirstAdded ||
                        (allArtists && allArtists.length <= 1 && !isNewArtist)
                      }
                    />
                    <div>
                      <TextBolder12>
                        {t('organisms:makeThisAsFirst')}
                      </TextBolder12>
                      <br />
                      <TextRegular12>
                        {isFirstAdded ? (
                          <>{t('organisms:thisArtistIsTheOnlyArtist')}</>
                        ) : (
                          <>{t('organisms:firstArtistNammeWillAppear')}</>
                        )}
                      </TextRegular12>
                    </div>
                  </div>
                )}
              </div>
              {/* Featured Artist */}
              <div className="d-flex flex-column mb-1">
                <div className="d-flex align-items-center">
                  <CustomInput
                    type="radio"
                    id="featured_artist"
                    checked={artistInfoData.artist_type === 'FEATUREDARTIST'}
                    onChange={() => handleArtistTypeChange('FEATUREDARTIST')}
                    inline
                    className="mr-0"
                    disabled={
                      isFirstAdded ||
                      (allArtists && allArtists.length <= 1 && !isNewArtist)
                    }
                  />
                  <TextRegular16>
                    {t('organisms:featuredArtistSmall')}
                  </TextRegular16>
                </div>
                <div
                  className="text-muted text-left ml-2"
                  style={{ paddingLeft: '0.3rem' }}
                >
                  <TextRegular12>
                    {t('organisms:thisArtistWillBeIncluded')}
                    <br />
                    {t('organisms:eg')} {t('organisms:artistName')}{' '}
                    {t('organisms:songName')}
                    <strong>{t('organisms:artistName')} </strong>)
                  </TextRegular12>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column my-1">
              <TextRegular14 className="text-left">
                {t('organisms:artistRole')}
              </TextRegular14>
              <div className="d-flex w-100 flex-wrap">
                {allArtistsRoleArray.map((artistRole: ArtistRole) => (
                  <div className="left-text mt-1 d-flex" key={artistRole.id}>
                    <CustomInput
                      type="checkbox"
                      className="custom-control-Primary font-weight-bold"
                      id={artistRole.id}
                      label={artistRole.name}
                      inline
                      checked={selectedArtistsRole?.includes(artistRole.name)}
                      onChange={() => toggleCheckbox(artistRole.name)}
                    />
                  </div>
                ))}
              </div>
            </div>
            {isFirstAdded ? (
              <></>
            ) : (
              <Row>
                <Col xs={6} className="mb-1">
                  <Label>
                    {artistInfoData.artist_name || t('organisms:artist')}&apos;{' '}
                    {t('organisms:split')}
                  </Label>
                  <Input
                    defaultValue={artistInfoData.percentage}
                    type="number"
                    className="text-dark mr-1"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      debouncedPercentages.callback(e, artistInfoData.id)
                    }
                  />
                </Col>
                <>
                  {allArtists && allArtists.length > 1 ? (
                    allArtists
                      ?.filter((a) => a.artist_type !== 'CONTRIBUTOR')
                      .map((a, index) => {
                        if (index !== 0 && a.id !== artistInfoData.id) {
                          const artistPercentage = artistPercentages.find(
                            (ap) => ap.artist_id === a.id,
                          )?.percentage;
                          return (
                            <Col
                              xs={6}
                              className="mb-1"
                              key={`artist-percentage-${a.id}`}
                            >
                              <Label>
                                {a.artist_name || t('organisms:artist')}&apos;{' '}
                                {t('organisms:split')}
                              </Label>
                              <Input
                                defaultValue={artistPercentage || 0}
                                id={`input-${a.id}`}
                                type="number"
                                name={`artist_name-${a.id}`}
                                className="text-dark"
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => debouncedPercentages.callback(e, a.id)}
                              />
                            </Col>
                          );
                        }
                        return null;
                      })
                  ) : (
                    <></>
                  )}
                  {artist?.id !== allArtists?.[0]?.id && (
                    <Col xs={6} className="mb-1">
                      <Label>
                        <span className="text-bolder">
                          {(allArtists && allArtists[0].artist_name) ||
                            t('organisms:artist')}
                          &apos; {t('organisms:split')}
                        </span>
                      </Label>

                      <Input
                        defaultValue={
                          (artistPercentages &&
                            artistPercentages?.[0]?.percentage) ||
                          0
                        }
                        id={`input-${
                          (artistPercentages &&
                            artistPercentages?.[0]?.artist_id) ||
                          0
                        }`}
                        type="number"
                        className={`${
                          getPercentageSum() >= 99 && getPercentageSum() <= 101
                            ? 'text-dark'
                            : 'text-danger'
                        }`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          debouncedPercentages.callback(
                            e,
                            artistPercentages?.[0]?.artist_id || undefined,
                          )
                        }
                      />
                    </Col>
                  )}
                </>
              </Row>
            )}
          </div>

          <TextBolder14 className="text-danger mt-1">
            {error !== '' && error}
          </TextBolder14>

          <Row
            style={{ backgroundColor: style.lightSilver }}
            className="p-1 text-left"
          >
            <div className="text-muted d-block w-100">
              <TextBold12>{t('organisms:songNamePreview')}</TextBold12>
            </div>
            <div>
              <TextBold14>{getSongNamePreview()}</TextBold14>
            </div>
          </Row>
        </CardBody>
        <Button
          color="primary"
          text={`${
            spotifyArtist?.name || isNewArtist
              ? t('organisms:saveAndAddArtist')
              : t('organisms:saveAndEditArtist')
          }`}
          className="d-flex mx-auto pt-1"
          onClick={handleSave}
          disabled={!isValid}
        />
      </div>
    </Dialog>
  );
}

const roundToTwoDecimalPlaces = (num: number) => {
  const rounded = Math.round((num + Number.EPSILON) * 100) / 100;
  return rounded;
};

export default MainFlowArtistDialog;
