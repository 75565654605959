import React from 'react';
import { ArrowRight } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { useIsMobile } from '../../style/constants';
import {
  TextRegular14,
  TitleBold21,
  TitleBold28,
  TitleBold46,
} from '../atoms/Text';
import { Button, Col, Row } from '../atoms/_index';

interface DistributionLandingRequestReleaseProps {
  isLoggedIn: boolean;
  hasTracks: boolean;
  onRequestRelease?: () => void;
}

function DistributionLandingRequestRelease({
  isLoggedIn,
  hasTracks,
  onRequestRelease,
}: DistributionLandingRequestReleaseProps) {
  const isMobile = useIsMobile();
  const { t } = useTranslation('organisms');
  if (!isLoggedIn || !hasTracks) {
    return <></>;
  }

  return (
    <Row
      className={`bg-primary d-flex align-items-center justify-content-center  rounded mb-3 mx-auto ${
        isMobile ? 'w-100 px-2 py-3' : 'w-75 px-3 py-5'
      }`}
    >
      <Col className="text-center text-white">
        {isMobile ? (
          <TitleBold21 className="text-white">
            {t('organisms:getSelectedForIndependentRelease')}
          </TitleBold21>
        ) : (
          <TitleBold28 className="text-white">
            {t('organisms:getSelectedForIndependentRelease')}
          </TitleBold28>
        )}
        <TitleBold46 className="mt-1 d-block text-white">
          {t('organisms:requestARelease')}
        </TitleBold46>
        {!isMobile ? (
          <TextRegular14 className="mt-1 d-block">
            {t('organisms:dontMissOut')}
          </TextRegular14>
        ) : (
          <></>
        )}
        <Button
          color="light"
          text={t('organisms:requestNow') as string}
          size="lg"
          onClick={onRequestRelease}
          className="mt-2"
        >
          <ArrowRight size={18} />
        </Button>
      </Col>
    </Row>
  );
}

export default DistributionLandingRequestRelease;
