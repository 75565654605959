import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { PaymentInfo, API, useCountries, CountryOption } from '../../api';
import Container from '../atoms/Container';
import Row from '../atoms/Row';
import { TextBold18 } from '../atoms/Text';
import VuexyButton from '../atoms/Button';
import PaymentDetailsContentDistribution from '../molecules/PaymentDetailsContentDistribution';

const PaymentInfoInitialState: PaymentInfo = {
  payment_type: 'bank',
  bank_name: '',
  account_number: '',
  iban_code: '',
  country_id: 0,
  paypal_email: '',
};

interface MainFlowPaymentDetailsProps {
  nextStepHandler: () => void;
  trackId: number;
}

function MainFlowPaymentDetails({
  nextStepHandler,
  trackId,
}: MainFlowPaymentDetailsProps) {
  const { t } = useTranslation();

  const [paymentInfoData, setPaymentInfoData] = useState<PaymentInfo>(
    PaymentInfoInitialState,
  );

  const [errorBankMessage, setErrorBankMessage] = useState<string>('');
  const [errorPaypalMessage, setErrorPaypalMessage] = useState<string>('');

  const [submitDisable, setSubmitDisable] = useState<boolean>(false);

  const [countryOptions, setCountryOptions] = useState<CountryOption[]>([]);
  const [
    selectedCountryOptionValue,
    setSelectedCountryOptionValue,
  ] = useState<CountryOption>();
  const { result: countries } = useCountries();

  useEffect(() => {
    if (countries.length === 0) return;

    const coutryOptionsMapper = countries.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    setCountryOptions(coutryOptionsMapper);

    try {
      API.trackReleases.getTrackRelease(trackId).then((response) => {
        const paymentSetup = response?.data?.payment_setup?.payment_info;
        if (paymentSetup) {
          setPaymentInfoData({
            ...paymentSetup,
            payment_type: paymentSetup.payment_type || 'bank',
          });
          const countryFilter = coutryOptionsMapper.find(
            (item) => item.value.toString() === paymentSetup?.country_id,
          );
          setSelectedCountryOptionValue(countryFilter);
        }
      });
    } catch (error: any) {
      console.log('Error while fetching data', error);
    }
  }, [countries]);

  useEffect(() => {
    let isEmpty = false;
    if (paymentInfoData?.payment_type === 'bank') {
      isEmpty =
        paymentInfoData.account_number === '' ||
        paymentInfoData.bank_name === '' ||
        paymentInfoData.country_id === 0 ||
        paymentInfoData.iban_code === '';
    } else if (paymentInfoData?.payment_type === 'paypal') {
      isEmpty = paymentInfoData?.paypal_email === '';
    }
    setSubmitDisable(isEmpty);
  }, [paymentInfoData]);

  const handleSave = async () => {
    const paymentInfo: PaymentInfo =
      paymentInfoData?.payment_type === 'bank'
        ? {
            payment_type: 'bank',
            bank_name: paymentInfoData.bank_name,
            account_number: paymentInfoData.account_number,
            iban_code: paymentInfoData.iban_code,
            country_id: paymentInfoData.country_id,
          }
        : {
            payment_type: 'paypal',
            paypal_email: paymentInfoData?.paypal_email,
          };

    const response = await API.trackReleases.postPaymentInfo(
      paymentInfo,
      trackId,
    );

    if (response.success) {
      setErrorBankMessage('');
      nextStepHandler();
    } else {
      setSubmitDisable(true);
      if (paymentInfoData?.payment_type === 'bank') {
        setErrorPaypalMessage('');
        setErrorBankMessage(response.message);
      }
      if (paymentInfoData?.payment_type === 'paypal') {
        setErrorBankMessage('');
        setErrorPaypalMessage(response.message);
      }
    }
  };

  const handleBankNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentInfoData({ ...paymentInfoData, bank_name: e.target.value });
  };

  const handleAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPaymentInfoData({ ...paymentInfoData, account_number: e.target.value });
  };

  const handleIBANCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentInfoData({ ...paymentInfoData, iban_code: e.target.value });
  };

  const handleCountyCodeChange = (e: CountryOption) => {
    setPaymentInfoData({
      ...paymentInfoData,
      country_id: parseInt(e.value.toString(), 10),
    });
  };

  const handlePayPalEmailIdChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSubmitDisable(false);
    setPaymentInfoData({ ...paymentInfoData, paypal_email: e.target.value });
  };

  const handlePaymentCheck = (paymentType: 'bank' | 'paypal') => {
    setPaymentInfoData({ ...paymentInfoData, payment_type: paymentType });
  };

  return (
    <Container className="mb-4 px-0">
      <Row className="d-flex justify-content-between mt-2">
        <TextBold18 className="pl-1">{t('organisms:payment')}</TextBold18>
      </Row>
      <PaymentDetailsContentDistribution
        paymentInfoData={paymentInfoData}
        errorBankMessage={errorBankMessage}
        errorPaypalMessage={errorPaypalMessage}
        countryOptions={countryOptions}
        selectedCountryOptionValue={selectedCountryOptionValue}
        handlePayPalEmailIdChange={handlePayPalEmailIdChange}
        handleIBANCodeChange={handleIBANCodeChange}
        handleAccountNumberChange={handleAccountNumberChange}
        handleBankNameChange={handleBankNameChange}
        handlePaymentCheck={handlePaymentCheck}
        setSelectedCountryOptionValue={setSelectedCountryOptionValue}
        handleCountyCodeChange={handleCountyCodeChange}
      />

      <VuexyButton
        className="mt-3 mb-4"
        color="success"
        onClick={handleSave}
        disabled={submitDisable}
      >
        {t('organisms:saveAndProceed') as string}
      </VuexyButton>
    </Container>
  );
}

export default MainFlowPaymentDetails;
