import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useIsLoggedIn } from '../api';

const useLoginWithRedirect = (
  redirectImmediately = true,
  customQuery: Record<string, string | number | boolean> = {},
  redirectAsPath?: boolean,
) => {
  const loggedIn = useIsLoggedIn();
  const { asPath, push, pathname } = useRouter();

  const perform = async () => {
    const redirectQuery = Object.keys(customQuery).length
      ? queryString.stringify(customQuery)
      : undefined;

    if (!loggedIn) {
      await push({
        pathname: '/login',
        query: {
          redirect: redirectAsPath ? asPath : pathname,
          redirectQuery,
        },
      });
    }
  };

  if (redirectImmediately) {
    perform();
  }

  return perform;
};

export default useLoginWithRedirect;
