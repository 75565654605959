import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import MoonLoader from 'react-spinners/ScaleLoader';
import { Camera, X } from 'react-feather';
import { style } from '../../api';

const fileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

const acceptedFileType = fileTypes.join(',');

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: row;
  gap: 10px;
  top: 0px;
  left: 0px;
  z-index: 9;
  user-select: none;
`;
interface Props {
  size?: number;
  className?: string;
  children: React.ReactNode;
  onUpload: (file?: File | null) => Promise<void>;
  forceShowOverlay?: boolean;
}

export default styled<(props: Props) => JSX.Element>(
  ({ size = 40, className, children, onUpload, forceShowOverlay }: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const delegateClick = () => inputRef?.current?.click();
    const [uploading, setUploading] = useState(false);

    const sendImage = async ({
      target,
    }: React.ChangeEvent<HTMLInputElement>) => {
      const image = target && target.files && target.files[0];
      setUploading(true);
      await onUpload(image);
      setUploading(false);
    };

    return (
      <Wrapper
        className={className}
        onClick={delegateClick}
        uploading={uploading}
        forceShowOverlay={forceShowOverlay}
      >
        {children}
        <Overlay>
          {(forceShowOverlay || uploading) && uploading ? (
            <MoonLoader color={style.white} />
          ) : (
            <Camera size={size} color={style.white} />
          )}
        </Overlay>
        <FileInput
          type="file"
          onChange={sendImage}
          ref={inputRef}
          accept={acceptedFileType}
        />
      </Wrapper>
    );
  },
)``;

export const WithUploadOverlay: React.FC<{
  size?: number;
  onClick: () => void;
}> = ({ children, onClick, size = 40 }) => (
  <Wrapper>
    {children}
    <Overlay>
      <IconCircle onClick={onClick} className="cursor-pointer">
        <Camera size={size} color={style.white} />
      </IconCircle>
    </Overlay>
  </Wrapper>
);

export const CoverWithUploadOverlay: React.FC<{
  size?: number;
  onClick: () => void;
  removeImage: () => void;
}> = ({ children, onClick, size = 40, removeImage }) => (
  <Wrapper>
    {children}
    <Overlay>
      <CoverIconCircle onClick={onClick} className="cursor-pointer">
        <Camera size={size} color={style.white} />
      </CoverIconCircle>
      <CoverIconCircle onClick={removeImage} className="cursor-pointer">
        <X size={size} color={style.white} />
      </CoverIconCircle>
    </Overlay>
  </Wrapper>
);

const IconCircle = styled.div`
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  padding: 25px;
  display: hidden;
  align-items: center;
  justify-content: center;
`;
const CoverIconCircle = styled.div`
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  padding: 16px;
  align-items: center;
  justify-content: center;
`;

const FileInput = styled.input`
  display: none !important;
  height: 0px !important;
  width: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
`;

const Wrapper = styled.div<{ uploading?: boolean; forceShowOverlay?: boolean }>`
  overflow: hidden;
  position: relative;
`;
