import React from 'react';
import { MainFlowCongratulationBox } from '../molecules/_index';

function MainFlowCongratulationPage({
  trackId,
}: MainFlowCongratulationPageProps) {
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <MainFlowCongratulationBox trackId={trackId} />
      </div>
    </>
  );
}

export default MainFlowCongratulationPage;

interface MainFlowCongratulationPageProps {
  trackId: number;
}
