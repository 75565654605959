import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { TrackArtist } from '../../api';
import { TextBold16, TextBolder12, TextRegular14 } from '../atoms/Text';

interface MainFlowArtistCardProps {
  artist: TrackArtist;
}
const MainFlowContributorCard = ({ artist }: MainFlowArtistCardProps) => {
  const { t } = useTranslation('organisms');
  const newArtistRoleArray = artist?.artist_role
    ?.map((role) =>
      role === t('organisms:lyricist') ? t('organisms:Songwriter') : role,
    )
    .join(', ');
  const isCorrect = false;
  return (
    <div
      style={{
        border: '3px solid #CCCCCC',
        borderRadius: '4px',
      }}
      className="p-1"
    >
      <div className="d-flex flex-column">
        <TextBolder12 className="text-uppercase mb-1">
          {t('organisms:contributor')}
        </TextBolder12>
        <TextBold16 className="text-bold" style={{ marginBottom: '5px' }}>
          {artist.real_name}
        </TextBold16>
        {artist.artist_role.length > 0 ? (
          <TextRegular14 className={isCorrect ? 'text-danger' : ''}>
            {newArtistRoleArray}
          </TextRegular14>
        ) : (
          t('organisms:artistRoleNotSet')
        )}
      </div>
    </div>
  );
};

export default MainFlowContributorCard;
