import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import ReactPlayer from 'react-player/youtube';
import { PlayCircle } from 'react-feather';
import styled from 'styled-components';

class StrapModal extends React.Component {
  state = {
    isOpen: false,
  };

  toggleModal = () => {
    const currentState = this.state.isOpen;
    this.setState({ isOpen: !currentState });
  };

  render() {
    const { isOpen } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <PlayButtonContainer>
          <div onClick={this.toggleModal} style={{ cursor: 'pointer' }}>
            <PlayCircle size="110" strokeWidth="1" color="white" />
          </div>
        </PlayButtonContainer>
        <Modal
          style={{ marginTop: '10rem', maxWidth: '1000px' }}
          isOpen={isOpen}
        >
          <ModalHeader
            style={{ backgroundColor: 'transparent' }}
            toggle={this.toggleModal}
          />
          <ReactPlayer
            url="https://www.youtube.com/watch?v=skUZRQE9lhA&t=26s"
            playing={false}
            muted
            width="100%"
            height="465px"
            controls
          />
        </Modal>
      </div>
    );
  }
}

const PlayButtonContainer = styled.div`
  position: relative;
  svg {
    animation: heartbeat 1.5s ease-in-out infinite both;
  }

  @keyframes heartbeat {
    from {
      transform: scale(1);
      transform-origin: center center;
      animation-timing-function: ease-out;
    }
    10% {
      transform: scale(0.91);
      animation-timing-function: ease-in;
    }
    17% {
      transform: scale(0.98);
      animation-timing-function: ease-out;
    }
    33% {
      transform: scale(0.87);
      animation-timing-function: ease-in;
    }
    45% {
      transform: scale(1);
      animation-timing-function: ease-out;
    }
  }
`;

export default StrapModal;
