import React, { useState } from 'react';
import { Minus, Plus } from 'react-feather';
import { TextBolder16, TextRegular14 } from '../atoms/Text';

function DistributionLandingFaqItem({ title, content }: FaItem) {
  const [collapse, setCollpase] = useState(false);

  return (
    <div
      className="border-bottom p-1 cursor-pointer"
      onClick={() => setCollpase(!collapse)}
    >
      <div
        className={`d-flex justify-content-between align-items-center ${
          collapse ? 'mb-1' : ''
        }`}
      >
        <TextBolder16>{title}</TextBolder16>
        <div>{collapse ? <Minus size={24} /> : <Plus size={24} />}</div>
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: content }}></div> */}
      <TextRegular14
        className={`${collapse ? 'd-block transition' : 'd-none transition'} `}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}

interface FaItem {
  title: string;
  content: string;
}

export default DistributionLandingFaqItem;
