import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AlertCircle, Activity, Play, ThumbsUp, Clock } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import Button from '../atoms/Button';
import { style, Track, useCurrentUser } from '../../api';
import DistributionIconGroup from '../molecules/DistributionIconGroup';
import Row from '../atoms/Row';
import Col from '../atoms/Col';
import { TextBolder12 } from '../atoms/Text';
import Loader from '../../components/common/Loader';

function DistributionMessage({ track }: DistributionMessageProps) {
  const { t } = useTranslation('organisms');
  const currentUser = useCurrentUser();
  const router = useRouter();

  let button;
  const isStreamNow = track.release_status === 'live';
  const isReleasePending = track.release_status === 'pending';
  const isReleaseAccepted = track.release_status === 'accepted';
  const isReleaseIncorrect = track.release_status === 'incorrect details';
  const isReleaseSubmitted = track.release_status === 'submitted';

  if (currentUser.requesting) {
    return (
      <div className="d-flex justify-content-center w-100">
        <Loader />
      </div>
    );
  }

  const shouldShowMessage =
    (!track.pulled_status &&
      !track.pulled_at &&
      track.release_status !== 'inactive' &&
      track.release_status !== 'declined' &&
      currentUser.id === track.user.id) ||
    (router.asPath.startsWith('/profile') && track.release_status === 'live');

  if (!shouldShowMessage) {
    return null;
  }

  if (isStreamNow) {
    button = (
      <span>
        {currentUser.id === track.user.id ? (
          <Link href={`/statistics?tab=releases&track_id=${track.id}`}>
            <div className="w-100">
              <Button color="success" className="mb-sm-0 mr-1">
                <>
                  <Activity size={14} className="mr-50" />
                  {t('organisms:viewStats') as string}
                </>
              </Button>
            </div>
          </Link>
        ) : (
          <Link href={`/track-releases/${track.id}`}>
            <div className="w-100">
              <Button color="primary">
                <>
                  <Play size={14} className="mr-50" />
                  {t('organisms:streamNow') as string}
                </>
              </Button>
            </div>
          </Link>
        )}
      </span>
    );
  }

  if (isReleasePending) {
    button = (
      <Link href={`/my-tracks?track_id=${track.id}`}>
        <div className="w-100">
          <Button
            className="py-0 w-100 d-flex flex-row justify-content-center border-0 br-5"
            style={{ background: style.distributionGradient }}
            text={t('organisms:releaseNow') as string}
          />
        </div>
      </Link>
    );
  }

  if (isReleaseAccepted) {
    button = (
      <Link href={`/distribution?track_id=${track.id}`}>
        <div className="w-100">
          <Button
            style={{
              background: style.distributionGradient,
              borderRadius: '5px',
              width: '170px',
              padding: '0.05rem',
              minWidth: '170px',
              maxWidth: '100%',
              maxHeight: '50px',
            }}
            className="px-0 d-flex w-100 align-items-baseline justify-content-around border-0 br-5"
          >
            <span
              style={{
                placeSelf: 'center',
              }}
            >
              <ThumbsUp size={14} />
              <span
                style={{
                  marginLeft: '5px',
                  marginTop: '2px',
                }}
              >
                {t('organisms:updateAndRelease') as string}
              </span>
            </span>
          </Button>
        </div>
      </Link>
    );
  }

  if (isReleaseIncorrect) {
    button = (
      <Link href={`/distribution?track_id=${track.id}`}>
        <div className="w-100">
          <Button color="danger">
            <>
              <AlertCircle size={14} className="mr-50" />
              {t('organisms:viewStats') as string}
            </>
          </Button>
        </div>
      </Link>
    );
  }

  if (isReleaseSubmitted) {
    button = (
      <Button color="secondary" disabled>
        <>
          <Clock size={14} className="mr-50" />
          {t('organisms:waitingOnApproval') as string}
        </>
      </Button>
    );
  }

  return (
    <div className="w-100 text-center mx-auto d-flex justify-content-center">
      <Row
        className="w-100 d-flex justify-content-between pb-sm-1 pb-lg-0 br-5 mt-1"
        style={{
          background: 'linear-gradient(135deg, #FCF3F8 0%, #F5F8FE 100%)',
        }}
      >
        <Col xs="12" lg="8">
          <div className="d-flex flex-column py-1">
            <TextBolder12 className="text-center text-lg-left">
              {t('organisms:eligibleForPublish') as string}
            </TextBolder12>
            <div className="align-self-center align-self-lg-start">
              <DistributionIconGroup iconSize={23} />
            </div>
          </div>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="d-flex align-items-center align-items-md-end flex-column justify-content-center pb-sm-0 pb-1"
        >
          {button}
        </Col>
      </Row>
    </div>
  );
}

export default DistributionMessage;
interface DistributionMessageProps {
  track: Track;
}
