import React from 'react';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { Check, RefreshCcw, Radio, Clock, ThumbsDown } from 'react-feather';
import { TextRegular14 } from '../atoms/Text';
import { Col, Row } from '../atoms/_index';

// import Loader from '../../components/common/Loader';

export type TrackReleaseLiveStatusProps = {
  trackStoreDeliveryStatusData: {
    name: string;
    status: string;
  }[];
};
function TrackDeliveryStatusDetails({
  trackStoreDeliveryStatusData,
}: TrackReleaseLiveStatusProps) {
  const { t } = useTranslation('myTracks');

  const getNameFromLiveStatus = (status: string) => {
    let statusName = '';
    let statusIcon = <></>;
    let backgroundColor = '';
    if (status === 'DELIVERED') {
      statusName = 'Delivered';
      statusIcon = <Check size={13} style={{ marginRight: '4px' }} />;
      backgroundColor = '#67C639';
    } else if (
      status === 'CANCELLED' ||
      status === 'BLOCKED' ||
      status === 'TAKEN_DOWN' ||
      status === 'ERROR' ||
      status === 'DELETED' ||
      status === 'CANCELLED'
    ) {
      statusName = 'Cancelled';
      statusIcon = <ThumbsDown size={14} style={{ marginRight: '4px' }} />;
      backgroundColor = '#E43E1B';
    } else if (
      status === 'UNBLOCKED' ||
      status === 'SCHEDULED' ||
      status === 'PROCESSING' ||
      status === 'ADDED ' ||
      status === 'STALLED'
    ) {
      statusName = 'Scheduled';
      statusIcon = <Clock size={13} style={{ marginRight: '4px' }} />;
      backgroundColor = '#F2AD0D';
    } else if (status === 'REDELIVERED') {
      statusName = 'Redelivered';
      statusIcon = <RefreshCcw size={13} style={{ marginRight: '4px' }} />;
      backgroundColor = '#0A5AF5';
    } else if (status === 'LIVE') {
      statusName = 'Live';
      statusIcon = <Radio size={13} style={{ marginRight: '4px' }} />;
      backgroundColor = '#67C639';
    }

    return {
      statusName,
      statusIcon,
      backgroundColor,
    };
  };
  return (
    <>
      <Row className="d-flex mt-1 mb-1 align-items-center">
        <Col xs={12}>
          <TextRegular14>
            {t('myTracks:storeDeliveryStatus.modalHeader') as string}
          </TextRegular14>
        </Col>
      </Row>

      <Row className="mt-1 mb-3">
        {trackStoreDeliveryStatusData?.length !== 0 ? (
          <>
            {trackStoreDeliveryStatusData?.map((e) => (
              <Col
                xs={6}
                md={4}
                xl={4}
                className="mb-1"
                key={`store-delivery-status-${e.name}`}
              >
                {/* <div className="d-flex flex-row "> */}
                <div className="d-flex flex-column align-items-center">
                  <div style={{ marginBottom: '4px' }}>{e.name}:</div>
                  <div>
                    <SpanDesignStatus
                      className="d-flex flex-row"
                      style={{
                        backgroundColor: `${
                          getNameFromLiveStatus(e.status).backgroundColor
                        }`,
                      }}
                    >
                      {getNameFromLiveStatus(e.status).statusIcon}
                      {getNameFromLiveStatus(e.status).statusName}
                    </SpanDesignStatus>
                  </div>
                </div>
              </Col>
            ))}
          </>
        ) : (
          <>
            {/* <Loader /> */}
            <div className="mt-5 font-weight-bold"> No Data</div>
          </>
        )}
      </Row>
    </>
  );
}

const SpanDesignStatus = styled.div`
  padding: 2px 9px;
  background: #f2ad0d;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  margin-left: 6px;
  align-items: center;
`;

export default TrackDeliveryStatusDetails;
