import React from 'react';
import { ArrowRight } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { BsFillCheckCircleFill, BsXCircle } from 'react-icons/bs';
import Dialog from '../common/Dialog';
import { DialogContent } from '../common/MaterialDialog';
import {
  TextBolder22,
  TextRegular14,
  TextRegular16,
} from '../../VuexyDesign/atoms/Text';
import { Button, Col, Row } from '../../VuexyDesign/atoms/_index';
import { useIsDesktop } from '../../style/constants';

function DistibutionCofirmationDialog({
  onClose,
  isOpen,
  onConfirm,
}: DistibutionCofirmationDialogProps) {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation('pricing');
  const DosDontList = [
    t('distributionLanding:doUploadArtworkWith'),
    t('distributionLanding:dontUseSexualContent'),
    t('distributionLanding:doSelectExplicitContent'),
    t('distributionLanding:dontIncludeUnnecessary'),
    t('distributionLanding:doCleanOriginalTitle'),
    t('distributionLanding:dontUseIntroSKitOutroTitle'),
    t('distributionLanding:doUploadHighQuality'),
    t('distributionLanding:dontUploadBadQuality'),
  ];

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        style={{
          width: isDesktop ? '55rem' : '',
          overflowY: 'auto',
        }}
        className="p-0 m-0"
      >
        <div className="w-100 mx-auto mb-3 d-flex flex-column justify-content-center align-items-center text-center">
          <TextBolder22>
            {t('distributionLanding:prepareYourTrack') as string}{' '}
          </TextBolder22>
          <TextRegular16 className="mt-1">
            {t('distributionLanding:doDontSubmittingRelease') as string}
          </TextRegular16>

          <Row className="w-100 my-2 text-left px-3">
            {isDesktop ? (
              <>
                {DosDontList.map((textData, i) => (
                  <Col
                    xs={12}
                    lg={6}
                    className="d-flex mt-1"
                    key={`do-donts-distribution-${textData}`}
                  >
                    {i % 2 === 0 ? (
                      <span>
                        <BsFillCheckCircleFill
                          className="text-success"
                          size={24}
                        />
                      </span>
                    ) : (
                      <span>
                        <BsXCircle className="text-danger" size={24} />
                      </span>
                    )}

                    <TextRegular14 className="ml-1">{textData}</TextRegular14>
                  </Col>
                ))}
              </>
            ) : (
              <>
                {DosDontList.filter((_f, i) => i % 2 === 0).map((textData) => (
                  <Col
                    xs={12}
                    lg={6}
                    className="d-flex mt-1"
                    key={`do-donts-distribution-${textData}`}
                  >
                    <span>
                      <BsFillCheckCircleFill
                        className="text-success"
                        size={24}
                      />
                    </span>

                    <TextRegular14 className="ml-1">{textData}</TextRegular14>
                  </Col>
                ))}
                {DosDontList.filter((_f, i) => i % 2 !== 0).map((textData) => (
                  <Col
                    xs={12}
                    lg={6}
                    className="d-flex mt-1"
                    key={`do-donts-distribution-${textData}`}
                  >
                    <span>
                      <BsXCircle className="text-danger" size={24} />
                    </span>

                    <TextRegular14 className="ml-1">{textData}</TextRegular14>
                  </Col>
                ))}
              </>
            )}
          </Row>

          <Button
            color="success"
            className="d-flex justify-content-between mt-1"
            onClick={onConfirm}
          >
            <>
              {t('distributionLanding:okayUnderstand') as string}
              <ArrowRight />
            </>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DistibutionCofirmationDialog;

interface DistibutionCofirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
