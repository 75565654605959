import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

function Tabs({
  tabs,
  active,
  style,
  className,
  tabStyle,
  tabClassName,
  setActive,
  shouldClearQueryParams,
}: TabsProps) {
  const router = useRouter();

  const [activeTab, setActiveTab] = useState(
    active?.toLocaleLowerCase() || tabs[0].label.toLowerCase(),
  );

  useEffect(() => {
    if (active) {
      setActiveTab(active.toLocaleLowerCase());
    }
  }, [active]);

  const handleClick = async (tab: TabProps) => {
    if (shouldClearQueryParams) {
      await router.replace(
        { pathname: router.pathname, query: undefined },
        undefined,
        {
          shallow: true,
        },
      );
    }

    setActiveTab(tab.label.toLowerCase());
    setActive(tab.label);
  };

  return (
    <div style={style} className={className}>
      {tabs.map((tab) => (
        <div
          key={`tab-${tab.label}`}
          className={`d-inline-flex justify-content-center align-items-center user-select-none font-weight-bold ${
            activeTab === tab.label.toLowerCase() ? 'bg-primary text-white' : ''
          } ${tabClassName || ''}`}
          style={{
            borderRadius: '4px',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            marginLeft: '10px',
            marginRight: '10px',
            paddingLeft: '15px',
            paddingRight: '15px',
            cursor: 'pointer',
            ...tabStyle,
          }}
          onClick={() => handleClick(tab)}
        >
          {tab.label}
        </div>
      ))}

      {tabs.map((tab) =>
        tab.label.toLowerCase() === activeTab.toLowerCase() ? (
          <div key={`$tab-content-${tab.label}`} className="mt-2">
            {tab.content}
          </div>
        ) : undefined,
      )}
    </div>
  );
}

export default Tabs;

interface TabsProps {
  tabs: TabProps[];
  active?: string;
  style?: React.CSSProperties;
  className?: string;
  tabStyle?: React.CSSProperties;
  tabClassName?: string;
  shouldClearQueryParams?: boolean;
  setActive: (active: string) => void;
}

interface TabProps {
  label: string;
  content: JSX.Element;
}
