import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { TextRegular16 } from '../atoms/Text';
import { Button } from '../atoms/_index';

function CreateNewMusicId({ handleClick }: CreateNewMusicIdProps) {
  const { t } = useTranslation('artist');
  return (
    <div className="w-100">
      <TextRegular16
        className="d-flex text-dark text-center justify-content-center"
        style={{ paddingBottom: '10px' }}
      >
        {t('artist:notOnStreamingAlready')}
      </TextRegular16>
      <Button
        outline
        color="dark"
        text="Create New Spotify &amp; Apple Music ID"
        className="w-75 align-self-center"
        onClick={handleClick}
      />
    </div>
  );
}

export default CreateNewMusicId;

interface CreateNewMusicIdProps {
  handleClick?: () => void;
}
