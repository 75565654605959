import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { CardText, Button } from '../atoms/_index';

function WaitingOnApprovalSection() {
  const { t } = useTranslation('artist');
  return (
    <>
      <CardText className="mt-1 text-center">
        {t('artist:trackWasSelected') as string}
      </CardText>
      <Button
        color="secondary"
        disabled
        style={{ cursor: 'default', width: '200px' }}
        className="px-2 d-flex mb-1 br-5"
      >
        {t('artist:waitingOnRelease') as string}
      </Button>
    </>
  );
}

export default WaitingOnApprovalSection;
