import React from 'react';
import NextLink, { LinkProps } from 'next/link';

interface CustomLinkProps extends LinkProps {
  children: React.ReactNode;
}

const CustomLink = React.forwardRef<HTMLAnchorElement, CustomLinkProps>(
  ({ children, ...props }, ref) => (
    <NextLink legacyBehavior {...props}>
      <a ref={ref}>{children}</a>
    </NextLink>
  ),
);

export default CustomLink;
