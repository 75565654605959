import React from 'react';
import Link from 'next/link';
import { AlertCircle, ThumbsUp } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { style, Track } from '../../api';
import DistributionIconGroup from '../molecules/DistributionIconGroup';
import Button from '../atoms/Button';
import Row from '../atoms/Row';
import Col from '../atoms/Col';

function DistributionMessageEdit({ track }: DistributionMessageProps) {
  const { t } = useTranslation('organisms');
  if (
    track.pulled_status ||
    track.pulled_at ||
    track.release_status === 'inactive' ||
    track.release_status === 'declined' ||
    track.release_status === 'released'
  ) {
    return null;
  }
  const isReleasePending = track.release_status === 'pending';
  const isReleaseAccepted = track.release_status === 'accepted';
  const isReleaseIncorrect = track.release_status === 'incorrect details';

  let button;

  if (isReleasePending) {
    button = (
      <Link href={`/my-tracks?track_id=${track.id}`}>
        <Button
          className="py-0 w-100 mb-1 d-flex flex-row justify-content-center"
          style={{
            background: style.distributionGradient,
            borderRadius: '2px',
            padding: '0.05rem',
            minWidth: '200px',
            maxWidth: '300px',
            maxHeight: '50px',
          }}
          text={t('organisms:releaseNow') as string}
        />
      </Link>
    );
  }

  if (isReleaseAccepted) {
    button = (
      <Link href={`/distribution?track_id=${track.id}`}>
        <Button
          style={{
            background: style.distributionGradient,
            borderRadius: '2px',
            padding: '0.05rem',
            minWidth: '200px',
            maxWidth: '300px',
            maxHeight: '50px',
            placeSelf: 'center',
          }}
          className="px-0 w-100 d-flex justify-content-around mb-1"
        >
          <span>
            <ThumbsUp size={14} />
            <span style={{ marginLeft: '5px', marginTop: '2px' }}>
              {t('organisms:updateAndRelease')}
            </span>
          </span>
        </Button>
      </Link>
    );
  }

  if (isReleaseIncorrect) {
    button = (
      <Link href={`/distribution?track_id=${track.id}`}>
        <Button
          color="danger"
          className="px-2 w-100 d-flex align-items-baseline justify-content-around mb-1"
        >
          <span>
            <AlertCircle size={14} />
            <span style={{ marginLeft: '5px', marginTop: '2px' }}>
              {t('organisms:incorrectDetails')}
            </span>
          </span>
        </Button>
      </Link>
    );
  }

  return (
    <div
      className="w-100 text-center mx-auto p-1 d-flex "
      style={{
        background: 'linear-gradient(135deg, #FCF3F8 0%, #F5F8FE 100%)',
      }}
    >
      <Row className="px-1 w-100 d-flex justify-content-between">
        <Col xs="12" lg="6">
          <div className="d-flex flex-column w-100">
            <h1
              className="font-weight-bold text-center text-lg-left"
              style={{ fontSize: '12px', fontWeight: 700, textAlign: 'left' }}
            >
              {t('organisms:eligibleForPublish')}
            </h1>
            <div className="text-left align-self-center align-self-lg-start">
              <DistributionIconGroup iconSize={23} />
            </div>
          </div>
        </Col>
        <Col
          xs="12"
          lg="4"
          className="d-flex align-items-center align-items-sm-center flex-column pt-1"
        >
          {button}
        </Col>
      </Row>
    </div>
  );
}

export default DistributionMessageEdit;
interface DistributionMessageProps {
  track: Track;
}
