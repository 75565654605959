import React from 'react';
import { ArrowRight } from 'react-feather';
import Link from 'next/link';
import styled from 'styled-components';
import Container from '../atoms/Container';
import Button from '../atoms/Button';
import Col from '../atoms/Col';

const CtaBanner = ({
  title,
  subtitle,
  button,
  buttonText,
  buttonColor,
  link = '/register',
}: CtaBannerProps) => (
  <RoundedContainer className="d-md-flex bg-primary py-5 flex-column text-white mb-5 align-items-center justify-content-between">
    <Col sm={12} md={8} className="d-flex flex-column align-items-center">
      <div className="text-center">
        <h2 className="custom-heading-font-2 text-white">{title}</h2>
        <p>{subtitle}</p>
      </div>

      <>
        {button ? (
          <Link passHref href={link}>
            <div>
              <Button text={buttonText} color={buttonColor}>
                <ArrowRight size={16} />
              </Button>
            </div>
          </Link>
        ) : null}
      </>
    </Col>
  </RoundedContainer>
);

interface CtaBannerProps {
  title?: string;
  subtitle?: string;
  button?: boolean;
  buttonText?: string;
  buttonColor?: string;
  link?: string;
}

export default CtaBanner;

const RoundedContainer = styled(Container)`
  border-radius: 24px;
`;
