/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import {
  API,
  ArtistDetails,
  IncorrectDetails,
  style as colors,
  TrackArtist,
} from '../../api';
import Container from '../atoms/Container';
import Row from '../atoms/Row';
import Col from '../atoms/Col';

import { TextBold18, TextBolder14, TextRegular12 } from '../atoms/Text';
import VuexyButton from '../atoms/Button';
import MainFlowArtistCard from './MainFlowArtistCard';
import { MainFlowArtistBySearchDialog, MainFlowArtistDialog } from './_index';
import MainFlowArtistDeleteDialog from './MainFlowArtistDeleteDialog';
import { MainFlowIncorrectDetails } from '../molecules/_index';

interface MainFlowArtistDetailsProps {
  nextStepHandler: () => void;
  trackId: number;
  updatePreview: (val: boolean) => void;
  isIncorrect?: boolean;
}

const initial: ArtistDetails = {
  artists: [],
  label: '',
};

function MainFlowArtistDetails({
  nextStepHandler,
  trackId,
  updatePreview,
  isIncorrect,
}: MainFlowArtistDetailsProps) {
  const { t } = useTranslation('organisms');
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [openArtistPage, setOpenArtistPage] = useState(false);
  const [artistDetails, setArtistDetails] = useState<ArtistDetails>(initial);
  const [incorrectDetails, setIncorrectDetails] = useState<IncorrectDetails>();
  const [label, setLabel] = useState<string>('');
  const [editUser, setEditUser] = useState<TrackArtist>();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [artistToDelete, setArtistToDelete] = useState<TrackArtist>();

  const [serverError, setServerError] = useState<any>();
  const [isValid, setIsValid] = useState(false);

  const fetchArtistDetails = async () => {
    try {
      const response = await API.trackReleases.getTrackRelease(trackId);
      setArtistDetails(response?.data?.artist_details || undefined);
      setIncorrectDetails(response?.data.incorrect_details);
      setLabel(response?.data?.artist_details.label || '');
    } catch (e: any) {
      setServerError(e.response.data);
      console.log('Errors fetching Artists ', e);
    }
  };

  useEffect(() => {
    fetchArtistDetails();
    updatePreview(true);
  }, []);

  useEffect(() => {
    validate();
  }, [artistDetails]);

  const validate = () => {
    setIsValid(
      artistDetails.label !== null &&
        artistDetails.label !== '' &&
        artistDetails.artists.length !== 0,
    );
  };

  const handleCloseArtistPage = () => {
    updatePreview(true);
    setOpenArtistPage(false);
  };

  const handleOpenSearchArtist = () => {
    setOpenArtistPage(true);
  };

  const handleCloseEditDialog = () => {
    updatePreview(true);
    setEditUser(undefined);
    setShowEditDialog(false);
  };

  const handleOpenEditDialog = (artist: TrackArtist) => {
    setEditUser(artist);
    setShowEditDialog(true);
  };

  const handleOpenDeleteDialog = (artist: TrackArtist) => {
    setArtistToDelete(artist);
    setShowDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    updatePreview(true);
    setArtistToDelete(undefined);
    setShowDeleteDialog(false);
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
    setArtistDetails({ ...artistDetails, label: e.target.value });
  };

  const handleDelete = (artist?: TrackArtist) => {
    if (!artist || artist.id === undefined) {
      return;
    }

    API.trackReleases.deleteArtist(artist.id, trackId).then(() => {
      handleCloseDeleteDialog();
      fetchArtistDetails();
    });
    updatePreview(true);
  };

  const handleSave = () => {
    updatePreview(true);
    API.trackReleases.saveLabel({ label }, trackId);
    nextStepHandler();
  };

  return (
    <Container className="mb-4 px-0">
      <Row>
        <Col className="d-flex justify-content-between mt-2">
          <TextBold18>{t('organisms:artistDetails')}</TextBold18>
        </Col>
      </Row>
      {isIncorrect && incorrectDetails ? (
        <MainFlowIncorrectDetails
          incorrectDetails={incorrectDetails}
          step="artist_details"
          allArtists={artistDetails.artists}
        />
      ) : (
        <></>
      )}
      <CardRow className="mt-2 mb-1">
        <>
          {artistDetails?.artists
            ?.filter(
              (artist: TrackArtist) => artist.artist_type !== 'CONTRIBUTOR',
            )
            .map((artist: TrackArtist, index: number) => (
              <Col xs={12} md={8} key={`artist-card-${artist.id}`}>
                <div className="">
                  <MainFlowArtistCard key={artist.email} artist={artist} />
                  <div className="d-flex w-100 justify-content-center align-items-center mt-1 mb-2">
                    <TextBolder14
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleOpenEditDialog(artist)}
                    >
                      {t('organisms:edit')}
                    </TextBolder14>
                    {index !== 0 && (
                      <TextBolder14
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        className="text-danger ml-2"
                        onClick={() => handleOpenDeleteDialog(artist)}
                      >
                        {t('organisms:delete')}
                      </TextBolder14>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          {/* GET INFORMATION FOR EDIT */}
          {editUser && (
            <MainFlowArtistDialog
              trackId={trackId}
              artist={editUser}
              handleCloseArtistPage={handleCloseEditDialog}
              isMainFlowArtistDialogOpen={showEditDialog}
              fetchArtistDetails={fetchArtistDetails}
              isFirstAdded={false}
              allArtists={artistDetails.artists}
            />
          )}
          <Col xs={12} md={8} lg={4}>
            <div
              className="d-flex flex-column align-items-center justify-content-center px-2"
              style={{
                background: colors.gray,
                cursor: 'pointer',
                height: '175px',
                borderRadius: '4px',
              }}
              onClick={handleOpenSearchArtist}
            >
              <div>
                <Image
                  src="/static/images/cross.svg"
                  width={20}
                  height={20}
                  alt="test"
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </div>
              <div className="d-flex">
                {artistDetails.artists.length === 0 ? (
                  <TextBolder14 className="text-muted text-center user-select-none">
                    {t('organisms:addYourArtistDetails')}
                  </TextBolder14>
                ) : (
                  <TextBolder14 className="text-muted user-select-none">
                    {t('organisms:addOtherArtist')}
                  </TextBolder14>
                )}
              </div>
            </div>
          </Col>
        </>
      </CardRow>
      {/* add */}
      <MainFlowArtistBySearchDialog
        trackId={trackId}
        handleCloseArtistPage={handleCloseArtistPage}
        isMainFlowArtistBySearchDialogOpen={openArtistPage}
        fetchArtistDetails={fetchArtistDetails}
        isFirstAdded={artistDetails.artists.length === 0}
        allArtists={artistDetails.artists}
      />
      <div className="d-flex flex-column mt-1">
        <FormGroup>
          <Label for="recordLabelName">{t('organisms:recordLabelName')}</Label>
          <Input
            type="name"
            name="name"
            id="recordLabelName"
            placeholder={t('organisms:enterRecordLabelName')}
            className="text-dark"
            onChange={(e: any) => handleLabelChange(e)}
            value={artistDetails?.label || ''}
          />
        </FormGroup>
        {serverError && (
          <p style={{ color: colors.red }}>{serverError.message}</p>
        )}
      </div>
      <div className="d-flex flex-column mt-1">
        <TextRegular12>{t('organisms:writeYourArtistName')}</TextRegular12>
        <TextRegular12>{t('organisms:ifYourAreUsingNewLabel')}</TextRegular12>
      </div>
      <VuexyButton
        className="mt-3 mb-4"
        color="success"
        disabled={!isValid}
        onClick={handleSave}
      >
        {t('organisms:saveAndProceed') as string}
      </VuexyButton>

      <MainFlowArtistDeleteDialog
        isOpen={showDeleteDialog}
        onClose={handleCloseDeleteDialog}
        artist={artistToDelete}
        firstArtist={artistDetails.artists[0]}
        onDelete={() => handleDelete(artistToDelete)}
      />
    </Container>
  );
}

export default MainFlowArtistDetails;

const CardRow = styled(Row)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
