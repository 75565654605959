import Link from 'next/link';
import React from 'react';
import { AlertCircle } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { Track } from '../../api';
import Button from '../atoms/Button';
import CardText from '../atoms/CardText';

function IncorrectDetailsDistributionSection({ track }: { track: Track }) {
  const { t } = useTranslation('artist');
  return (
    <>
      <CardText className="mt-1 text-center">
        {t('artist:trackWasSelected') as string}
      </CardText>
      <Link href={`/distribution?track_id=${track.id}`}>
        <Button
          style={{ width: '200px' }}
          color="danger"
          className="d-flex align-items-center justify-content-around mb-1 br-5"
        >
          <AlertCircle size={14} />
          <span style={{ marginLeft: '5px' }}>
            {t('artist:incorrectDetails')}
          </span>
        </Button>
      </Link>
    </>
  );
}

export default IncorrectDetailsDistributionSection;
