import React from 'react';
import { ArrowRight } from 'react-feather';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import Dialog from '../common/Dialog';
import { DialogContent } from '../common/MaterialDialog';
import {
  TextRegular14,
  TextRegular18,
  TextBold28,
} from '../../VuexyDesign/atoms/Text';
import { Button, Col, Row } from '../../VuexyDesign/atoms/_index';
import { useIsDesktop, useIsMobile } from '../../style/constants';

function GrowthPlanValidationForReleaseDialog({
  onClose,
  isOpen,
}: GrowthPlanValidationForReleaseDialogProps) {
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const { t } = useTranslation('pricing');

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        style={{
          width: isDesktop ? '55rem' : '',
          overflowY: 'auto',
        }}
        className="p-0 m-0"
      >
        <div className="w-100 mx-auto mb-3 d-flex flex-column justify-content-center align-items-center text-center">
          <div className="d-flex justify-content-center">
            <img
              src="/static/images/payment/ultra-plan.svg"
              height="230"
              width="200"
              alt="upgrade your plan from growth plan"
            />
          </div>
          <TextBold28 className={`${isMobile ? 'px-1' : ''}`}>
            {t('pricing:upgradeNowUnlockDistribution') as string}{' '}
          </TextBold28>
          <TextRegular18 className={`mt-1 ${isMobile ? 'px-1' : ''}`}>
            {t('pricing:currentPlanDistributeOneSong') as string}
          </TextRegular18>

          <Row
            className={`${
              isMobile ? 'w-100 px-2' : 'w-75 px-1'
            }  my-1 text-center`}
          >
            <Col xs={12} lg={12} className="d-flex ">
              <TextRegular14>
                {t('pricing:upgradeNowToDistributeYourEntire') as string}
              </TextRegular14>
            </Col>
          </Row>
          <Link href="/become-pro">
            <Button
              color="success"
              className="d-flex justify-content-between mt-1"
            >
              <>
                {t('pricing:upgradePlanToday') as string}
                <ArrowRight className="ml-1" />
              </>
            </Button>
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default GrowthPlanValidationForReleaseDialog;

interface GrowthPlanValidationForReleaseDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
