import React from 'react';
import styled from 'styled-components';
import { X } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { Box } from '@material-ui/core';
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '../../common/MaterialDialog';
import { useIsDesktop } from '../../../style/constants';
import TrackDeliveryStatusDetails from '../../../VuexyDesign/molecules/TrackDeliveryStatusDetails';
import { Button } from '../../../VuexyDesign/atoms/_index';
import { TextBolder14, TextBolder24 } from '../../../VuexyDesign/atoms/Text';

interface ModalProps {
  visible?: boolean;
  trackStoreDeliveryStatusData: { name: string; status: string }[];
  onDismiss: () => void;
}

const StoreDeliveryStatusViewModal = ({
  visible = false,
  trackStoreDeliveryStatusData,
  onDismiss,
}: ModalProps) => {
  const { t } = useTranslation('myTracks');
  const isDesktop = useIsDesktop();

  const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: 'absolute',
      top: theme.spacing(2),
      right: isDesktop ? theme.spacing(-3) : theme.spacing(1),
    },
  }));
  /* ------------------------------ material dialog css customization ----------------------------- */
  const Dialog = withStyles((theme: Theme) => ({
    paper: {
      borderRadius: '10px',
      minHeight: 400,
      maxWidth: 850,
      backgroundColor: '#ffffff',
      padding: '0px 45px 15px 45px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 8px 15px 8px',
        width: `calc(100% - 12px)`,
        margin: 0,
      },
    },
  }))(MuiDialog);

  const classes = useStyles();
  return (
    <ModalWrapper>
      <Dialog open={visible} onClose={onDismiss} className="dialog-live-status">
        <Box position="relative">
          <X
            className={classes.closeButton}
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={onDismiss}
          />
        </Box>

        <DialogContent
          className="mt-3"
          style={{ overflowX: 'hidden', textAlign: 'center' }}
        >
          <div>
            <TextBolder24>
              {t('myTracks:storeDeliveryStatus.storeDeliveryStatus') as string}
            </TextBolder24>
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <TrackDeliveryStatusDetails
              trackStoreDeliveryStatusData={trackStoreDeliveryStatusData}
            />
          </div>
        </DialogContent>
        <DialogActions className="d-flex flex-column">
          <Button
            color="secondary"
            text="Close"
            onClick={onDismiss}
            outline
            className="text-dark"
          />

          <a
            target="_blank"
            href="https://virpp.zendesk.com/"
            rel="noreferrer"
            className="mt-1"
          >
            <TextBolder14
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'rgba(0, 0, 0, 0.87)',
                lineHeight: '18px',
              }}
              className="mt-1"
            >
              {t('myTracks:storeDeliveryStatus.learnMore') as string}
            </TextBolder14>
          </a>
        </DialogActions>
      </Dialog>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  .dialog-live-status {
    .MuiDialogTitle-root {
      margin: 0;
      padding: 28px 8px 0px 8px;
    }
  }
`;

export default StoreDeliveryStatusViewModal;
