import styled from 'styled-components';
import { large, mobile, tablet } from '../../style/constants';

export const PlayButton = styled.div`
  position: absolute;
  @media ${mobile} {
    margin: auto;
    margin-top: 13rem;
  }
  @media ${tablet} {
    margin-left: 30rem;
  }
  @media ${large} {
    margin-left: 35rem;
    margin-top: -5rem;
  }
`;
