import React, { useState } from 'react';
import { Label, Input } from 'reactstrap';
import useTranslation from 'next-translate/useTranslation';
import { Formik, Form, ErrorMessage, Field, FieldProps } from 'formik';
import { FormGroup } from '@mui/material';
import { Button, Col, Row } from '../atoms/_index';
import { API, useCurrentUser } from '../../api';
import VuexyCustomInput from '../atoms/CustomInput';
import { TextRegular16 } from '../atoms/Text';
import { TextRegular12 } from '../../components/common/Text';

interface PaymentDetailsContentProps {
  onSubmitHandler?: () => void;
}

function PaymentDetailsContent({
  onSubmitHandler,
}: PaymentDetailsContentProps) {
  const { t } = useTranslation();

  const { id } = useCurrentUser();
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      <div>
        <Formik
          initialValues={{
            amount: '',
            bank_name: '',
            bank_account_no: '',
            bank_code: '',
            paypal_address: '',
            type: 'bank',
            currency: 'dollar',
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            setSubmitting(true);

            try {
              const response = await API.statistics.requestWithdrawAmount(
                values.amount,
                values.bank_name,
                values.bank_account_no,
                values.bank_code,
                values.paypal_address,
                id,
                values.type,
                values.currency,
              );
              if (typeof response === 'object') {
                if (response === false) {
                  setErrors(response);
                }
              }
              if (onSubmitHandler) {
                onSubmitHandler();
              }
            } catch (error) {
              if (error.response && error.response.status) {
                const errorResponse = error.response.data;
                setErrors({
                  amount: errorResponse.data.amount,
                  bank_name: errorResponse.data.bank_name,
                  bank_account_no: errorResponse.data.bank_account_no,
                  bank_code: errorResponse.data.bank_code,
                  paypal_address: errorResponse.data.paypal_address,
                });
                setErrorMessage(errorResponse.data.message);
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div>
                <Row className="my-1">
                  <Col lg={6}>
                    <Field name="amount">
                      {({ field }: FieldProps) => (
                        <div>
                          <Label for={t('pricing:enterWithdrawAmount')}>
                            {t('pricing:enterWithdrawAmount')}
                          </Label>
                          <Input
                            {...field}
                            type="number"
                            name="amount"
                            placeholder={t('pricing:withdrawalAmount')}
                            className="text-dark cursor-pointer"
                            id="paymentAmount"
                          />
                          <TextRegular12 style={{ color: '#b9b9c3' }}>
                            {t('pricing:withdrawalbleBalance')}
                          </TextRegular12>
                          <div className="text-danger">{errorMessage}</div>
                          <ErrorMessage
                            name="amount"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      )}
                    </Field>
                  </Col>
                </Row>
                <div>
                  <Row className="my-1">
                    <Col lg={6}>
                      <label>
                        <Field
                          type="radio"
                          name="currency"
                          render={() => (
                            <FormGroup>
                              <div className="d-flex">
                                <Label className="d-flex" for="dollar">
                                  <VuexyCustomInput
                                    type="radio"
                                    id="dollar"
                                    checked={values.currency === 'dollar'}
                                    value="dollar"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      setFieldValue('currency', e.target.value);
                                    }}
                                  />
                                  <TextRegular16>$</TextRegular16>
                                </Label>
                                <Label className="d-flex ml-1" for="euro">
                                  <VuexyCustomInput
                                    type="radio"
                                    id="euro"
                                    checked={values.currency === 'euro'}
                                    value="euro"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      setFieldValue('currency', e.target.value);
                                    }}
                                  />
                                  <TextRegular16>€</TextRegular16>
                                </Label>
                              </div>
                            </FormGroup>
                          )}
                        />
                      </label>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row className="my-1">
                    <Col lg={6}>
                      <label>
                        <Field
                          type="radio"
                          name="type"
                          render={() => (
                            <FormGroup>
                              <div className="d-flex">
                                <Label className="d-flex" for="bank">
                                  <VuexyCustomInput
                                    type="radio"
                                    id="bank"
                                    checked={values.type === 'bank'}
                                    value="bank"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      setFieldValue('type', e.target.value);
                                    }}
                                  />
                                  <TextRegular16>
                                    {t('pricing:bankAccount')}
                                  </TextRegular16>
                                </Label>
                                <Label className="d-flex ml-1" for="bank">
                                  <VuexyCustomInput
                                    type="radio"
                                    id="paypal"
                                    checked={values.type === 'paypal'}
                                    value="paypal"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      setFieldValue('type', e.target.value);
                                    }}
                                  />
                                  <TextRegular16>
                                    {t('pricing:paypal')}
                                  </TextRegular16>
                                </Label>
                              </div>
                            </FormGroup>
                          )}
                        />
                      </label>
                    </Col>
                  </Row>
                </div>

                {values.type === 'bank' && (
                  <>
                    <Row className="my-1">
                      <Col lg={6}>
                        <Field name="bank_name">
                          {({ field }: FieldProps) => (
                            <div>
                              <Label for={t('pricing:bankName')}>
                                {t('pricing:bankName')}
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                name="bank_name"
                                placeholder={t('pricing:bankName')}
                                className="text-dark cursor-pointer"
                                id="bank_name"
                              />
                              <TextRegular12 style={{ color: '#b9b9c3' }}>
                                {t('pricing:enterNameOfBank')}
                              </TextRegular12>
                              <ErrorMessage
                                name="bank_name"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          )}
                        </Field>
                      </Col>
                    </Row>

                    <Row className="my-1">
                      <Col lg={6}>
                        <Field name="bank_account_no">
                          {({ field }: FieldProps) => (
                            <div>
                              <Label for={t('pricing:accountNumber')}>
                                {t('pricing:accountNumber')}
                              </Label>
                              <Input
                                {...field}
                                type="number"
                                name="bank_account_no"
                                placeholder={t('pricing:accountNumber')}
                                className="text-dark cursor-pointer"
                                id="bank_account_no"
                              />
                              <TextRegular12 style={{ color: '#b9b9c3' }}>
                                {t('pricing:provideBankAccountNumber')}
                              </TextRegular12>
                              <ErrorMessage
                                name="bank_account_no"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col lg={6}>
                        <Field name="bank_code">
                          {({ field }: FieldProps) => (
                            <div>
                              <Label for={t('pricing:bicSwift')}>
                                {t('pricing:bicSwift')}
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                name="bank_code"
                                placeholder={t('pricing:bicSwift')}
                                className="text-dark cursor-pointer"
                                id="bank_code"
                              />
                              <TextRegular12 style={{ color: '#b9b9c3' }}>
                                {t('pricing:provideBicSwift')}
                              </TextRegular12>
                              <ErrorMessage
                                name="bank_code"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </>
                )}
                {values.type === 'paypal' && (
                  <>
                    <Row className="my-1">
                      <Col lg={6}>
                        <Field name="paypal_address">
                          {({ field }: FieldProps) => (
                            <div>
                              <Label for={t('pricing:paypalEmailId')}>
                                {t('pricing:paypalEmailId')}
                              </Label>
                              <Input
                                {...field}
                                type="email"
                                name="paypal_address"
                                placeholder={t('pricing:paypalEmailId')}
                                className="text-dark cursor-pointer"
                                id="paypal_address"
                              />
                              <TextRegular12 style={{ color: '#b9b9c3' }}>
                                {t('pricing:enterPaypalMail')}
                              </TextRegular12>
                              <ErrorMessage
                                name="paypal_address"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              <Button
                type="submit"
                className="mt-1"
                color="info"
                disabled={isSubmitting}
              >
                <>{t('pricing:sendRequest')}</>
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default PaymentDetailsContent;
