import React, { useEffect, useState } from 'react';
import {
  MdAttachMoney,
  MdCheck,
  MdFlag,
  MdImage,
  MdLibraryMusic,
  MdPerson,
  MdShoppingCart,
  MdWarning,
} from 'react-icons/md';
import useTranslation from 'next-translate/useTranslation';
import {
  ArtistDetails,
  MusicStores,
  PaymentInfo,
  TrackDetails,
} from '../../api';
import { TextBold14 } from '../atoms/Text';
import { Desktop } from '../../style/constants';

function StepperHeader({
  step,
  stepId,
  handleClick,
  currentStepId,
  activeStep,
  isIncorrect,
}: StepperHeaderProps) {
  const [currentStep, setCurrentStep] = useState(step);
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation('organisms');

  const getTitle = () => {
    switch (stepId) {
      case 'artist_details':
        return 'organisms:stepperHeader.artistDetails';
      case 'track_details':
        return 'organisms:stepperHeader.trackDetails';
      case 'contributors':
        return 'organisms:stepperHeader.contributors';
      case 'album_art':
        return 'organisms:stepperHeader.albumArt';
      case 'music_stores':
        return 'organisms:stepperHeader.musicStores';
      case 'payment_setup':
        return 'organisms:stepperHeader.paymentSetup';
      case 'finish':
        return 'organisms:stepperHeader.finish';
      default:
        return '';
    }
  };

  const getIcon = () => {
    switch (stepId) {
      case 'artist_details':
        return (
          <MdPerson
            size={18}
            color={
              !currentStep.completed && currentStepId !== stepId
                ? 'black'
                : 'white'
            }
          />
        );
      case 'track_details':
        return (
          <MdLibraryMusic
            size={18}
            color={
              !currentStep.completed && currentStepId !== stepId
                ? 'black'
                : 'white'
            }
          />
        );
      case 'contributors':
        return (
          <MdPerson
            size={18}
            color={
              !currentStep.completed && currentStepId !== stepId
                ? 'black'
                : 'white'
            }
          />
        );
      case 'album_art':
        return (
          <MdImage
            size={18}
            color={
              !currentStep.completed && currentStepId !== stepId
                ? 'black'
                : 'white'
            }
          />
        );
      case 'music_stores':
        return (
          <MdShoppingCart
            size={18}
            color={
              !currentStep.completed && currentStepId !== stepId
                ? 'black'
                : 'white'
            }
          />
        );
      case 'payment_setup':
        return (
          <MdAttachMoney
            size={18}
            color={
              !currentStep.completed && currentStepId !== stepId
                ? 'black'
                : 'white'
            }
          />
        );
      case 'finish':
        return (
          <MdFlag
            size={18}
            color={
              !currentStep.completed && currentStepId !== stepId
                ? 'black'
                : 'white'
            }
          />
        );
      default:
        return <></>;
    }
  };

  const getIconStyle = () => {
    const style = {
      width: '35px',
      height: '35px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '',
      disabled: false,
    };
    if (isIncorrect) {
      style.backgroundColor = '#F2AD0D';
    } else if (currentStepId === stepId) {
      style.backgroundColor = '#F71454';
    } else if (currentStep.completed) {
      style.backgroundColor = '#67C639';
    } else {
      style.backgroundColor = '#F2F2F2';
    }

    return style;
  };

  const getOwnStyle = () => {
    const style = {
      backgroundColor: '',
      cursor: 'pointer',
      color: '',
    };
    if (isIncorrect && currentStepId === stepId) {
      style.backgroundColor = '#F2F2F2';
    } else if (isIncorrect) {
      style.backgroundColor = isHovering ? '#F2F2F2' : '';
    } else if (currentStepId === stepId) {
      style.backgroundColor = '#FFF5F8';
      style.cursor = 'pointer';
    } else if (currentStep.completed) {
      style.backgroundColor = isHovering ? '#E1F4D7' : '';
    } else if (stepId === activeStep) {
      style.cursor = 'pointer';
    } else {
      style.color = '#b9b9c3';
      style.cursor = 'default';
    }

    return style;
  };

  const getVerificationIcon = () => {
    if (isIncorrect) {
      return <MdWarning size={24} color="#F2AD0D" />;
    }
    if (currentStep.completed) {
      return (
        <Desktop>
          <MdCheck size={24} color="#67C639" stroke="8" />
        </Desktop>
      );
    }

    return <></>;
  };

  const [iconStyle, setIconStyle] = useState<Record<string, unknown>>(() =>
    getIconStyle(),
  );
  const [ownStyle, setOwnStyle] = useState<Record<string, unknown>>(() =>
    getOwnStyle(),
  );
  const [verificationIcon, setVerificationIcon] = useState<JSX.Element>(() =>
    getVerificationIcon(),
  );

  useEffect(() => {
    setCurrentStep(step);
    setIconStyle(() => getIconStyle());
    setOwnStyle(() => getOwnStyle());
    setVerificationIcon(() => getVerificationIcon());
  }, [currentStepId]);

  useEffect(() => {
    setIconStyle(() => getIconStyle());
    setOwnStyle(() => getOwnStyle());
    setVerificationIcon(() => getVerificationIcon());
  }, [isHovering, currentStep]);

  return (
    <div
      onClick={handleClick}
      style={ownStyle}
      className="d-flex align-items-center w-100 my-2 p-1"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        style={iconStyle}
        className="d-inline-flex justify-content-center align-items-center"
      >
        {getIcon()}
      </div>
      <Desktop>
        <TextBold14 className="mx-1">{t(getTitle())}</TextBold14>
      </Desktop>
      {verificationIcon}
    </div>
  );
}

export default StepperHeader;

interface StepperHeaderProps {
  step: Step;
  stepId: string;
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  currentStepId: string;
  activeStep?: string;
  isIncorrect?: boolean;
}

type Step = ArtistDetails | TrackDetails | MusicStores | PaymentInfo;
