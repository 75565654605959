/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export interface ConnectDropdownProps {
  label: string;
  allValues: any[];
  setSelectedValues: (selectedOptions: any) => void;
}

const animatedComponents = makeAnimated();

const ConnectDropdown = ({
  label,
  allValues,
  setSelectedValues,
}: ConnectDropdownProps) => (
  <>
    <Select
      closeMenuOnSelect={false}
      closeMenuOnScroll
      hideSelectedOptions={false}
      components={animatedComponents}
      onChange={(selectedValue) => setSelectedValues(selectedValue)}
      placeholder={label}
      isMulti
      options={allValues}
      className="react-select"
      classNamePrefix="select"
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        borderColor: '#f71454',
        colors: {
          ...theme.colors,
          primary75: '#fc5383',
          primary50: '#fc5383',
          primary25: '#fc5383',
          primary: '#f71454',
        },
      })}
    />
  </>
);
export default ConnectDropdown;
