import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { IncorrectDetails } from '../../api';
import { TextBold18 } from '../atoms/Text';
import { Col } from '../atoms/_index';
import ArtistDetails from './ArtistDetails';
import { MainFlowIncorrectDetails } from './_index';

interface FinishPageContentProps {
  header?: string;
  trackId: number;
  isIncorrect?: boolean;
  incorrectDetails?: IncorrectDetails;
}

function FinishPageContent({
  header,
  trackId,
  isIncorrect,
  incorrectDetails,
}: FinishPageContentProps) {
  const { t } = useTranslation('artist');
  return (
    <div>
      <div className="d-flex flex-column">
        <Col className="d-flex justify-content-between mt-2 p-0">
          <TextBold18>{header}</TextBold18>
        </Col>
        {isIncorrect && incorrectDetails ? (
          <MainFlowIncorrectDetails
            incorrectDetails={incorrectDetails}
            step="finish"
          />
        ) : (
          <></>
        )}
        <TextBold18 className="mt-1">{t('artist:createdRelease')}</TextBold18>
      </div>
      <div>
        <ArtistDetails trackId={trackId} />
      </div>
    </div>
  );
}

export default FinishPageContent;
