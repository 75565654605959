import React from 'react';
import styled from 'styled-components';
import { TextBolder22 } from '../atoms/Text';
import { Badge } from '../atoms/_index';

export interface DistributionLandingWorksCardProps {
  title: string;
  subtitle: string;
  isActive: boolean;
  isCompleted: boolean;
  index: number;
  icon: React.ReactNode;
  button?: React.ReactNode;
}

function DistributionLandingWorksCard({
  title,
  subtitle,
  isActive,
  isCompleted,
  index,
  icon,
  button,
}: DistributionLandingWorksCardProps) {
  return (
    <div
      className={`py-2 px-2 h-100 d-flex flex-column justify-content-center align-items-center text-center rounded  ${
        isActive && !isCompleted
          ? 'border border-success bg-light'
          : 'border border-secondary'
      } `}
    >
      <Index size={40}>
        <Badge
          text={`Step ${index}`}
          color={isActive && !isCompleted ? 'success' : 'light'}
          className={isActive && !isCompleted ? 'text-white' : 'text-dark'}
          style={{ fontSize: '16px' }}
        />
      </Index>
      {icon}

      <div className="mt-2">
        <TextBolder22>{title}</TextBolder22>
        {isActive ? (
          <div className="mt-1 mb-0">{button}</div>
        ) : (
          <p className="text-secondary mt-1 mb-0">{subtitle}</p>
        )}
      </div>
    </div>
  );
}

export default DistributionLandingWorksCard;

const Index = styled.div<{ size: number }>`
  position: absolute;
  z-index: 10;
  top: ${(p) => `-${p.size / 2}px`};
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`;
