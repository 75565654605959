import React, { useEffect, useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import useTranslation from 'next-translate/useTranslation';
import { API, FugaLanguage, IncorrectDetails } from '../../api';
import {
  TextBold18,
  TextBolder14,
  TextRegular12,
  TextRegular14,
} from '../atoms/Text';
import {
  Col,
  Container,
  Dropdown,
  Row,
  Button,
  ProgressBar,
} from '../atoms/_index';
import { ChunkUploader, MainFlowIncorrectDetails } from '../molecules/_index';
import Loader from '../../components/common/Loader';

const explicitOptions: Option[] = [
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' },
];

function MainFlowTrackDetails({
  nextStepHandler,
  trackId,
  updatePreview,
  isIncorrect,
}: MainFlowTrackDetailsProps) {
  const { t } = useTranslation('organisms');
  const [mainGenres, setMainGenres] = useState<Option[]>([]);
  const [believeGenres, setBelieveGenres] = useState<BelieveGenre[]>([]);
  const [fugaLanguagesLyrics, setFugaLanguagesLyrics] = useState<Option[]>([]);
  const [fugaLanguagesMeta, setFugaLanguagesMeta] = useState<Option[]>([]);
  const [mainSubgenreOptions, setMainSubgenreOptions] = useState<Option[]>([]);
  const [secondarySubgenreOptions, setSecondarySubgenreOptions] = useState<
    Option[]
  >([]);

  const [incorrectDetails, setIncorrectDetails] = useState<IncorrectDetails>();

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [error, setError] = useState('');
  const [submitDisable, setSubmitDisable] = useState(true);

  const [title, setTitle] = useState<string>('');
  const [mainGenre, setMainGenre] = useState<Option>();
  const [mainSubgenre, setMainSubgenre] = useState<Option>();
  const [productionYear, setProductionYear] = useState<string>('');
  const [metaDataLanguage, setMetaDataLanguage] = useState<Option>();
  const [lyricsLanguage, setLyricsLanguage] = useState<Option>();
  const [explicitLyrics, setExplicitLyrics] = useState<Option>();
  const [price, setPrice] = useState<Option>();
  const [secondaryGenre, setSecondaryGenre] = useState<Option>();
  const [secondarySubgenre, setSecondarySubgenre] = useState<Option>();
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [progressLoader, setProgressLoader] = useState(false);
  const [masterTitle, setMasterTitle] = useState('');
  const [uploadError, setUploadError] = useState('');

  useEffect(() => {
    const getBelieveGenres = async () => {
      const genres: BelieveGenre[] = await API.trackReleases
        .getTrackGenres()
        .then((res) => res.data);
      setBelieveGenres(genres);
      setMainGenres(
        genres
          .filter(
            (genre) =>
              genre.parent_id === undefined || genre.parent_id === null,
          )
          .map((genre) => ({ label: genre.name, value: genre.id })),
      );
    };
    getBelieveGenres();

    API.trackReleases.getLanguages(true).then((resp) =>
      setFugaLanguagesLyrics(
        resp.data.map((lang: FugaLanguage) => ({
          value: lang.id,
          label: lang.name,
        })),
      ),
    );

    API.trackReleases.getLanguages().then((resp) =>
      setFugaLanguagesMeta(
        resp.data.map((lang: FugaLanguage) => ({
          value: lang.id,
          label: lang.name,
        })),
      ),
    );
  }, []);

  // Once the genres are loaded get track release to check if it exists and pre-fill data
  useEffect(() => {
    if (mainGenres && fugaLanguagesMeta && fugaLanguagesLyrics)
      API.trackReleases.getTrackRelease(trackId).then((response) => {
        setIncorrectDetails(response.data.incorrect_details);

        setMasterTitle(response.data.track_details.master_file || '');
        if (response.data.track_details.completed) {
          const data = response.data.track_details;

          const _mainSubgenres: Option[] = data.main_genre_id
            ? believeGenres
                .filter((genre) => genre.parent_id === data.main_genre_id)
                .map((genre) => ({ label: genre.name, value: genre.id }))
            : [];

          const _secondarySubgenres: Option[] = data.secondary_genre_id
            ? believeGenres
                .filter((genre) => genre.parent_id === data.secondary_genre_id)
                .map((genre) => ({ label: genre.name, value: genre.id }))
            : [];

          setTitle(data.release_title);
          setProductionYear(data.production_year.toString());

          const _mainGenre = mainGenres.find(
            (item) => item.value === data.main_genre_id,
          );
          const _mainSubgenre = _mainSubgenres.find(
            (item) => item.value === data.main_sub_genre_id,
          );

          const _secondaryGenre = mainGenres.find(
            (item) => item.value === data.secondary_genre_id,
          );
          const _secondarySubgenre = _secondarySubgenres.find(
            (item) => item.value === data.secondary_sub_genre_id,
          );

          const _metaDataLanguage = fugaLanguagesMeta.find(
            (item) => item.value === data.meta_data_language.toUpperCase(),
          );
          const _lyricsLanguage = fugaLanguagesLyrics.find(
            (item) => item.value === data.lyrics_language.toUpperCase(),
          );

          const _explicitLyrics = explicitOptions.find(
            (item) => item.value === data.explicit,
          );

          const _price = allPrices.find((item) => item.value === data.price);

          setMainGenre(_mainGenre);
          setMainSubgenre(_mainSubgenre);
          setMetaDataLanguage(_metaDataLanguage);
          setLyricsLanguage(_lyricsLanguage);
          setExplicitLyrics(_explicitLyrics);
          setPrice(_price);

          setSecondaryGenre(_secondaryGenre);
          setSecondarySubgenre(_secondarySubgenre);

          setMainSubgenreOptions(_mainSubgenres);
          setSecondarySubgenreOptions(_secondarySubgenres);
        }
      });
  }, [mainGenres, fugaLanguagesMeta, fugaLanguagesLyrics]);

  const handleMainGenreChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    if (!selected || Array.isArray(selected)) {
      setMainGenre(undefined);
      return;
    }

    const newValue = typeof selected.value === 'number' ? selected : undefined;
    setMainGenre(newValue);

    const subgenres: Option[] = believeGenres
      .filter((genre) => genre.parent_id === selected?.value)
      .map((genre) => ({ label: genre.name, value: genre.id }));
    setMainSubgenreOptions(subgenres);
  };

  useEffect(() => {
    if (mainGenre === undefined) {
      setMainSubgenre(undefined);
      setMainSubgenreOptions([]);
    }
  }, [mainGenre]);

  useEffect(() => {}, [mainSubgenre]);

  const handleMainSubgenreChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    const newValue =
      selected && !Array.isArray(selected) && typeof selected.value === 'number'
        ? selected
        : undefined;

    setMainSubgenre(newValue);
  };

  const handleMetaDataLanguageChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    const newValue =
      selected && !Array.isArray(selected) && typeof selected.value === 'string'
        ? selected
        : undefined;

    setMetaDataLanguage(newValue);
  };

  const handleLyricsLanguageChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    const newValue =
      selected && !Array.isArray(selected) && typeof selected.value === 'string'
        ? selected
        : undefined;

    setLyricsLanguage(newValue);
  };

  const handlePriceChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    const newValue =
      selected && !Array.isArray(selected) && typeof selected.value === 'string'
        ? selected
        : undefined;

    setPrice(newValue);
  };

  const handleSecondaryGenreChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    if (!selected || Array.isArray(selected)) {
      setSecondaryGenre(undefined);
      handleSecondarySubgenreChange(null);
      setSecondarySubgenreOptions([]);
      return;
    }

    const newValue = typeof selected.value === 'number' ? selected : undefined;

    setSecondaryGenre(newValue);
    setSecondarySubgenre(undefined);

    const subgenres: Option[] = believeGenres
      .filter((genre) => genre.parent_id === selected?.value)
      .map((genre) => ({ label: genre.name, value: genre.id }));
    setSecondarySubgenreOptions(subgenres);
  };

  const handleSecondarySubgenreChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    const newValue =
      selected && !Array.isArray(selected) && typeof selected.value === 'number'
        ? selected
        : undefined;

    setSecondarySubgenre(newValue);
  };

  useEffect(() => {
    const isValid =
      (title !== '' &&
        title &&
        mainGenre &&
        mainSubgenre &&
        productionYear !== '' &&
        productionYear &&
        metaDataLanguage &&
        lyricsLanguage &&
        price &&
        explicitLyrics &&
        masterTitle !== '' &&
        uploadError === '') ||
      false; // failsafe if all fields are undefined
    setSubmitDisable(!isValid);
  }, [
    title,
    mainGenre,
    mainSubgenre,
    productionYear,
    metaDataLanguage,
    lyricsLanguage,
    price,
    secondaryGenre,
    secondarySubgenre,
    masterTitle,
    uploadError,
    progressBarValue,
    explicitLyrics,
  ]);

  const handleExplicitLyricsChange = (
    selected: Option | Option[] | undefined | null,
  ) => {
    const newValue =
      selected && !Array.isArray(selected) ? selected : undefined;

    setExplicitLyrics(newValue);
  };

  const handleSave = async () => {
    if (!submitDisable) {
      const response = await API.trackReleases.patchTrackDetails(
        {
          release_title: title,
          main_genre_id: Number(mainGenre?.value),
          main_sub_genre_id: Number(mainSubgenre?.value),
          secondary_genre_id: Number(secondaryGenre?.value),
          secondary_sub_genre_id: Number(secondarySubgenre?.value),
          production_year: Number(productionYear),
          meta_data_language:
            metaDataLanguage?.value.toString().toUpperCase() || '',
          lyrics_language: lyricsLanguage?.value.toString().toUpperCase() || '',
          explicit: String(explicitLyrics?.value),
          price: price?.value.toString() || '',
        },
        trackId,
      );

      if (response.success) {
        setError('');
        updatePreview(true);
        nextStepHandler();
      } else {
        setSubmitDisable(true);
        setError(response.message);
      }
    }
  };

  return (
    <Container className="mb-4 px-0">
      <Row className="d-flex justify-content-between">
        <Col className="d-flex justify-content-between mt-2">
          <TextBold18>{t('organisms:trackDetails')}</TextBold18>
        </Col>
      </Row>
      {isIncorrect && incorrectDetails ? (
        <MainFlowIncorrectDetails
          incorrectDetails={incorrectDetails}
          step="track_details"
        />
      ) : (
        <></>
      )}
      <div className="d-flex flex-column mt-1">
        <FormGroup>
          <TextRegular14>{t('organisms:trackTitle')}</TextRegular14>
          <Input
            type="text"
            name="name"
            id="exampleName"
            className="text-dark"
            defaultValue={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTitle(e.target.value)
            }
          />
          <TextRegular12 className="text-muted">
            {t('organisms:writeFullTrackName')}
          </TextRegular12>
        </FormGroup>
      </div>
      <Row className="mb-1">
        <Col xs={12}>
          <TextRegular14>{t('organisms:masterAudioFile')}</TextRegular14>
          <ChunkUploader
            trackId={trackId}
            setError={setUploadError}
            setProgressLoader={setProgressLoader}
            setProgressPercentage={setProgressBarValue}
            title={masterTitle}
            setMasterTitle={setMasterTitle}
          />
          {progressBarValue !== 0 &&
          uploadError === '' &&
          progressLoader === false ? (
            <div style={{ marginTop: '5px' }}>
              <ProgressBar
                value={progressBarValue}
                color={progressBarValue > 99.9 ? 'success' : 'primary'}
                striped={progressBarValue < 99.9}
                animated={progressBarValue < 99.9}
              />
            </div>
          ) : (
            <></>
          )}
          {progressLoader === true && uploadError === '' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0 auto',
              }}
            >
              <Loader />
            </div>
          ) : (
            <></>
          )}
          <TextRegular12 className="text-muted">
            {t('organisms:needsAStereo')}
          </TextRegular12>

          {uploadError !== '' ? (
            <TextBolder14 className="d-block text-danger">
              {uploadError}
            </TextBolder14>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={6} style={{ zIndex: 12 }} className="z-1">
          <TextRegular14>{t('organisms:mainGenre')}</TextRegular14>
          <Dropdown
            placeholder={t('organisms:selectOption') as string}
            options={mainGenres}
            onChange={handleMainGenreChange}
            closeMenuOnSelect
            isClearable
            value={mainGenre}
          />
        </Col>
        <Col lg={6} style={{ zIndex: 11 }}>
          <TextRegular14>{t('organisms:subGenre')}</TextRegular14>
          <Dropdown
            placeholder={t('organisms:selectOption') as string}
            options={mainSubgenreOptions}
            onChange={handleMainSubgenreChange}
            closeMenuOnSelect
            isClearable
            value={mainSubgenre || null}
          />
        </Col>
      </Row>
      <div className="d-flex flex-column mt-1">
        <FormGroup>
          <TextRegular14>{t('organisms:productionYear')}</TextRegular14>
          <Input
            type="text"
            name="name"
            id="exampleName"
            placeholder="eg. 2020"
            className="text-dark"
            defaultValue={productionYear}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProductionYear(e.target.value);
            }}
          />
          <TextRegular12 className="text-muted">
            {t('organisms:enterProductionYear')}
          </TextRegular12>
        </FormGroup>
      </div>
      <Row>
        <Col lg={6} style={{ zIndex: 10 }}>
          <TextRegular14>{t('organisms:trackMetaDataLanguage')}</TextRegular14>
          <Dropdown
            placeholder={t('organisms:selectOption') as string}
            options={fugaLanguagesMeta.filter((lang) => lang.value !== 'ZXX')}
            onChange={handleMetaDataLanguageChange}
            closeMenuOnSelect
            isClearable
            value={metaDataLanguage}
          />
        </Col>
        <Col lg={6} style={{ zIndex: 9 }}>
          <TextRegular14>{t('organisms:lyricsLanguage')}</TextRegular14>
          <Dropdown
            placeholder={t('organisms:selectOption') as string}
            options={fugaLanguagesLyrics}
            onChange={handleLyricsLanguageChange}
            closeMenuOnSelect
            isClearable
            value={lyricsLanguage}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={6} style={{ zIndex: 8 }}>
          <TextRegular14>
            {t('organisms:thisSongHasExplicitLyrics')}
          </TextRegular14>
          <Dropdown
            options={explicitOptions}
            onChange={handleExplicitLyricsChange}
            isClearable
            closeMenuOnSelect
            value={explicitLyrics}
          />
        </Col>
      </Row>

      <Row className="mt-1">
        <Col lg={6} style={{ zIndex: 7 }}>
          <TextRegular14>{t('organisms:trackPrice')}</TextRegular14>
          <Dropdown
            placeholder={t('organisms:selectOption') as string}
            options={allPrices}
            onChange={handlePriceChange}
            closeMenuOnSelect
            isClearable
            value={price}
          />
        </Col>
      </Row>

      <Row className="mt-1">
        <Col>
          {showAdvancedOptions ? (
            <TextRegular14
              style={{
                textDecoration: 'underline',
                color: '#999999',
                cursor: 'pointer',
              }}
              onClick={() => setShowAdvancedOptions(false)}
            >
              {t('organisms:hideAdvanceOptions')}
            </TextRegular14>
          ) : (
            <TextRegular14
              style={{
                textDecoration: 'underline',
                color: '#999999',
                cursor: 'pointer',
              }}
              onClick={() => setShowAdvancedOptions(true)}
            >
              {t('organisms:showAdvanceOptions')}
            </TextRegular14>
          )}
        </Col>
      </Row>

      {showAdvancedOptions ? (
        <Row className="mt-1">
          <Col lg={6} style={{ zIndex: 6 }}>
            <TextRegular14>{t('organisms:secondaryGenre')}</TextRegular14>
            <Dropdown
              placeholder={t('organisms:selectOption') as string}
              options={mainGenres}
              onChange={handleSecondaryGenreChange}
              closeMenuOnSelect
              isClearable
              value={secondaryGenre}
            />
          </Col>
          <Col lg={6} style={{ zIndex: 5 }}>
            <TextRegular14>{t('organisms:secondarySubGenre')}</TextRegular14>
            <Dropdown
              placeholder={t('organisms:selectOption') as string}
              options={secondarySubgenreOptions}
              onChange={handleSecondarySubgenreChange}
              closeMenuOnSelect
              isClearable
              value={secondarySubgenre || null}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <div className="d-flex flex-column mt-1">
        <TextBolder14>{t('organisms:note')}</TextBolder14>
        <ul>
          <li>
            <TextRegular14>{t('organisms:categorisedAsASingle')}</TextRegular14>
          </li>
          <li>
            <TextRegular14>{t('organisms:newUpcEanCode')}</TextRegular14>
          </li>
          <li>
            <TextRegular14>{t('organisms:newIsrcNumber')}</TextRegular14>
          </li>
        </ul>
      </div>

      {error !== '' ? (
        <div className="mt-2 mb-1 text-danger">
          <TextBolder14>{t('organisms:correction')}</TextBolder14>
          <ul>
            <li>
              <TextRegular14>{error}</TextRegular14>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}

      <Button
        className="mt-2"
        color="success"
        style={{
          cursor: `${submitDisable ? 'default' : 'pointer'}`,
          borderRadius: '2px',
        }}
        onClick={handleSave}
        disabled={submitDisable}
      >
        {progressBarValue === 0 || progressBarValue > 99.9
          ? (t('organisms:saveAndProceed') as string)
          : `${t('organisms:uploading')} ${progressBarValue}%`}
      </Button>
    </Container>
  );
}

export default MainFlowTrackDetails;

interface MainFlowTrackDetailsProps {
  nextStepHandler: () => void;
  trackId: number;
  updatePreview: (val: boolean) => void;
  isIncorrect?: boolean;
}

interface BelieveGenre {
  id: number;
  name: string;
  parent_id?: number;
}

interface Option {
  value: string | number;
  label: string;
}

const allPrices: Option[] = [
  { value: 'BACK', label: '0.79' },
  { value: 'MID', label: '0.99' },
  { value: 'FRONT', label: '1.29' },
];
