import React, { useEffect, useState } from 'react';
import { AiOutlineUpload } from 'react-icons/ai';
import useTranslation from 'next-translate/useTranslation';
import { API, IncorrectDetails } from '../../api';
import Container from '../atoms/Container';
import Row from '../atoms/Row';
import Col from '../atoms/Col';
import {
  TextBolder14,
  TextBold18,
  TextRegular12,
  TextBold14,
} from '../atoms/Text';
import VuexyButton from '../atoms/Button';
import Uploadable from '../../components/common/Uploadable';
import { MainFlowIncorrectDetails } from '../molecules/_index';

function MainFlowAlbumDetails({
  trackId,
  nextStepHandler,
  updatePreview,
  isIncorrect,
}: MainFlowAlbumDetailsProps) {
  const { t } = useTranslation('organisms');
  const [imgSrc, setImgSrc] = useState<string | null>();
  const [error, setError] = useState<string[]>([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [incorrectDetails, setIncorrectDetails] = useState<IncorrectDetails>();

  useEffect(() => {
    API.trackReleases.getTrackRelease(trackId).then((response) => {
      if (response.data.track_details.completed) {
        const data = response.data.album_art;
        setImgSrc(data.artwork);
      }
      setIncorrectDetails(response?.data.incorrect_details);
    });
  }, []);

  useEffect(() => {
    if (imgSrc && imgSrc !== '') {
      setDisableSubmit(false);
    }
  }, [imgSrc]);

  const handleSave = async () => {
    if (disableSubmit) {
      return;
    }

    nextStepHandler();
  };

  return (
    <Container className="mb-4 px-0">
      <Row className="d-flex justify-content-between">
        <Col className="d-flex justify-content-between mt-2">
          <TextBold18>{t('organisms:albumDetails')}</TextBold18>
        </Col>
      </Row>
      {isIncorrect && incorrectDetails ? (
        <MainFlowIncorrectDetails
          incorrectDetails={incorrectDetails}
          step="album_art"
        />
      ) : (
        <></>
      )}
      <Row className="mt-2 mb-2 mx-0">
        <div
          className="p-0"
          style={{
            cursor: 'pointer',
            border: '2px dashed #CCCCCC',
            height: '200px',
            width: '200px',
            borderRadius: '4px',
          }}
        >
          <Uploadable
            onUpload={async (file) => {
              if (file) {
                const data = new FormData();
                data.append('artwork', file);
                const response = await API.trackReleases.postArtwork(
                  trackId,
                  data,
                );
                if (response.success) {
                  updatePreview(true);
                  setError([]);
                  setImgSrc(response.data.album_art.artwork);
                } else {
                  const errorMessage =
                    response?.data && response?.data?.artwork
                      ? response?.data.artwork
                      : [response.message];
                  setError(errorMessage);
                }
              }
            }}
            className="w-100 h-100 d-block-flex justify-content-center align-items-center"
          >
            {!imgSrc ? (
              <>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <span className="border border-dark p-1">
                    <AiOutlineUpload width={20} height={20} />
                    <TextBolder14 style={{ marginLeft: '5px' }}>
                      {t('organisms:uploadImage')}
                    </TextBolder14>
                  </span>
                </div>
              </>
            ) : (
              <img src={imgSrc} width="100%" height="100%" alt="Album art" />
            )}
          </Uploadable>
        </div>
      </Row>

      <div className="d-flex flex-column">
        {error && error.length > 0 ? (
          <>
            {error.map((item) => (
              <TextBold14 className="text-danger">{item}</TextBold14>
            ))}
          </>
        ) : (
          <></>
        )}

        <TextRegular12>{t('organisms:jpg')}</TextRegular12>
        <TextRegular12>{t('organisms:dontIncreaseSize')}</TextRegular12>
        <TextRegular12>{t('organisms:storesWillRejectArtwork')}</TextRegular12>
        <TextRegular12>{t('organisms:youOwnThisArtwork')}</TextRegular12>
      </div>
      <VuexyButton
        className="mt-3 mb-4"
        color="success"
        disabled={disableSubmit}
        style={{
          cursor: `${disableSubmit ? 'defaut' : 'cursor'}`,
          borderRadius: '2px',
        }}
        onClick={handleSave}
      >
        {t('organisms:saveAndProceed') as string}
      </VuexyButton>
    </Container>
  );
}

export default MainFlowAlbumDetails;

interface MainFlowAlbumDetailsProps {
  nextStepHandler: () => void;
  trackId: number;
  updatePreview: (val: boolean) => void;
  isIncorrect?: boolean;
}
