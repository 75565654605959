import React from 'react';
import styled from 'styled-components';

import useTranslation from 'next-translate/useTranslation';
import { Track, style } from '../../../api';
import { TextRegular14 } from '../../common/Text';
import { FlatButton, FlatButtonWhite } from '../../common/Buttons';
import { mobile, desktop } from '../../../style/constants';
import Dialog from '../../common/Dialog';

interface ModalProps {
  visible?: boolean;
  track: Track;
  onDismiss: () => void;
  onConfirm: () => void;
}

const FlatButtonDelete = styled(FlatButton)`
  color: #fff;
  background-color: ${style.error_new};
`;

const DeleteModal = ({
  visible = false,
  track,
  onDismiss,
  onConfirm,
}: ModalProps) => {
  const { t } = useTranslation('myTracks');

  return (
    <Dialog open={visible} onClose={onDismiss}>
      <Container>
        <div className="delete-content">
          <TextRegular14>
            {t('myTracks:deleteTrack') as string} <br />
            {t('myTracks:yourTrack') as string}{' '}
            <strong>“{track?.title}”</strong>
          </TextRegular14>
        </div>
        <div className="actions_container">
          <FlatButtonWhite onClick={onDismiss}>
            {t('myTracks:noCancel') as string}
          </FlatButtonWhite>
          <FlatButtonDelete onClick={onConfirm}>
            {t('myTracks:yesDelete') as string}
          </FlatButtonDelete>
        </div>
      </Container>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0px 42px 25px 42px;

  .delete-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 28px;
    @media ${mobile} {
      margin-bottom: 42px;
    }
  }

  .actions_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    button {
      flex: initial;
    }

    @media ${desktop} {
      button:first-of-type {
        margin-right: 42px;
      }
    }

    @media ${mobile} {
      button {
        flex: 0 1 100%;
      }

      button:first-of-type {
        margin: 0 0 12px 0;
      }
    }
  }
`;

export default DeleteModal;
