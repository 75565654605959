import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdOutlineFileUpload } from 'react-icons/md';
import { API, style } from '../../api';
import { TextRegular16 } from '../atoms/Text';

const acceptedFileTypes = [
  // 'audio/mp3', // just for testing
  'audio/flac',
  'audio/wav',
  '.wav',
];

const fileInputAccept = acceptedFileTypes.join(',');

function ChunkUploader({
  setError,
  trackId,
  setProgressPercentage,
  setProgressLoader,
  title,
  setMasterTitle,
}: ChunkUploaderProps) {
  const [file, setFile] = useState<File | null>(null);
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [oneChunkPercentage, setOneChunkPercentage] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uuid, setUuid] = useState<string | null>(null);

  const onDrop = ({ masterFile }: OnDropProps) => {
    if (masterFile) {
      setFile(masterFile);
    }
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    setTotalProgress(0);
    setProgressPercentage(0);
    setMasterTitle('');

    const newUuid = Date.now().toString();

    setUuid(newUuid);
    setIsUploading(true);

    if (file.size > 100_000_000) {
      const size = 2_500_000;
      const chunksNum = Math.ceil(file.size / size);

      setOneChunkPercentage(100 / chunksNum);

      for (let i = 0; i < chunksNum; i += 1) {
        const chunk = file.slice(
          i * size,
          Math.min(i * size + size, file.size),
          file.type,
        );

        setChunks((prev) => [...prev, chunk]);
      }
    } else {
      const blobFile = file.slice(0, file.size, file.type);

      const body = new FormData();
      body.append('file', blobFile, `${file?.name}`);
      body.append('id', `${uuid}`);
      body.append('is_complete_file', 'true');

      setProgressLoader(true);

      API.trackReleases
        .uploadChunk(body, trackId)
        .then((resp) => {
          setMasterTitle(file?.name || '');

          if (!resp.success) {
            setIsUploading(false);
            setError(resp.message);
            return;
          }
          setError('');
          setProgressPercentage(100);
          setTotalProgress(100);
          setIsUploading(false);
          setProgressLoader(false);
        })
        .catch((err) => {
          setIsUploading(false);
          setError(err.message);
          setMasterTitle(file?.name || '');
          setIsUploading(false);
        });
    }
  }, [file]);

  useEffect(() => {
    if (chunks.length > 0) {
      upload();
    } else {
      setMasterTitle(file?.name || '');
      setIsUploading(false);
    }
  }, [chunks]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async ([masterFile]) => {
      /**
       * Ensure we can't proceed with upload until conditions are met
       * We use this instead of "disable" prop on "useDropzone" to allow
       * click events on container, showing an error message if user tries to
       * upload without accepting terms and conditions
       */

      onDrop({ masterFile });
    },
    multiple: false,
  });

  const getBorderColor = () => (isDragActive ? style.grey_dark_new : '#D8D6DE');

  const upload = () => {
    const body = new FormData();
    body.append('file', chunks[0], `${file?.name}.part`);
    body.append('id', `${uuid}`);
    body.append('is_last', `${chunks.length === 1}`);

    API.trackReleases
      .uploadChunk(body, trackId)
      .then((resp) => {
        if (!resp.success) {
          setError(resp.message);
          setMasterTitle(file?.name || '');
          return;
        }
        setError('');
        setProgressPercentage(totalProgress + oneChunkPercentage);
        setTotalProgress((prev) => prev + oneChunkPercentage);
        setChunks((prev) => prev.slice(1));
      })
      .catch((err) => {
        setError(err.message);
        setMasterTitle(file?.name || '');
      });
  };

  return (
    <div
      className="w-100 d-flex align-items-center justify-content-between px-1 rounded"
      style={{
        border: `2px dashed ${getBorderColor()}`,
        height: '50px',
        cursor: 'pointer',
      }}
      {...getRootProps()}
    >
      {title || file?.name ? (
        <TextRegular16>{file?.name || title}</TextRegular16>
      ) : (
        <TextRegular16 className="text-muted">Select file</TextRegular16>
      )}

      <input
        {...getInputProps({ className: 'outline-none' })}
        /**
         * We are by-passing "Dropzone" validation since its not working in all browsers
         * This just ensures native file picker dialog with have filter pre-selected
         */
        accept={fileInputAccept}
        disabled={isUploading}
      />
      <MdOutlineFileUpload size={20} color="#b9b9c3" />
    </div>
  );
}

export default ChunkUploader;

interface ChunkUploaderProps {
  title?: string;
  className?: string;
  trackId: number;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setProgressPercentage: React.Dispatch<React.SetStateAction<number>>;
  setProgressLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setMasterTitle: React.Dispatch<React.SetStateAction<string>>;
}

interface OnDropProps {
  masterFile?: File;
  url?: string;
}
