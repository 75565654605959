import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import { API, TrackRelease } from '../../api';
import {
  TextBold14,
  TextBold16,
  TextBold18,
  TextRegular12,
  TextRegular14,
} from '../atoms/Text';

function MainFlowPreview({
  shouldUpdate,
  trackId,
  setShouldUpdate,
  setIsNotFoundPage,
}: MainFlowPreviewProp) {
  const { t } = useTranslation('organisms');
  const [trackRelease, setTrackRelease] = useState<TrackRelease>();
  const [believeGenres, setBelieveGenres] = useState<BelieveGenre[]>([]);

  const update = () => {
    API.trackReleases.getTrackRelease(trackId).then((response) => {
      const { data, success } = response;
      if (success) {
        setTrackRelease(data);
      }
      if (!success && setIsNotFoundPage) {
        setIsNotFoundPage(true);
      }
    });
  };

  useEffect(() => {
    update();
    const getBelieveGenres = async () => {
      const genres: BelieveGenre[] = await API.trackReleases
        .getTrackGenres()
        .then((res) => res.data);
      setBelieveGenres(genres);
    };
    getBelieveGenres();
  }, []);

  useEffect(() => {
    if (shouldUpdate) {
      update();
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  useEffect(() => {}, [trackRelease]);

  const getProducers = () => {
    if (!trackRelease) {
      return <></>;
    }

    const producers = trackRelease.artist_details.artists
      .filter((artist) => artist.artist_role.includes('Producer'))
      .map((artist) => artist.artist_name);

    if (producers.length === 0) {
      return <></>;
    }

    return (
      <TextRegular14>
        {t('organisms:producers')} {producers.join(', ')}
      </TextRegular14>
    );
  };

  const getComposers = () => {
    if (!trackRelease) {
      return <></>;
    }

    const composers = trackRelease.artist_details.artists
      .filter((artist) => artist.artist_role.includes('Composer'))
      .map((artist) => artist.artist_name);

    if (composers.length === 0) {
      return <></>;
    }

    return (
      <TextRegular14>
        {t('organisms:composers')} {composers.join(', ')}
      </TextRegular14>
    );
  };
  const getWriters = () => {
    if (!trackRelease) {
      return <></>;
    }

    const writer = trackRelease.artist_details.artists
      .filter((artist) => artist.artist_role.includes('Writer'))
      .map((artist) => artist.artist_name);

    if (writer.length === 0) {
      return <></>;
    }

    return (
      <TextRegular14>
        {t('organisms:writerColon')} {writer.join(', ')}
      </TextRegular14>
    );
  };

  const getReleaseDate = () => {
    if (!trackRelease) {
      return <></>;
    }

    const releaseDate = trackRelease.finish.original_release_date;

    if (!releaseDate || releaseDate === '') {
      return <></>;
    }

    const date = format(new Date(releaseDate), 'd MMM yyyy');

    return (
      <TextRegular14>
        {t('organisms:releaseDate')} {date}
      </TextRegular14>
    );
  };

  if (!trackRelease) {
    return <></>;
  }

  const getGenre = () => {
    const currentGenre = believeGenres.find(
      (genre) => genre.id === trackRelease.track_details.main_genre_id,
    )?.name;

    if (currentGenre) {
      return (
        <span>
          {t('organisms:genre')} {currentGenre}
        </span>
      );
    }
    return <></>;
  };

  const getArtists = () => {
    const mainArtists = trackRelease.artist_details.artists
      .filter((artist) => artist.artist_type === 'MAINARTIST')
      .map((artist) => artist.artist_name);

    const featuredArtists = trackRelease.artist_details.artists
      .filter((artist) => artist.artist_type === 'FEATUREDARTIST')
      .map((artist) => artist.artist_name);

    let result = mainArtists.join(', ');

    if (featuredArtists.length > 0) {
      result = `${result} ${t('organisms:ft')} ${featuredArtists.join(', ')}`;
    }

    return result;
  };

  return (
    <>
      <div className="d-flex flex-column w-100 align-items-center mt-1">
        <TextBold16 className="mb-3">{t('organisms:preview')}</TextBold16>

        <Image
          src={
            trackRelease.album_art.artwork ||
            'https://dev.virpp.com/samples/covers/artwork1.png'
          }
          alt="Track cover"
          width={200}
          height={200}
          unoptimized
          style={{
            maxWidth: '100%',
          }}
        />

        <TextBold18 className="mt-3">
          {trackRelease.track_details.release_title}
        </TextBold18>
        <TextRegular14 className="w-75 text-center">
          {getArtists()}
        </TextRegular14>

        <div className="my-2 d-flex flex-column align-items-center">
          <TextBold14>{getGenre()}</TextBold14>
          <TextRegular12>
            {
              believeGenres.find(
                (genre) =>
                  genre.id === trackRelease.track_details.main_sub_genre_id,
              )?.name
            }
          </TextRegular12>
        </div>

        {trackRelease && (
          <>
            {getProducers()}
            {getComposers()}
            {getWriters()}
            {getReleaseDate()}
          </>
        )}
      </div>
    </>
  );
}

export default MainFlowPreview;

interface MainFlowPreviewProp {
  shouldUpdate: boolean;
  trackId: number;
  setShouldUpdate: (val: boolean) => void;
  setIsNotFoundPage?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface BelieveGenre {
  id: number;
  name: string;
  parent_id?: number;
}
