import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Image from 'next/image';
import { MessageSquare, Eye } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import {
  getPullRequestsForLogginInUser,
  Track,
  TrackPulledRecieved,
  useCurrentUser,
} from '../../api';
import Button from '../atoms/Button';
import { TextBolder14 } from '../atoms/Text';

function PulledSuccessSection({ track }: { track: Track }) {
  // TODO add api call to get pull info https://api-dev.virpp.com/api/pull/tracks/{trackId}
  const { t } = useTranslation('myTracks');
  const [pullRequest, setPullRequest] = useState<TrackPulledRecieved>();
  const currentUser = useCurrentUser();

  useEffect(() => {
    const setPulls = async () => {
      const pulls: TrackPulledRecieved[] = await getPullRequestsForLogginInUser().then(
        (res) => res,
      );

      const filtered: TrackPulledRecieved = pulls.filter(
        (pull) => track.id === pull.track_id && pull.status === 'accepted',
      )[0];

      setPullRequest(filtered);
    };
    setPulls();
  }, []);
  return (
    <>
      <div
        style={{
          marginTop: '1.7rem',
          marginBottom: '1rem',
          width: '200px',
          gap: '8px',
        }}
        className="d-flex justifty-content-center flex-column mx-auto"
      >
        {pullRequest && (
          <p className="d-flex justify-content-center">
            <Image
              src={pullRequest.label?.profile_photo || ''}
              width="60"
              height="60"
              unoptimized
              className="rounded-circle"
              alt="test"
              style={{
                maxWidth: '100%',
              }}
            />
          </p>
        )}

        <span className="text-center">
          {t('myTracks:trackSuccessfullyPulled')}{' '}
          <LabelName>
            <Link href={`/profile/${pullRequest?.label?.id}`}>
              <>{pullRequest?.label?.display_name}.</>
            </Link>
          </LabelName>
        </span>

        <Link href={`/chat/${currentUser.conversation_id}`}>
          <Button color="dark" outline>
            <MessageSquare size={18} style={{ marginRight: '15px;' }} />
            <span style={{ marginLeft: '5px' }}>
              {t('myTracks:messageLabel')}
            </span>
          </Button>
        </Link>

        <Link href={`/tracks/${track.id}`}>
          <Button
            color="primary"
            className="px-2 d-flex align-items-center justify-content-center br-5"
          >
            <span>
              <Eye size={18} />
              <span style={{ marginLeft: '5px' }}>
                {t('myTracks:viewTrack')}
              </span>
            </span>
          </Button>
        </Link>
      </div>
    </>
  );
}

const LabelName = styled(TextBolder14)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default PulledSuccessSection;
