import React from 'react';
import { Eye } from 'react-feather';
import useTranslation from 'next-translate/useTranslation';
import { Track } from '../../api';
import Button from '../atoms/Button';
import CustomLink from '../../components/common/Link/CustomNextLink';

function DefaultSection({ track }: { track: Track }) {
  const { t } = useTranslation('common');
  return (
    <CustomLink href="/tracks/[trackId]" as={`/tracks/${track.id}`}>
      <Button
        color="primary"
        className="px-2 d-flex align-items-center br-5"
        style={{ marginTop: '1.5rem', width: '100%' }}
      >
        <>
          <Eye size={18} style={{ marginRight: '8px' }} />
          {t('common:viewTrack')}
        </>
      </Button>
    </CustomLink>
  );
}

export default DefaultSection;
