import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Track } from '../../api';
import MyTracksDistributionCardBodyNew from './MyTracksDistributionCardBodyNew';

function MyTracksDistributionModal({
  track,
  onAction,
  showModal,
  setShowModal,
}: MyTracksDistributionModalProps) {
  if (!track) {
    return null;
  }

  return (
    <>
      <Modal
        centered
        fullscreen
        keyboard
        scrollable
        toggle={() => setShowModal(!showModal)}
        isOpen={showModal}
        className="m-0 w-100 mr-1 ml-1"
        contentClassName="h-100 w-100 m-0"
        modalClassName="m-0 mt-5 p-0 d-flex justify-content-center"
        wrapClassName="m-0 mt-2 p-0"
      >
        <ModalHeader toggle={() => setShowModal(false)} className="mr-1 mt-1" />
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ overflowY: 'scroll' }}
        >
          <ModalBody className="h-100">
            <MyTracksDistributionCardBodyNew
              track={track}
              onAction={onAction}
            />
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}

export default MyTracksDistributionModal;

interface MyTracksDistributionModalProps {
  track?: Track;
  onAction: (action?: string) => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
